import React, { useEffect, useState, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { store } from "./Store";
import { Provider, useDispatch, useSelector } from "react-redux";
import { MerchantScreen, NotFoundScreen } from "./screens";
import { utils, GlobalDebug  } from "./utils";
import { v4 as uuidv4 } from "uuid";
import { Modal } from "./components";
import { useNavigate } from "react-router-dom";
import { message, FloatButton } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";

const App = () => 
{    
	const navigate = useNavigate();
	const preOrderCart = localStorage.getItem('__cart') ? utils.decryptObject('__cart') : {};
	const preOrderData = localStorage.getItem('__detail') ? utils.decryptObject('__detail') : {};
	const cartOrder =
		typeof preOrderCart === "object" ? preOrderCart : JSON.parse(preOrderCart);
	const orderData =
    typeof preOrderData === "object" ? preOrderData : JSON.parse(preOrderData);
	const [deliveryInfo, setDeliveryInfo] = useState(orderData);	
	const details = localStorage.getItem('__detail') ? localStorage.getItem('__detail') : {};
	const driveObj = localStorage.getItem('__el_drive') ? JSON.parse(utils.cipherDecode(localStorage.getItem('__el_drive'))) : "";
	const driveData = typeof driveObj === "object" ? driveObj : {};	
	
	const [drive, setDrive] = useState(driveData);
	const [target, setTarget] = useState('');
	const [startpoint, setStartPoint] = useState('');
	const [mainstage, setMainStage] = useState('');
	const [mstartpoint, setMStartPoint] = useState('');
	const [mstage, setMStage] = useState('');
	const [modalOpen, setModalOpen] = useState(false);	
	const [modalTitle, setModalTitle] = useState('');
	const [modalBody, setModalBody] = useState('');
	const [modalButton, setModalButton] = useState(false);
	const [modalButtonFunc, setModalButtonFunc] = useState();
	const [modalPage, setModalPage] = useState('');	
	// State to store user's country
  const [userCountry, setUserCountry] = useState(false);

  // State to track whether the user is allowed or not
  const [isAllowed, setIsAllowed] = useState(true);

  useEffect(() => {			
    // Function to get user's country using geolocation API
    const getUserCountry = async () => {
      try {
				const region = await utils.getStorage('__user_region');
				if(!region){
					const response = await fetch('https://api.errandlocal.com/v1/ip');
					const data = await response.json();
					if(data.success){
						setUserCountry(data.country_code.toUpperCase().trim());
					}else{
						setUserCountry('Unknown');
					}
				}else{
					setUserCountry(region.toUpperCase().trim());
				}
      } catch (error) {
        // Set a default country or handle the error accordingly
        setUserCountry('Unknown');
      }
    };
    // Call the function to get user's country on component mount
    getUserCountry();
  }, []);

  useEffect(() => {
    // List of supported countries
		const isPresent = utils.supportedCountries.some(country => country.code === userCountry);

    // Check if the user's country is in the supported list
    if (!isPresent && userCountry) {
      setIsAllowed(false);
    }else if (isPresent && userCountry) {	
      utils.setStorage('__user_region', userCountry);
    }		
  }, [userCountry]);	
	
	useEffect(() => {
		setDeliveryInfo(deliveryInfo);
		(process.env.NODE_ENV === "production" ||
		 process.env.REACT_APP_ENV === "STAGING") && GlobalDebug(false);
	}, [deliveryInfo, details]);
	
	useEffect(() => {
		if(!localStorage.getItem('__el_drive')){
			driveLink();
		}
	}, [drive, driveData]);	
	
	
  useEffect(() => {
		if(cartOrder?.orderPlaced && cartOrder?.orderPlaced.length > 0){
			if(cartOrder?.orderPlaced[0]?.product?.storeId !== deliveryInfo?.storeId){
				const sId = cartOrder?.orderPlaced[0]?.product?.storeId;
				const sName = cartOrder?.orderPlaced[0]?.product?.storeName;
				const sCity = cartOrder?.orderPlaced[0]?.product?.storeCity;
				const sState = cartOrder?.orderPlaced[0]?.product?.storeState;
				const sZip = cartOrder?.orderPlaced[0]?.product?.storeZip;				
						
				const navStore = utils.convertAddress(sName);
				const navCity = utils.convertAddress(sCity);
				const navId = utils.convertAddress(sId);
				const navZip = utils.convertAddress(sZip);
				const navState = utils.convertAddress(sState);
				const navLink = `/${navState}/${navCity}/${navZip}/${navStore}/${navId}`;		
				
				updateDeliveryData({...deliveryInfo, checkOutLink: navLink, storeId: sId, storeName: sName, storeCity: sCity, storeState: sState, storeZip: sZip});				
			}
		}
    
  }, [cartOrder, deliveryInfo]);
	
		
	useEffect(() => 
	{ 		
		if(localStorage.getItem('driverReg')){
			let nstage = localStorage.getItem('driverReg');
			let nstart = localStorage.getItem('driverStart');
			setMainStage(nstage);
			setStartPoint(nstart);
		}
	}, [startpoint, mainstage]);
	
	useEffect(() => 
	{ 
		if(localStorage.getItem('merchantReg')){
			let mstage = localStorage.getItem('merchantReg');
			let mstart = localStorage.getItem('merchantStart');
			setMStage(mstage);
			setMStartPoint(mstart);
		}
	}, [mstartpoint, mstage]);
	
	useEffect(() => 	
	{
		setTarget(target);
		if(!modalOpen){
			window.document.body.style.overflow = '';
		}
	}, [modalOpen, target]);
		
	const logPage = async (ref, loc) => {
		try {			
			const logUrl = utils.USER_REPORT+`?ref=${encodeURIComponent(ref)}&loc=${loc}`;
			const header = {
				'Content-Type': 'image/png'
			};
			const dataToPost = '';
			const res = await utils.fetchURL(logUrl, 'GET', dataToPost, header);
			const data = await res.json();

		} catch (e) {}
	};
		
	const genJWT = async() => 
	{
		const authToken = await utils.getStorage('__el_data_login_token');
		return authToken;
	}
	
	const updateDeliveryData = async (data) => {
    try {
      setDeliveryInfo(data);
      //console.log(data);
      //dispatch(setOrderData(JSON.stringify(data)));
    } catch (e) {}
  };
	
	const onPostDriver = async (obj) => 
	{		
		try{
			const authToken = await genJWT();
			const url = utils.DRIVER_SIGNUP_URL;
			const header = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+authToken
			};
			const dataToPost = await utils.cipherEncode(JSON.stringify(obj));
			const res = await utils.fetchURL(url, 'POST', dataToPost, header);
			const data = await res.json();
			
			if(data.msg === 'Success'){
				var state;
				if(obj.data_type === 'signupDriver'){
					state = document.getElementById('status');
					state.style.display = 'block';
					state.className = 'incsizespan_info center show';
					state.innerHTML = "<b>"+data.screen+"</b>";					
					localStorage.setItem('driverReg', data.screen);					
					localStorage.setItem('driverStart', data.startpoint);
					setStartPoint(data.startpoint);
					setMainStage(data.screen);
					window.location.reload();
				}else if(obj.data_type === 'signupDriver2'){
					state = document.getElementById('status2');
					state.style.display = 'block';
					state.className = 'incsizespan_info center show';		
					state.innerHTML = "<b>"+data.screen+"</b>";	
					localStorage.setItem('driverReg', data.screen);
					localStorage.setItem('driverStart', '');
					setStartPoint('');
					setMainStage(data.screen);
				}				
			}else{
				let state;
				if(obj.data_type === 'signupDriver'){
					state = document.getElementById('status');
					state.style.display = 'block';
					state.className = 'cus_error center show';		
					state.innerHTML = "<b>"+data.msg+"</b>";
				}else if(obj.data_type === 'signupDriver2'){
					state = document.getElementById('status2');
					state.style.display = 'block';
					state.className = 'cus_error center show';		
					state.innerHTML = "<b>"+data.msg+"</b>";					
				}
			}
		}catch(e){
			message.error(e.message);
		}
	}
	
	const onPostMerchant = async (obj) => {		
		try{
			const authToken = await genJWT();
			const url = utils.MERCHANT_SIGNUP_URL;
			const header = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+authToken
			};
			const dataToPost = await utils.cipherEncode(JSON.stringify(obj));
			const res = await utils.fetchURL(url, 'POST', dataToPost, header);
			const data = await res.json();
			
			if(data.msg === 'Success'){
				var state;
				if(obj.data_type === 'signupMerchant'){
					state = document.getElementById('status2');
					state.style.display = 'block';
					state.className = 'incsizespan_info center show';		
					state.innerHTML = "<b>"+data.screen+"</b>";					
					localStorage.setItem('merchantReg', data.screen);
					localStorage.setItem('merchantStart', '');
					setMStartPoint('');
					setMStage(data.screen);
				}				
			}else{
				if(obj.data_type === 'signupMerchant'){
					state = document.getElementById('status2');
					state.style.display = 'block';
					state.className = 'cus_error center show';		
					state.innerHTML = "<b>"+data.msg+"</b>";
				}
			}
		}catch(e){
			message.error(e.message);
		}
	}
	
	const refClick = (data) => {		
		setTarget(data);
	}
	
	const driveLink = async () => {
		try{
			let logUrl;
			const authToken = await genJWT();
			logUrl = utils.USER_DRIVE;			
			
			const header = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+authToken
			};
			const dataToPost = "";
			const res = await utils.fetchURL(logUrl, 'GET', dataToPost, header);		
			const data = await res.json();
			//const decodeData = await utils.cipherDecode(data?.info);
			//console.log(decodeData);
			localStorage.setItem('__el_drive', data?.info);
			//setDrive();
			
		}catch(e){
			message.error("OK => "+ e.message);
		}
	
	}
	
	const sendLink = async (obj) => {
		try{
			let url, state = document.getElementById('modal-status');
			const authToken = await genJWT();
			if(modalPage === 'merchant'){
				url = utils.MERCHANT_SIGNUP_URL;
			}else if(modalPage === 'driver'){
				url = utils.DRIVER_SIGNUP_URL;
			}else if(modalPage === 'customer'){
				url = utils.USER_URL;
			}
			
			const header = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+authToken
			};
			const dataToPost = await utils.cipherEncode(JSON.stringify(obj));
			const res = await utils.fetchURL(url, 'POST', dataToPost, header);		
			const data = await res.json();
			
			if(data.msg === 'Success'){
				await setModalOpen(!modalOpen);
				message.success('Download link was sent to your phone');
			}else{				
				state.style.display = 'block';
				state.className = 'cus_error center show';		
				state.innerHTML = '<b>'+data.msg+'</b>';
			}
		}catch(e){
			message.error(e.message);
		}	
	}
	
	const ExecOperation = (type) => {
		
		let linkphonetype;				
		let state = document.getElementById('modal-status');
		let linkphone = document.getElementById('linkphone').value;		
		
		if(type === 'notspecific'){
			if(!document.getElementById('android').checked && !document.getElementById('ios').checked){
				state.style.display = 'block';
				state.className = 'cus_error center show';		
				state.innerHTML = '<b>Choose phone operating system</b>';
				return;
			}
			if(document.getElementById('android').checked){
				linkphonetype = 'android';
			}else if(document.getElementById('ios').checked){
				linkphonetype = 'ios';
			}else {
				return;
			}
		}else{
			if(type){
				linkphonetype = type;
			}else {
				return;
			}
		}
		
		if(!linkphone){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>Phone number is required</b>';
			return;
		}
		
		var obj = {
			'data_type': 'linkrequest',
			'data_os': linkphonetype,			
			'data_phone': linkphone,
		}
		
		sendLink(obj);	
	}
	
	const sendAppDriver = (app) => {
		setModalPage('driver');
		if(app){	
			setModalTitle('Driver App - '+app);
			setModalBody("<label key='l1'>Phone number *</label><br/><input maxlength=10 type='phone' id='linkphone' placeholder='' class='modal-text-input' name='linkphone'/><br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
			setModalButton(true);
			setModalButtonFunc(app);
			setModalOpen(!modalOpen);
			window.document.body.style.overflow = 'hidden';
		}else{
			setModalTitle('Driver App');
			setModalBody("<label key='l1'>Choose operating system *</label><br/><br/>Android <input type='radio' id='android' class='modal-radio' name='os'/> | iOS <input type='radio' id='ios' class='modal-radio' name='os'/> <br/><br/><label key='l2'>Phone number *</label><br/><input maxlength=10 type='phone' id='linkphone' class='modal-text-input' name='linkphone'/><br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
			setModalButton(true);
			setModalButtonFunc('notspecific');
			setModalOpen(!modalOpen);
			window.document.body.style.overflow = 'hidden';
		}
	}
	
	const sendAppMerchant = (app) => {
		setModalPage('merchant');
		if(app){	
			setModalTitle('Merchant App - '+app);
			setModalBody("<label key='l1'>Phone number *</label><br/><input maxlength=10 type='phone' id='linkphone' placeholder='' class='modal-text-input' name='linkphone'/><br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
			setModalButton(true);
			setModalButtonFunc(app);
			setModalOpen(!modalOpen);
			window.document.body.style.overflow = 'hidden';
		}else{
			setModalTitle('Merchant App');
			setModalBody("<label key='l1'>Choose operating system *</label><br/><br/>Android <input type='radio' id='android' class='modal-radio' name='os'/> | iOS <input type='radio' id='ios' class='modal-radio' name='os'/> <br/><br/><label key='l2'>Phone number *</label><br/><input maxlength=10 type='phone' id='linkphone' class='modal-text-input' name='linkphone'/><br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
			setModalButton(true);
			setModalButtonFunc('notspecific');
			setModalOpen(!modalOpen);
			window.document.body.style.overflow = 'hidden';
		}
	}
	
	const sendAppCustomer = (app) => {
		setModalPage('customer');
		if(app){	
			setModalTitle('ErrandLocal App - '+app);
			setModalBody("<label key='l1'>Phone number *</label><br/><input maxlength=10 type='phone' id='linkphone' placeholder='' class='modal-text-input' name='linkphone'/><br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
			setModalButton(true);
			setModalButtonFunc(app);
			setModalOpen(!modalOpen);
			window.document.body.style.overflow = 'hidden';
		}else{
			setModalTitle('ErrandLocal App');
			setModalBody("<label key='l1'>Choose operating system *</label><br/><br/>Android <input type='radio' id='android' class='modal-radio' name='os'/> | iOS <input type='radio' id='ios' class='modal-radio' name='os'/> <br/><br/><label key='l2'>Phone number *</label><br/><input maxlength=10 type='phone' id='linkphone' class='modal-text-input' name='linkphone'/><br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
			setModalButton(true);
			setModalButtonFunc('notspecific');
			setModalOpen(!modalOpen);
			window.document.body.style.overflow = 'hidden';
		}
	}
	
	const infoDisplay = (title, body) => {		
		const b = body.split('|');
		var mainbody = '';
		for(let i=0; i<b.length; i++){
			mainbody += "<p style='font-size: 18px'>"+b[i]+"</p>";
		}
		setModalPage('');
		setModalTitle(title);
		setModalBody(mainbody);
		setModalButton(false);
		setModalOpen(!modalOpen);
		window.document.body.style.overflow = 'hidden';
	}

	return (
	<Provider store={store}>	
	  <Suspense fallback={<h3>Loading...</h3>}>
		<Routes>
			<Route path="/" element={<MerchantScreen onPostMerchant={onPostMerchant} refClick={refClick} mstartpoint={mstartpoint} mstage={mstage} sendApp={sendAppMerchant} setModalOpen={setModalOpen} infoDisplay={infoDisplay} logPage={logPage}/>} key={uuidv4()} />	
			<Route path="*" element={<NotFoundScreen sendApp={sendAppCustomer} infoDisplay={infoDisplay} logPage={logPage}/>} key={uuidv4()} />
		</Routes>		
		
		{modalOpen && (<Modal setOpenModal={setModalOpen} modalTitle={modalTitle} modalBody={modalBody} modalButton={modalButton} setModalButtonFunc={()=>ExecOperation(modalButtonFunc)} key={uuidv4()} />)}
		
	  </Suspense>
		  	
	</Provider>
	);
	
};

export default App;
