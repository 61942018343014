import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { utils } from "../../utils";
const { decryptObject, encryptObject } = utils;

let elAddress = "",
  elAddressLat = 0,
  elAddressLng = 0;
  
if(Cookies.get("elAddr")){
	let getAddr = Cookies.get("elAddr");	
	getAddr = typeof getAddr === "object" ? getAddr : JSON.parse(getAddr);		
	if (getAddr?.address) {
		elAddress = getAddr?.address;
		elAddressLat = getAddr?.latitude;
		elAddressLng = getAddr?.longitude;
	}
}

// Retrieve cart data from local storage
const getAddressesFromLocalStorage = () => {
  const addressData = localStorage.getItem("__el_ad");
  if (addressData) {
    return decryptObject("__el_ad");
  }
  return [];
};

// Retrieve cart data from local storage
const getErrandAddressesFromLocalStorage = () => {
  const errandAddressData = localStorage.getItem("__el_errandad");
  if (errandAddressData) {
    return decryptObject("__el_errandad");
  }
  return [];
};

// Retrieve cart data from local storage
const getPayFromLocalStorage = () => {
  const payData = localStorage.getItem("__el_pa");
  if (payData) {
    return decryptObject("__el_pa");
  }
  return [];
};

// Retrieve cart data from local storage
const getCartFromLocalStorage = () => {
  const cartData = localStorage.getItem("__cart");
  if (cartData) {
    return decryptObject("__cart");
    //return JSON.parse(cartData);
  }
  return {
    fulfillmentType: "",
    orderPlaced: [],
  };
};

// Retrieve cart data from local storage
const getErrandCartFromLocalStorage = () => {
  const errandCartData = localStorage.getItem("__errandcart");
  if (errandCartData) {
    return decryptObject("__errandcart");
    //return JSON.parse(cartData);
  }
  return {
    fulfillmentType: "",
    orderPlaced: [],
  };
};

// Retrieve detail data from local storage
const getDetailFromLocalStorage = () => {
  const detailData = localStorage.getItem("__detail");
  if (detailData) {
    return decryptObject("__detail");
    //return JSON.parse(detailData);
  }
  return {
    day: "ASAP",
    time: "",
    type: "",
    fee: 0,
    currency: "",
    latitude: elAddressLat,
    longitude: elAddressLng,
    address: elAddress,
    apartment: "",
    instruction: "",
    instructionType: "Leave At Door",
    firstName: "",
    lastName: "",
    storeName: "",
    storeId: "",
    storeState: "",
    storeCity: "",
    storeZip: "",
    hours: {},
    tip: 0,
    tipPercentage: 15,
    tax: 0,
    serviceCharge: 0,
    subTotal: 0,
    total: 0,
    deliveryFee: 0,
    minutes: 0,
    miles: 0,
    code: "",
  };
};

// Retrieve detail data from local storage
const getErrandDetailFromLocalStorage = () => {
  const errandDetailData = localStorage.getItem("__erranddetail");
  if (errandDetailData) {
    return decryptObject("__erranddetail");
    //return JSON.parse(detailData);
  }
  return {
    day: "ASAP",
    time: "",
    type: "",
    fee: 0,
    currency: "",
    latitude: elAddressLat,
    longitude: elAddressLng,
    address: elAddress,
    apartment: "",
    instruction: "",
    instructionType: "Leave At Door",
    firstName: "",
    lastName: "",
    storeLabel: "",
    storeName: "",
    storeAddress: "",
    storeSuite: "",
    storePhone: "",
    storeInstruction: "",
    storeId: "",
    storeState: "",
    storeCity: "",
    storeZip: "",
    storeLat: 0,
    storeLng: 0,
    hours: {},
    tip: 0,
    tipPercentage: 15,
    tax: 0,
    serviceCharge: 0,
    subTotal: 0,
    total: 0,
    deliveryFee: 0,
    minutes: 0,
    miles: 0,
    code: "",
  };
};

const initialState = {
  products: [],
  orders: [],
  stores: [],
  savedstores: [],
  currentstore: [],
  storecategories: [],  
  productcategories: [],
  usersession: null,
  sessiontoken: "",
  geolocation: [],
  addressdata: [],
  errandaddressdata: [],
  pastorderdata: null,
  paymentcarddata: [],
  errandApp: {},
  orderdata: getDetailFromLocalStorage(),
  errandorderdata: getErrandDetailFromLocalStorage(),
  ordercart: getCartFromLocalStorage(),
  errandordercart: getErrandCartFromLocalStorage(),
  savedaddresses: getAddressesFromLocalStorage(),
  savederrandaddresses: getErrandAddressesFromLocalStorage(),
  savedcards: getPayFromLocalStorage(),
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setStores: (state, action) => {
      state.stores = action.payload;
    },
    setSavedStores: (state, action) => {
      state.savedstores = action.payload;
    },
    setCurrentStore: (state, action) => {
      state.currentstore = action.payload;
    },
    setStoreCategories: (state, action) => {
      state.storecategories = action.payload;
    },
    setProductCategories: (state, action) => {
      state.productcategories = action.payload;
    },
    setGeoLocation: (state, action) => {
      state.geolocation = action.payload;
    },
    setUserSession: (state, action) => {
      state.usersession = action.payload;
    },
    setSessionToken: (state, action) => {
      state.sessiontoken = action.payload;
    },
    setAddressData: (state, action) => {
      state.addressdata = action.payload;
    },
    setErrandAddressData: (state, action) => {
      state.errandaddressdata = action.payload;
    },
    setErrandApp: (state, action) => {
      state.errandApp = action.payload;
    },
    setPastOrderData: (state, action) => {
      state.pastorderdata = action.payload;
    },
    setPaymentCardData: (state, action) => {
      state.paymentcarddata = action.payload;
    },
    setOrderCart: (state, action) => {
      state.ordercart = action.payload;
      encryptObject("__cart", action.payload);
    },
    setErrandOrderCart: (state, action) => {
      state.errandordercart = action.payload;
      encryptObject("__errandcart", action.payload);
    },
    setOrderData: (state, action) => {
      state.orderdata = action.payload;
      encryptObject("__detail", action.payload);
    },
    setErrandOrderData: (state, action) => {
      state.errandorderdata = action.payload;
      encryptObject("__erranddetail", action.payload);
    },
    setSavedAddresses: (state, action) => {
      state.savedaddresses = action.payload;
			encryptObject("__el_ad", action.payload);
    },
    setSavedErrandAddresses: (state, action) => {
      state.savederrandaddresses = action.payload;
			encryptObject("__el_errandad", action.payload);
    },
    setSavedCards: (state, action) => {
      state.savedcards = action.payload;
			encryptObject("__el_pa", action.payload);
    },
  },
});

export const {
  setProducts,
  setOrders,
  setStores,
  setStoreCategories,
  setProductCategories,
  setSavedStores,
  setOrderCart,
	setErrandOrderCart,
  setOrderData,
	setErrandOrderData,
  setCurrentStore,
  setUserSession,
  setSessionToken,
  setSavedAddresses,
  setSavedErrandAddresses,
  setSavedCards,
  setAddressData,
  setErrandAddressData,
  setPastOrderData,
  setPaymentCardData,
  setErrandApp,
} = navSlice.actions;
export const selectOrders = (state) => state.nav.orders;
export const selectProducts = (state) => state.nav.products;
export const selectStores = (state) => state.nav.stores;
export const selectSavedStores = (state) => state.nav.savedstores;
export const selectCurrentStore = (state) => state.nav.currentstore;
export const selectStoreCategories = (state) => state.nav.storecategories;
export const selectProductCategories = (state) => state.nav.productcategories;
export const selectOrderCart = (state) => state.nav.ordercart;
export const selectErrandOrderCart = (state) => state.nav.errandordercart;
export const selectOrderData = (state) => state.nav.orderdata;
export const selectErrandOrderData = (state) => state.nav.errandorderdata;
export const selectGeoLocation = (state) => state.nav.geolocation;
export const selectUserSession = (state) => state.nav.usersession;
export const selectSessionToken = (state) => state.nav.sessiontoken;
export const selectSavedAddresses = (state) => state.nav.savedaddresses;
export const selectSavedErrandAddresses = (state) => state.nav.savederrandaddresses;
export const selectSavedCards = (state) => state.nav.savedcards;
export const selectAddressData = (state) => state.nav.addressdata;
export const selectErrandAddressData = (state) => state.nav.errandaddressdata;
export const selectPastOrderData = (state) => state.nav.pastorderdata;
export const selectPaymentCardData = (state) => state.nav.paymentcarddata;
export const selectErrandApp = (state) => state.nav.errandApp;
export const nDeliveryData = {
	day: "ASAP",
	time: "",
	type: "",
	fee: 0,
	currency: "",
	latitude: 0,
	longitude: 0,
	address: "",
	apartment: "",
	instruction: "",
	instructionType: "Leave At Door",
	firstName: "",
	lastName: "",
	storeName: "",
	storeId: "",
	storeState: "",
	storeCity: "",
	storeZip: "",
	hours: {},
	tip: 0,
	tipPercentage: 15,
	tax: 0,
	serviceCharge: 0,
	subTotal: 0,
	total: 0,
	deliveryFee: 0,
	minutes: 0,
	miles: 0,
	code: "",
};

export const nErrandData = {
	day: "ASAP",
	time: "",
	type: "",
	fee: 0,
	currency: "",
	latitude: 0,
	longitude: 0,
	address: "",
	apartment: "",
	instruction: "",
	instructionType: "Leave At Door",
	firstName: "",
	lastName: "",
	storeLabel: "",
	storeName: "",
	storeAddress: "",
	storeSuite: "",
	storePhone: "",
	storeInstruction: "",
	storeId: "",
	storeState: "",
	storeCity: "",
	storeZip: "",
	storeLat: 0,
	storeLng: 0,
	hours: {},
	tip: 0,
	tipPercentage: 15,
	tax: 0,
	serviceCharge: 0,
	subTotal: 0,
	total: 0,
	deliveryFee: 0,
	minutes: 0,
	miles: 0,
	code: "",
};

export const OrderStatus = {
  NEW: "NEW",
  COOKING: "COOKING",
  AT_STORE: "AT_STORE",
  COURIER_PICKED_UP: "PICKED_UP",
  STORE_READY_FOR_PICKUP: "READY_FOR_PICKUP",
  COURIER_READY_FOR_PICKUP: "READY_FOR_PICKUP",
  STORE_COMPLETED: "COMPLETED",
  COURIER_COMPLETED: "COMPLETED",
  STORE_ACCEPTED: "ACCEPTED",
  COURIER_ACCEPTED: "ACCEPTED",
  STORE_DECLINED: "DECLINED",
  COURIER_DECLINED: "DECLINED",
  CANCELLED: "CANCELLED",
  PENDING: "PENDING",
  PAYMENT_DECLINED: "PAYMENT_DECLINED",
};
export default navSlice.reducer;
