import React,{useEffect} from "react";
import { useLocation } from 'react-router-dom';
import { Merchant, SubFooter, SubNavbar, MerchantNavbar } from '../../components';

const MerchantScreen = ({onPostMerchant, mstartpoint, mstage, sendApp, setModalOpen, infoDisplay, logPage}) => {
	
	const location = useLocation();
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Restaurant owners - Get started | ErrandLocal";
	}, []);	
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, [location.pathname]);
	
	return (
		<>
			<MerchantNavbar sendApp={sendApp} infoDisplay={infoDisplay}/>
			<Merchant onPostMerchant={onPostMerchant} mstartpoint={mstartpoint} mstage={mstage} setModalOpen={setModalOpen} infoDisplay={infoDisplay} />
			<SubFooter sendApp={sendApp} infoDisplay={infoDisplay}/>
		</>
	)
};

export default MerchantScreen;
