import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FormInput } from "../../components";
import { Link } from 'react-router-dom';
import { utils } from "../../utils";

const countryOptions = utils.countryOptions;

const depn = {
	'zip': 10,
	'phone': 11,
};

const formlabel = "#a0a0a0";
const inputlabel = "#257a80";

const locateOptions = [
	{label: '', value: '', disabled: true}, 
	{label: 'Social Media', value: 'Social', disabled: false}, 
	{label: 'Radio Ads', value: 'Radio', disabled: false},
	{label: 'TV Ads', value: 'TV', disabled: false},
	{label: 'Referral', value: 'Referral', disabled: false},
	{label: 'ErrandLocal Representative', value: 'Representative', disabled: false},
	{label: 'Restaurant', value: 'Restaurant', disabled: false},
	{label: 'Other', value: 'Other', disabled: false}
];

const questOptions = [
	{label: '', value: '', disabled: true}, 
	{label: 'Yes, we have in-house delivery', value: 'Yes, we have in-house delivery', disabled: false}, 
	{label: 'Yes, we work with delivery partners', value: 'Yes, we work with delivery partners', disabled: false},
	{label: 'Both, we offer in house and work with delivery partners', value: 'Both, we offer in house and work with delivery partners', disabled: false},
	{label: 'No', value: 'No', disabled: false}
];

/*const stateOptions = [
	{label: 'Select State', value: '', disabled: false}, 
	{label: 'TX', value: 'TX', disabled: false}
];*/

const MerchantForm = ({onPostMerchant, mstartpoint, mstage}) => 
{	
	const [email, setEmail] = useState('');
	const [business, setBusiness] = useState('');	
	const [locate, setLocate] = useState('');	
	const [quest, setQuest] = useState('');	
	const [phone, setPhone] = useState('');	
	
	const [zip, setZip] = useState('');
	const [houseNo, setHouseNo] = useState('');
	const [address, setAddress] = useState('');
	const [fullname, setFullName] = useState('');
	const [city, setCity] = useState('');
	const [stage, setStage] = useState('');	
	const [startpoint, setStartPoint] = useState('');
	const [state, setState] = useState('');
	const [stateOptions, setStateOptions] = useState([]);
	const [country, setCountry] = useState('');
	const [aptsuite, setAptSuite] = useState('');
	
	useEffect(() => 
		{
			if(localStorage.getItem('merchantReg'))
			{			
				let nstage = localStorage.getItem('merchantReg');
				let nstart = localStorage.getItem('merchantStart');
				setStage(nstage);
				setStartPoint(nstart);
			}
			
		},[stage, startpoint]);
		
		
		useEffect(() => 
			{
				if(mstage !== undefined){
					setStage(mstage);
					setStartPoint(mstartpoint);	
				}
			},[mstartpoint, mstage]);
			
			
			useEffect(() => 
				{
					if(country){
						const options = utils.getStateOptions(country);
						setStateOptions(options);
						setState('');
					}
					
				},[country]);
				
				
				const processQuery = (e) => 
				{
					
					var state = document.getElementById('status2');	
					state.style.display = 'none';
					state.className = '';		
					state.innerHTML = '';
					/*
						var email = document.getElementById('email').value;
						var phone = document.getElementById('phone').value;
						var fullname = document.getElementById('fullname').value;
						var quest = document.getElementById("quest");
						var question = quest[quest.selectedIndex].innerHTML;
						var loc = document.getElementById("locate");
						var locate = loc[loc.selectedIndex].innerHTML;
					*/
					
					var business = document.getElementById('business').value;
					var address = document.getElementById('address').value;		
					var city = document.getElementById('city').value;
					var aptsuite = document.getElementById('aptsuite').value;
					var zip = document.getElementById('zip').value;
					var sel = document.getElementById('state');
					
					//var countstate = stateOptions?.length > 0 ? sel[sel?.selectedIndex].innerHTML : '';					
					var countstate = sel.value;					
					
					if(!fullname){
						state.style.display = 'block';
						state.className = 'cus_error center show';		
						state.innerHTML = '<b>Full Name is Required</b>';
						return;
						}else if(!email){
						state.style.display = 'block';
						state.className = 'cus_error center show';		
						state.innerHTML = '<b>Email Address is Required</b>';
						return;
						}else if(!phone){
						state.style.display = 'block';
						state.className = 'cus_error center show';		
						state.innerHTML = '<b>Phone Number is Required</b>';
						return;
						}else if(!business){
						state.style.display = 'block';
						state.className = 'cus_error center show';		
						state.innerHTML = '<b>Company Name is Required</b>';
						return;
						}else if(!country){
						state.style.display = 'block';
						state.className = 'cus_error center show';		
						state.innerHTML = '<b>Country is Required</b>';
						return;
						}else if(!address){
						state.style.display = 'block';
						state.className = 'cus_error center show';		
						state.innerHTML = '<b>Street Name is Required</b>';
						return;
						}else if(!city){
						state.style.display = 'block';
						state.className = 'cus_error center show';		
						state.innerHTML = '<b>City is Required</b>';
						return;
						}else if(!countstate || countstate === 'Select State' || countstate === 'Select Province'){
						state.style.display = 'block';
						state.className = 'cus_error center show';		
						state.innerHTML = '<b>State/Province is Required</b>';
						return;
						}else if(!zip){
						state.style.display = 'block';
						state.className = 'cus_error center show';		
						state.innerHTML = '<b>Zip/Postal code is Required</b>';
						return;
					}
					
					const postaddress = (houseNo+' '+address).trim();
					
					var output = {
						'data_type': 'signupMerchant',
						'data_email': email,			
						'data_fullname': fullname,
						'data_phone': phone,
						'data_address': postaddress,			
						'data_city': city,
						'data_state': countstate,
						'data_aptsuite': aptsuite,
						'data_zipcode': zip,
						'data_country': country,
						'data_companyname': business,
						'data_locate': locate,
						'data_question': quest,
					}
					onPostMerchant(output);
				}
				
				const onRestart = (e) =>
				{
					localStorage.setItem('merchantReg', '');
					localStorage.setItem('merchantStart', '');
					localStorage.removeItem('merchantReg');
					localStorage.removeItem('merchantStart');
					setStage('');
					setStartPoint('');
				}
				
				const nextStep = () =>
				{
					var state = document.getElementById('status');	
					state.style.display = 'none';
					state.className = '';		
					state.innerHTML = '';
					var email = document.getElementById('email').value;
					var phone = document.getElementById('phone').value;
					var fullname = document.getElementById('fullname').value;
					var quest = document.getElementById("quest");
					var question = quest[quest.selectedIndex].innerHTML;
					var loc = document.getElementById("locate");
					var locate = loc[loc.selectedIndex].innerHTML;
					setStage('');
					setStartPoint('');
					setQuest(question);
					setLocate(locate);
					
					if(!fullname){
						state.style.display = 'block';
						state.className = 'cus_error center show';		
						state.innerHTML = '<b>Full Name is Required</b>';
						return;
						}else if(!email){			
						state.style.display = 'block';
						state.className = 'cus_error center show';		
						state.innerHTML = '<b>Email Address is Required</b>';
						return;
						}else if(!phone){
						state.style.display = 'block';
						state.className = 'cus_error center show';		
						state.innerHTML = '<b>Phone Number is Required</b>';
						return;
					}
					
					setStage('step2');
					setStartPoint('step2');
				}
				
				
				return (
					<>
					{stage === '' && (<motion.div 
						initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }} 	
						className="form" 
						autoComplete="off"
						style={{maxWidth: 450}}
						>
						<header>
						<h1 className="form-header">{'GET STARTED'}</h1>
						</header>
						{/* Name */}
						<FormInput
						label="Full name *"
						placeholder="e.g John Doe"
						secureTextEntry="text"
						inputType="input"
						inputId="fullname"
						onChange={(e) => setFullName(e.target.value)}
						value={fullname}
						containerStyle={{
							marginTop: 15,
							width: '100%'
						}}
						inputContainerStyle={{
							backgroundColor: '#ffffff',
							width: '100%'
						}}
						inputLabelStyle={{
							color: formlabel,
						}}
						inputClass={'cus_input'}
						inputStyle={{color: inputlabel}}
						/>
						{/* Email */}
						<FormInput
						label="Email address *"
						placeholder="e.g johndoe@errandlocal.com"
						secureTextEntry="email"
						inputType="input"
						inputId="email"
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						containerStyle={{
							marginTop: 15,
							width: '100%'
						}}
						inputContainerStyle={{
							backgroundColor: '#ffffff',
							width: '100%'
						}}
						inputLabelStyle={{
							color: formlabel,
						}}
						inputClass={'cus_input'}
						inputStyle={{color: inputlabel}}
						/>
						{/* Phone */}
						<FormInput
						label="Phone number *"
						placeholder="e.g 2141234567"
						secureTextEntry="phone"
						inputType="input"
						inputId="phone"
						onChange={(e) => setPhone(e.target.value)}
						value={phone}
						maxLength={`${depn.phone}`}
						containerStyle={{
							marginTop: 15,
							width: '100%'
						}}
						inputContainerStyle={{
							backgroundColor: '#ffffff',
							width: '100%'
						}}
						inputLabelStyle={{
							color: formlabel,
						}}
						inputClass={'cus_input'}
						inputStyle={{color: inputlabel}}
						/>
						
						
						{/* Locate */}
						<FormInput
						label="How did you find us?"
						placeholder=""
						secureTextEntry="text"
						inputType="select"
						inputId="locate"
						onChange={(e) => setLocate(e.target.value)}
						value={locate}
						containerStyle={{
							marginTop: 15,
							width: '100%'
						}}
						inputContainerStyle={{
							backgroundColor: '#ffffff',
							width: '100%'
						}}
						inputLabelStyle={{
							color: formlabel,
						}}
						inputClass={'cus_select'}
						inputStyle={{color: inputlabel, backgroundColor: '#ffffff'}}
						options={locateOptions}
						/>
						
						
						{/* Quest */}
						<FormInput
						label="Do you currently offer delivery?"
						placeholder=""
						secureTextEntry="text"
						inputType="select"
						inputId="quest"
						onChange={(e) => setQuest(e.target.value)}
						value={quest}
						containerStyle={{
							marginTop: 15,
							width: '100%'
						}}
						inputContainerStyle={{
							backgroundColor: '#ffffff',
							width: '100%'
						}}
						inputLabelStyle={{
							color: formlabel,
						}}
						inputClass={'cus_select'}
						inputStyle={{color: inputlabel, backgroundColor: '#ffffff'}}
						options={questOptions}
						/>
						
						<fieldset className="card-number">
						<div style={{fontSize: 13, color: 'grey', paddingLeft: 10, paddingRight: 10, }}>By clicking "CONTINUE" you agree to ErrandLocal's Terms of Service and Privacy Policy</div>
						</fieldset>
						<fieldset className="card-number">
						<div id="status" style={{display: 'none'}}></div>
						</fieldset>	
						
						{/* Button */}
						<FormInput
						label="Continue"
						secureTextEntry="text"
						inputType="button"
						inputId="button1"
						onClick={nextStep}
						containerStyle={{
							marginTop: 5,
							width: '100%'
						}}
						inputClass={'cus_button'}
						inputStyle={{}}
						/>
						
					</motion.div>)}
					
					{stage === 'step2' && (<motion.div 
						initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }} 	
						className="form" 
						autoComplete="off"
						style={{maxWidth: 450}}
						>
						<header>
						<h1 style={{fontSize: 24, color: '#0ECB81'}}>{'Final Step!'}</h1>
						</header>
						{/* Company Name */}
						<FormInput
						label="Company name *"
						placeholder="e.g John Doe Pizza"
						secureTextEntry="text"
						inputType="input"
						inputId="business"
						onChange={(e) => setBusiness(e.target.value)}
						value={business}
						containerStyle={{
							marginTop: 15,
							width: '100%'
						}}
						inputContainerStyle={{
							backgroundColor: '#ffffff',
							width: '100%'
						}}
						inputLabelStyle={{
							color: formlabel,
						}}
						inputClass={'cus_input'}
						inputStyle={{color: inputlabel}}
						/>			
						
						{/* Country */}
						<FormInput
						label="Country *"
						placeholder="Select Country"
						secureTextEntry="text"
						inputType="select"
						inputId="country"
						onChange={(e) => setCountry(e.target.value)}
						value={country}
						containerStyle={{
							marginTop: 15,
							width: '100%'
						}}
						inputContainerStyle={{
							backgroundColor: '#ffffff',
							width: '100%'
						}}
						inputLabelStyle={{
							color: formlabel,
						}}
						inputClass={'cus_select'}
						inputStyle={{color: inputlabel, backgroundColor: '#ffffff'}}
						options={countryOptions}
						/>
						
						<fieldset className="row-flex" style={{marginTop: 0}}>
						{/* AptSuite */}
						<FormInput
						label="House No"
						placeholder="e.g 1"
						secureTextEntry="text"
						inputType="input"
						inputId="houseNo"
						onChange={(e) => setHouseNo(e.target.value)}
						value={houseNo}
						containerStyle={{
							marginTop: 15,
							width: '30%'
						}}
						inputContainerStyle={{
							backgroundColor: '#ffffff',
							width: '100%'
						}}
						inputLabelStyle={{
							color: formlabel,
						}}
						inputClass={'cus_input cus_input_half'}
						inputStyle={{color: inputlabel}}
						/>
						
						{/* Address */}
						<FormInput
						label="Street Name *"
						placeholder="e.g Main Street"
						secureTextEntry="text"
						inputType="input"
						inputId="address"
						onChange={(e) => setAddress(e.target.value)}
						value={address}
						containerStyle={{
							marginTop: 15,
							width: '100%'
						}}
						inputContainerStyle={{
							backgroundColor: '#ffffff',
							width: '100%'
						}}
						inputLabelStyle={{
							color: formlabel,
						}}
						inputClass={'cus_input cus_input_half'}
						inputStyle={{color: inputlabel, marginLeft: 3}}
						/>
						</fieldset>
						
						<fieldset className="row-flex" style={{marginTop: 0}}>
						{/* AptSuite */}
						<FormInput
						label="Apt/Suite"
						placeholder="e.g 111"
						secureTextEntry="text"
						inputType="input"
						inputId="aptsuite"
						onChange={(e) => setAptSuite(e.target.value)}
						value={aptsuite}
						containerStyle={{
							marginTop: 15,
							width: '100%'
						}}
						inputContainerStyle={{
							backgroundColor: '#ffffff',
							width: '100%'
						}}
						inputLabelStyle={{
							color: formlabel,
						}}
						inputClass={'cus_input cus_input_half'}
						inputStyle={{color: inputlabel}}
						/>
						
						{/* City */}
						<FormInput
						label="City *"
						placeholder="e.g Dallas"
						secureTextEntry="text"
						inputType="input"
						inputId="city"
						onChange={(e) => setCity(e.target.value)}
						value={city}
						containerStyle={{
							marginTop: 15,
							width: '100%'
						}}
						inputContainerStyle={{
							backgroundColor: '#ffffff',
							width: '100%'
						}}
						inputLabelStyle={{
							color: formlabel,
						}}
						inputClass={'cus_input cus_input_half'}
						inputStyle={{color: inputlabel}}
						/>
						</fieldset>
						
						<fieldset className="row-flex" style={{marginTop: 0}}>
						
						{/* State */}
						<FormInput
						label="State/Province *"
						placeholder="e.g Texas"
						secureTextEntry="text"
						inputType="select"
						inputId="state"
						onChange={(e) => setState(e.target.value)}
						value={state}
						containerStyle={{
							marginTop: 15,
							width: '100%'
						}}
						inputContainerStyle={{
							backgroundColor: '#ffffff',
							width: '100%'
						}}
						inputLabelStyle={{
							color: formlabel,
						}}
						inputClass={'cus_select'}
						inputStyle={{color: inputlabel, backgroundColor: '#ffffff'}}
						options={stateOptions}
						/>
						
						{/* Zip */}
						<FormInput
						label="Zip/Postal Code *"
						placeholder="e.g 75243, E1A 7H6"
						secureTextEntry="text"
						inputType="input"
						inputId="zip"
						onChange={(e) => setZip(e.target.value)}
						value={zip}
						maxLength={`${depn.zip}`}
						containerStyle={{
							marginTop: 15,
							width: '100%'
						}}
						inputContainerStyle={{
							backgroundColor: '#ffffff',
							width: '100%'
						}}
						inputLabelStyle={{
							color: formlabel,
						}}
						inputClass={'cus_input cus_input_half'}
						inputStyle={{color: inputlabel, marginLeft: 3}}
						/>
						</fieldset>
						
						<fieldset className="card-number">
						<div style={{fontSize: 13, color: 'grey', paddingLeft: 10, paddingRight: 10, }}>By clicking "SUBMIT" you agree to ErrandLocal's Terms of Service and Privacy Policy</div>
						</fieldset>
						<fieldset className="card-number">
						<div id="status2" style={{display: 'none'}}></div>
						</fieldset>	
						
						{/* Button */}
						<FormInput
						label="Submit"
						secureTextEntry="text"
						inputType="button"
						inputId="button2"
						onClick={processQuery}
						containerStyle={{
							marginTop: 5,
							width: '100%'
						}}
						inputClass={'cus_button'}
						inputStyle={{}}
						/>
						
						<fieldset className="card-number">
						<div><Link to="#" onClick={onRestart} className="flex font-bold items-center gap-2" style={{color: '#d13529', fontWeight: 'bold'}}> {`<<`} Go Back </Link></div>
						</fieldset>
					</motion.div>)}
					
					
					{stage === 'HomeScreen' && (<motion.div 
						initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}		
						className="form" 
						autoComplete="off"
						>
						<header>
						<h1 style={{fontSize: 24, color: '#0ECB81'}}>Merchant Registration was Successful</h1>
						</header>
						
						<header>
						<h3 style={{fontSize: 18, color: '#000000', fontWeight: 'bold', marginTop: 10}}>A link to download the ErrandLocal Merchant App have been sent to your phone</h3>
						</header>
						
						<fieldset className="card-number">				
						<div>- Download the app.</div>
						</fieldset>
						<fieldset className="card-number">
						<div>- Login with your email & Create password.</div>
						</fieldset>
						<fieldset className="card-number">
						<div>- Manage your account, Add products & Publish.</div>
						</fieldset>
						<fieldset className="card-number">
						<div>- Then you are good to go.</div>
						</fieldset>
						<fieldset className="card-number">
						<div><Link to="#" onClick={onRestart} className="flex font-bold items-center gap-2" style={{color: '#d13529', fontWeight: 'bold'}}> {`<<`} Go Back </Link></div>
						</fieldset>
						
					</motion.div>)}
					</>	
				);
};

export default MerchantForm;

