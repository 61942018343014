import React from "react";
import { Modal as Codal, Button } from "antd";

const Modal = ({ setOpenModal, modalTitle, modalBody, modalButton, setModalButtonFunc }) => {		

	if(modalButton){
		return (
			<Codal
				open={true}
				title={modalTitle ? modalTitle : 'Message'}
				onCancel={() => {setOpenModal(false)}}
				footer={[
					<Button
						key="continue"
						className="c__button"
						onClick={setModalButtonFunc}
					>
						Continue
					</Button>
				]}
			>
				<p dangerouslySetInnerHTML={{__html: modalBody}}></p>
			</Codal>		
		)
	}else{
		return (
			<Codal
				open={true}
				title={modalTitle ? modalTitle : 'Message'}
				onCancel={() => {setOpenModal(false)}}
				footer={[
					<Button
						key="close"
						className="c__button"
						onClick={() => {setOpenModal(false)}}
					>
						Close
					</Button>
				]}
			>
				<p dangerouslySetInnerHTML={{__html: modalBody}}></p>
			</Codal>		
		)
	}
}

export default Modal;

