import React, {useState, useEffect} from "react";
import { Modal, Button, Spin } from "antd";

const AlertModal = ({ setAlertOpenModal, alertModalTitle, alertModalBody, alertModalButton, alertType, setAlertModalButtonFunc, setAlertModalButtonFunc2}) => 
{
	const [loading, setLoading] = useState(true);
	
	useEffect(() => {			
		setLoading(false);
	}, []);
		
	const LoadingData = () => 
	{
		return (
			<Modal
				open={true}
				title={'Please wait'}
				onCancel={() => setAlertOpenModal(false)}
				footer={[
					<Button
						key="cancel"
						className="c__button"
						onClick={() => setAlertOpenModal(false)}
					>
						Cancel
					</Button>
				]}
				style={{
          maxHeight: '80vh',
					overflow: 'auto',
        }}
			>
				<div key="m5" style={{textAlign: 'center', height: 130, marginTop: 100}}>
					<Spin size="large"></Spin>
				</div>
			</Modal>		
		)
	}
	
	if(loading)
	{
		return (
			<LoadingData />
		);
	}
	else if(alertType === 'address' && loading === false)
	{
		if(alertModalButton === '2')
		{
			return (
				<Modal
					open={true}
					title={alertModalTitle ? alertModalTitle : 'Message'}
					onCancel={() => setAlertOpenModal(false)}
					footer={[
						<Button
							key="use"
							className="c__button"
							onClick={setAlertModalButtonFunc}
						>
							Use {alertModalTitle ? alertModalTitle : 'Address'}
						</Button>,
						<Button
							key="edit"
							className="c__button"
							onClick={setAlertModalButtonFunc2}
						>
							Edit {alertModalTitle ? alertModalTitle : 'Address'}
						</Button>
					]}
					style={{
						maxHeight: '80vh',
						overflow: 'auto',
					}}
				>
					<p dangerouslySetInnerHTML={{__html: alertModalBody}}></p>
				</Modal>		
			)	
		}
		else if(alertModalButton === '1')
		{
			return (
				<Modal
					open={true}
					title={alertModalTitle ? alertModalTitle : 'Message'}
					onCancel={() => setAlertOpenModal(false)}
					footer={[
						<Button
							key="edit"
							className="c__button"
							onClick={setAlertModalButtonFunc2}
						>
							Edit {alertModalTitle ? alertModalTitle : 'Address'}
						</Button>
					]}
					style={{
						maxHeight: '80vh',
						overflow: 'auto',
					}}
				>
					<p dangerouslySetInnerHTML={{__html: alertModalBody}}></p>
				</Modal>		
			)	
		}
		else
		{
			return (
				<Modal
					open={true}
					title={alertModalTitle ? alertModalTitle : 'Message'}
					onCancel={() => setAlertOpenModal(false)}
					footer={[
						<Button
							key="back"
							className="c__button"
							onClick={() => setAlertOpenModal(false)}
						>
							Close
						</Button>
					]}
					style={{
						maxHeight: '80vh',
						overflow: 'auto',
					}}
				>
					<p dangerouslySetInnerHTML={{__html: alertModalBody}}></p>
				</Modal>		
			)	
		}	
	}	
	else if(alertType === 'card' && loading === false)
	{
		if(alertModalButton === '2')
		{
			return (
				<Modal
					open={true}
					title={alertModalTitle ? alertModalTitle : 'Message'}
					onCancel={() => setAlertOpenModal(false)}
					footer={[
						<Button
							key="use"
							className="c__button"
							onClick={setAlertModalButtonFunc}
						>
							Use {/*alertModalTitle ? alertModalTitle : 'Card'*/}
						</Button>,
						<Button
							key="edit"
							className="c__button"
							onClick={setAlertModalButtonFunc2}
						>
							Delete {/*alertModalTitle ? alertModalTitle : 'Card'*/}
						</Button>
					]}
					style={{
						maxHeight: '80vh',
						overflow: 'auto',
					}}
				>
					<p dangerouslySetInnerHTML={{__html: alertModalBody}}></p>
				</Modal>		
			)	
		}
		else if(alertModalButton === '1')
		{
			return (
				<Modal
					open={true}
					title={alertModalTitle ? alertModalTitle : 'Message'}
					onCancel={() => setAlertOpenModal(false)}
					footer={[
						<Button
							key="edit"
							className="c__button"
							onClick={setAlertModalButtonFunc2}
						>
							Delete {alertModalTitle ? alertModalTitle : 'Card'}
						</Button>
					]}
					style={{
						maxHeight: '80vh',
						overflow: 'auto',
					}}
				>
					<p dangerouslySetInnerHTML={{__html: alertModalBody}}></p>
				</Modal>		
			)	
		}
		else
		{
			return (
				<Modal
					open={true}
					title={alertModalTitle ? alertModalTitle : 'Message'}
					onCancel={() => setAlertOpenModal(false)}
					footer={[
						<Button
							key="back"
							className="c__button"
							onClick={() => setAlertOpenModal(false)}
						>
							Close
						</Button>
					]}
					style={{
						maxHeight: '80vh',
						overflow: 'auto',
					}}
				>
					<p dangerouslySetInnerHTML={{__html: alertModalBody}}></p>
				</Modal>		
			)	
		}	
	}	
	else if(alertType === 'signout' && loading === false)
	{
		if(alertModalButton === '2')
		{
			return (
				<Modal
					open={true}
					title={alertModalTitle ? alertModalTitle : 'Message'}
					onCancel={() => setAlertOpenModal(false)}
					footer={[
						<Button
							key="use"
							className="c__button"
							onClick={setAlertModalButtonFunc}
						>
							Cancel
						</Button>,
						<Button
							key="edit"
							className="c__button"
							onClick={setAlertModalButtonFunc2}
						>
							Sign Me Out!
						</Button>
					]}
					style={{
						maxHeight: '80vh',
						overflow: 'auto',
					}}
				>
					<p dangerouslySetInnerHTML={{__html: alertModalBody}}></p>
				</Modal>		
			)	
		}
		else
		{
			return (
				<Modal
					open={true}
					title={alertModalTitle ? alertModalTitle : 'Message'}
					onCancel={() => setAlertOpenModal(false)}
					footer={[
						<Button
							key="back"
							className="c__button"
							onClick={() => setAlertOpenModal(false)}
						>
							Close
						</Button>
					]}
					style={{
						maxHeight: '80vh',
						overflow: 'auto',
					}}
				>
					<p dangerouslySetInnerHTML={{__html: alertModalBody}}></p>
				</Modal>		
			)	
		}	
	}	
	else
	{
		return (
			<Modal
				open={true}
				title={alertModalTitle ? alertModalTitle : 'Message'}
				onCancel={() => setAlertOpenModal(false)}
				closable={false}
				maskClosable={false}
				footer={[
					<Button
						key="back"
						className="c__button"
						onClick={() => setAlertOpenModal(false)}
					>
						Cancel
					</Button>
				]}
				style={{
					maxHeight: '80vh',
					overflow: 'auto',
				}}
			>
				<p dangerouslySetInnerHTML={{__html: alertModalBody}}></p>
			</Modal>		
		)
	}
  
}

export default AlertModal;

