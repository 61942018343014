import spoon from '../assets/spoon.svg';
import req1 from '../assets/r1.png';
import req2 from '../assets/r2.png';
import req3 from '../assets/r3.png';
import req4 from '../assets/r4.png';
import driver from '../assets/driver.png';
import drivererrandlocal from '../assets/drivererrandlocal.png';
import merchanterrandlocal from '../assets/merchanterrandlocal.png';
import fullerrandlocal from '../assets/fullgreen.svg';
import foodcap from '../assets/foodcap.png';
import smilingman from '../assets/smilingman.png';
import dl from '../assets/dl.png';
import notfound from '../assets/notfound.png';
import errandlocal from '../assets/errandlocal50.svg';
import appstore from '../assets/appstore.svg';
import appstore2 from '../assets/appstore2.svg';
import googleplay from '../assets/googleplay.svg';
import cart from '../assets/cart.svg';
import couponsample from '../assets/couponsample.png';

export default {
  spoon,
  errandlocal,
  appstore,
  appstore2,
  googleplay,
  req1,
  req2,
  req3,
  req4,
  driver,
  dl,
  drivererrandlocal,
  merchanterrandlocal,
  fullerrandlocal,
  foodcap,
  smilingman,
  notfound,
  cart,
	couponsample
};
