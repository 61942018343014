import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { utils } from "../../utils";
import { useNavigate } from 'react-router-dom';
import { message } from "antd";
import './SMSOptInScreen.css'; 

function SMSOptInScreen() {
	const navigate = useNavigate();
	
  const [isChecked, setIsChecked] = useState(false);
  const [phone, setPhone] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
		
			if(!isChecked){
				message.error('Agree by checking the box.');
				return;				
			}
			const mobile = phone.replace(/\D/g, '');
			if(mobile.length !== 10){
				message.error('Provide a valid phone number.');
				return;				
			}
		
			let headers = {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			};			
			const target = utils.USER_URL;
			const header = new Headers(headers);
			const posturl = utils.BASE_URL+target;
			const dataToPost = JSON.stringify({'rtype': 'subscribe', 'phone': mobile, 'data_type': 'subscribe'});
			const res = await utils.fetchURL(posturl, 'POST', dataToPost, header);
			const data = await res.json();			
			if(data?.msg && data.msg === 'Success'){
				message.success('You have successfully subscribed!');
				setTimeout(()=>{
					navigate('/');	
				}, 5000);
			}else{
				message.error(data?.msg);
			}
			
    } catch (e) {			
      message.error('Failed to subscribe. Please try again later.');
			const report = await utils.reportError({
        exceptionMsg: e.message,
        postFunction: 'handleSubmit',
        screen: 'SMSCampaignScreen',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="opt-in-form">
      <h2>Register Your Mobile Number</h2>
      <p>Stay informed with ErrandLocal Alerts by receiving timely order notifications, exclusive promotions, and important marketing updates via SMS.</p>
      <label htmlFor="phone">Mobile Number</label>
      <InputMask type="text" mask="(999) 999-9999" id="phone" className="textbox" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="e.g., (555)-555-5555" required />
      <label htmlFor="checkbox">
        <input type="checkbox" id="checkbox" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
        <span>By checking the check box, I agree to receive marketing, notifications, and other ErrandLocal messages via SMS.</span>
      </label>
      <button type="submit" disabled={!isChecked}>Subscribe</button>
    </form>
  );
}

function SMSCampaignScreen() {
  return (
    <div className="campaign-screen">
      <h1>ErrandLocal's SMS Opt-In Service</h1>
      <p><a href="./">Continue to home page</a></p>
      <SMSOptInScreen />
    </div>
  );
}

export default SMSCampaignScreen;


