import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { utils } from "../../utils";
import { Button } from "antd";

function QRDownloadScreen({logPage}) 
{	
	const { category, identifier } = useParams();
	const location = useLocation();
	
	const [ done, setDone ] = useState(false);
	
  useEffect(() => {		
		downloadRedirect();
  }, [category]);	
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, []);
	
	const goHome = () => {
		window.location.href = 'https://errandlocal.com';
	}
	const goSignUp = () => {
		window.location.href = 'https://errandlocal.com/signup';
	}
	
	const downloadRedirect = async () => {
		
		const userAgent = window.navigator.userAgent.toLowerCase();	
		const channel = utils.convertAddress(identifier);	
		const download = await logDownload(category, userAgent, channel);
		
		if(category.toLowerCase() === 'user')
		{
			setDone(true);
			if (userAgent.includes('android')) {
				window.location.href = 'https://play.google.com/store/apps/details?id=com.errandlocal.app';
			}
			else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
				window.location.href = 'https://apps.apple.com/us/app/errandlocal/id1644999271';
			}
			else {
				window.location.href = 'https://errandlocal.com';
			}
		}
		else if(category.toLowerCase() === 'driver')
		{
			setDone(true);
			if (userAgent.includes('android')) {
				window.location.href = 'https://play.google.com/store/apps/details?id=com.errandlocal.driverapp';
			}
			else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
				window.location.href = 'https://apps.apple.com/us/app/errandlocal-driver/id1645037444';
			}
			else {
				window.location.href = 'https://errandlocal.com';
			}
		}
		else if(category.toLowerCase() === 'merchant')
		{
			setDone(true);
			if (userAgent.includes('android')) {				
				window.location.href = 'https://play.google.com/store/apps/details?id=com.errandlocal.merchantapp';
			}
			else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
				window.location.href = 'https://apps.apple.com/us/app/errandlocal-restaurant/id6443399679';
			}
			else {
				window.location.href = 'https://errandlocal.com';
			}
		}
		else
		{
			setDone(true);
			goSignUp();
		}	
	}
	
	
	const logDownload = async (category, useragent, channel) => {
		try{
			let url = utils.APP_DOWNLOAD+'?category='+category+'&useragent='+useragent+'&channel='+channel;			
			const header = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '
			};
			const dataToPost = "";
			const res = await utils.fetchURL(url, 'GET', dataToPost, header);		
			const data = await res.json();
			return data?.status;
		}catch(e){}	
	}

  return (
    <div className="App-download">
      <header className="App-download-header">
				{!done && (<p>Redirecting...</p>)}
        {done && (<Button type={`primary`} size={`large`}onClick={()=> goHome()}>GO TO HOME PAGE</Button>)}
        {/* You can add loading spinners or other content here */}
      </header>
    </div>
  );
}

export default QRDownloadScreen;
