//https://github.com/DeploySentinel/Recorder/blob/main/src/pages/Content/recorder.ts

import React, { useEffect, useState } from "react";
import debounce from "lodash.debounce";

const genSelectors = (element) => {
  if (!element) {
    return null;
  }

  const selectors = {
    generalSelector: getGeneralSelector(element),
    // Add more specific selectors as needed based on your requirements
  };

  return selectors;
}

const getGeneralSelector = (element) => {
  if (!element) {
    return null;
  }

  const tagName = element.tagName.toLowerCase();
  const classNames = Array.from(element.classList).join('.');

  return `${tagName}${classNames ? `.${classNames}` : ''}`;
}

const ActionType = {
  Resize: "resize",
  DragAndDrop: "drag-and-drop",
  FullScreenshot: "full-screenshot",
  Wheel: "wheel",
  // Add more action types as needed
};

const isEventFromOverlay = (event) => {
  // Placeholder implementation - adjust based on your actual overlay elements
  const overlayElement = document.getElementById('overlay-controls');

  if (!overlayElement) {
    return false; // No overlay element found
  }

  // Check if the event's path contains the overlay element
  return event.composedPath().includes(overlayElement);
}

function _shouldGenerateKeyPressFor(event: KeyboardEvent): boolean {
  // Backspace, Delete, AltGraph are changing input, will handle it there.
  if (['AltGraph', 'Backspace', 'Delete'].includes(event.key)) return false;
  // Ignore the QWERTZ shortcut for creating a at sign on MacOS
  if (event.key === '@' && event.code === 'KeyL') return false;
  // Allow and ignore common used shortcut for pasting.
  if (navigator.platform.includes('Mac')) {
    if (event.key === 'v' && event.metaKey) return false;
  } else {
    if (event.key === 'v' && event.ctrlKey) return false;
    if (event.key === 'Insert' && event.shiftKey) return false;
  }
  if (['Shift', 'Control', 'Meta', 'Alt'].includes(event.key)) return false;
  const hasModifier = event.ctrlKey || event.altKey || event.metaKey;
  if (event.key.length === 1 && !hasModifier) return false;
  return true;
}

const Recorder = () => {
  const [recording, setRecording] = useState([]);
  const [currentEventHandleType, setCurrentEventHandleType] = useState(null);
  const [lastContextMenuEvent, setLastContextMenuEvent] = useState(null);

  const appendToRecording = (action) => {
    setRecording((prevRecording) => {
      const newRecording = [...prevRecording, action];
      localStorage.setItem("recording", JSON.stringify(newRecording));
      console.log("Recording:", newRecording);
      // Additional logic to handle Playwright script generation can be added here
      return newRecording;
    });
  };

  const updateLastRecordedAction = (actionUpdate) => {
    setRecording((prevRecording) => {
      const lastAction = prevRecording[prevRecording.length - 1];
      const newAction = {
        ...lastAction,
        ...actionUpdate,
      };
      const newRecording = [...prevRecording];
      newRecording[prevRecording.length - 1] = newAction;
      localStorage.setItem("recording", JSON.stringify(newRecording));
      console.log("Recording:", newRecording);
      // Additional logic to handle Playwright script generation can be added here
      return newRecording;
    });
  };

  const checkAndSetDuplicateEventHandle = (event) => {
    if (currentEventHandleType != null) {
      return true; // This is a duplicate handle
    }
    setCurrentEventHandleType(event.type);

    setTimeout(() => {
      setCurrentEventHandleType(null);
    }, 0);
    return false; // This was not a duplicate handle
  };

  useEffect(() => {
    const handleResize = () => {
      const lastResizeAction = getLastResizeAction();
      const { innerWidth: width, innerHeight: height } = window;
      if (
        lastResizeAction == null ||
        lastResizeAction.width !== width ||
        lastResizeAction.height !== height
      ) {
        const action = {
          type: ActionType.Resize,
          width,
          height,
        };

        appendToRecording(action);
      }
    };

    const getLastResizeAction = () => {
      return recording.reduceRight((p, v) => {
        if (p != null) {
          return p;
        }
        if (v.type === ActionType.Resize) {
          return v;
        }
      }, null);
    };

    const debouncedOnResize = debounce(handleResize, 300);

    window.addEventListener("resize", debouncedOnResize, true);

    return () => {
      window.removeEventListener("resize", debouncedOnResize, true);
    };
  }, [recording]);

	const onMouseWheel = (event) => {
		if (isEventFromOverlay(event)) {
			return;
		}

		const lastAction = recording[recording.length - 1];

		const { pageXOffset, pageYOffset } = window;

		if (
			lastAction.type === ActionType.Wheel &&
			// We should record a new event if we've changed scroll directions
			Math.sign(lastAction.deltaX) === Math.sign(event.deltaX) &&
			Math.sign(lastAction.deltaY) === Math.sign(event.deltaY)
		) {
			updateLastRecordedAction({
				deltaX: Math.floor(lastAction.deltaX + event.deltaX),
				deltaY: Math.floor(lastAction.deltaY + event.deltaY),
				pageXOffset,
				pageYOffset,
			});
		} else {
			const action = {
				type: ActionType.Wheel,
				deltaX: Math.floor(event.deltaX),
				deltaY: Math.floor(event.deltaY),
				pageXOffset,
				pageYOffset,
			};
			appendToRecording(action);
		}
	};

  const onClick = (event) => {
    if (event.isTrusted === false) {
      // Ignore synthetic events
      return;
    }
    if (checkAndSetDuplicateEventHandle(event)) {
      return;
    }

    const target = event.target;

    // Choose the parent element if it's a link, since we probably want the link
    const { parentElement } = target;
    const predictedTarget =
      parentElement?.tagName === "A" ? parentElement : target;

    const action = {
      ...buildBaseAction(event, predictedTarget),
			type: 'click',
			targetX: event.x,
      targetY: event.y
    };

    appendToRecording(action);
  };

  const onDrag = (event) => {
    const lastAction = recording[recording.length - 1];

    if (event.type === "dragstart") {
      appendToRecording({
        ...buildBaseAction(event),
        type: ActionType.DragAndDrop,
        sourceX: event.x,
        sourceY: event.y,
      });
    } else if (
      event.type === "drop" &&
      lastAction.type === ActionType.DragAndDrop
    ) {
      updateLastRecordedAction({
        targetX: event.x,
        targetY: event.y,
      });
    }
  };

  const onKeyDown = (event) => {
		if (isEventFromOverlay(event)) {
			return;
		}

		if (!_shouldGenerateKeyPressFor(event)) {
			return;
		}

		// We're committed to handling, check and set handling flag
		if (checkAndSetDuplicateEventHandle(event)) {
			return;
		}

		const action = {
			...buildBaseAction(event),
			key: event.key,
		};

		appendToRecording(action);
	};
/*
	const onKeyUp = (event) => {
		if (isEventFromOverlay(event)) {
			return;
		}

		if (checkAndSetDuplicateEventHandle(event)) {
			return;
		}

		const target = event.target;
		const action = {
			...buildBaseAction(event),
			key: event.key,
		};

		appendToRecording(action);
	};
	
	const onMouseOver = (event) => {
		if (isEventFromOverlay(event)) {
			return;
		}

		if (checkAndSetDuplicateEventHandle(event)) {
			return;
		}

		
		const target = event.target;
		const { parentElement } = target;
		const predictedTarget =
			parentElement?.tagName === 'A' ? parentElement : target;

		// Create a tooltip element
		const tooltip = document.createElement('div');
		tooltip.textContent = `TagName: ${target.tagName.toLowerCase()}`;
		tooltip.style.position = 'absolute';
		tooltip.style.background = 'rgba(0, 0, 0, 0.8)';
		tooltip.style.color = 'white';
		tooltip.style.padding = '5px';
		tooltip.style.borderRadius = '3px';
		tooltip.style.zIndex = '9999';

		// Position the tooltip next to the mouse pointer
		tooltip.style.left = `${event.clientX + 10}px`;
		tooltip.style.top = `${event.clientY + 10}px`;

		// Append the tooltip to the document body
		document.body.appendChild(tooltip);

		// Remove the tooltip after a short delay
		setTimeout(() => {
			document.body.removeChild(tooltip);
		}, 2000);

		//appendToRecording(action);
	};
	
	const onMouseDown = (event) => {
		if (isEventFromOverlay(event)) {
			return;
		}

		if (checkAndSetDuplicateEventHandle(event)) {
			return;
		}

		const target = event.target;

		// Choose the parent element if it's a link, since we probably want the link
		const { parentElement } = target;
		const predictedTarget =
			parentElement?.tagName === 'A' ? parentElement : target;

		const action = {
			...buildBaseAction(event, predictedTarget),
			type: 'mousedown',
			// Add additional properties specific to mousedown event if needed
		};

		appendToRecording(action);
	};

	const onMouseUp = (event) => {
		if (isEventFromOverlay(event)) {
			return;
		}

		if (checkAndSetDuplicateEventHandle(event)) {
			return;
		}

		const action = {
			...buildBaseAction(event),
			type: 'mouseUp',
		};

		appendToRecording(action);
	};

	const onMouseMove = (event) => {
		if (isEventFromOverlay(event)) {
			return;
		}

		if (checkAndSetDuplicateEventHandle(event)) {
			return;
		}

		const action = {
			...buildBaseAction(event),
			type: 'mousemove',
			clientX: event.clientX,
			clientY: event.clientY,
		};

		appendToRecording(action);
	};
	
	const onMouseLeave = (event) => {
		if (isEventFromOverlay(event)) {
			return;
		}

		if (checkAndSetDuplicateEventHandle(event)) {
			return;
		}

		const action = {
			...buildBaseAction(event),
			type: 'mouseLeave',
		};

		appendToRecording(action);
	};
	
	const onFocus = (event) => {
		if (isEventFromOverlay(event)) {
			return;
		}

		if (checkAndSetDuplicateEventHandle(event)) {
			return;
		}

		const target = event.target;

		const action = {
			...buildBaseAction(event, target),
			type: 'focus',
		};

		appendToRecording(action);
	};
	
	const onScroll = (event) => {
		if (isEventFromOverlay(event)) {
			return;
		}
		
		if (checkAndSetDuplicateEventHandle(event)) {
			return;
		}

		const target = event.target;
		console.log('Last scroll recording: ', recording);
		const lastAction = recording[recording.length - 1];
		console.log('Last scroll action: ', lastAction);

		if (lastAction && lastAction?.type === 'scroll' && target === window) {
			// If the last action was also a scroll on the window, update the existing action
			updateLastRecordedAction({
				scrollX: window.scrollX,
				scrollY: window.scrollY,
			});
		} else {
			// Record a new scroll action
			const action = {
				type: 'scroll',
				scrollX: window.scrollX,
				scrollY: window.scrollY,
			};
			appendToRecording(action);
		}
	};
*/
  const onContextMenu = (event) => {
    setLastContextMenuEvent(event);
  };

  const onBackgroundMessage = (request) => {
    // Context menu was clicked, pull the last context menu element
    if (lastContextMenuEvent != null) {
      const action = {
        ...buildBaseAction(lastContextMenuEvent),
        type: "contextMenu",
        selectors: genSelectors(lastContextMenuEvent.target),
      };
      appendToRecording(action);
    }
  };

  const onInput = (event) => {
		if (isEventFromOverlay(event)) {
			return;
		}

		if (checkAndSetDuplicateEventHandle(event)) {
			return;
		}
		
		const target = event.target;
		const selectors = genSelectors(target);
		console.log('Last recording: ', recording);
		const lastAction = recording[recording.length - 1];
		console.log('Last action: ', lastAction);

		if (
			lastAction?.type === 'input' &&
			lastAction?.selectors?.generalSelector === selectors?.generalSelector
		) {
			updateLastRecordedAction({
				value: target?.value,
				timestamp: event.timeStamp,
			});
		} else {
			const action = {
				...buildBaseAction(event),
				value: target?.value,
			};
			appendToRecording(action);
		};
	};


  /*

  private _onInput(event: Event) {
    
    const target = deepEventTarget(event);
    if (['INPUT', 'TEXTAREA'].includes(target.nodeName)) {
      const inputElement = target;
      const elementType = (inputElement.type || '').toLowerCase();
      if (elementType === 'checkbox') {
        // Checkbox is handled in click, we can't let input trigger on checkbox - that would mean we dispatched click events while recording.
        return;
      }

      if (elementType === 'file') {
        globalThis._playwrightRecorderRecordAction({
          name: 'setInputFiles',
          selector: this._activeModel!.selector,
          signals: [],
          files: [...(inputElement.files || [])].map(file => file.name),
        });
        return;
      }

      // Non-navigating actions are simply recorded by Playwright.
      if (this._consumedDueWrongTarget(event))
        return;
      globalThis._playwrightRecorderRecordAction({
        name: 'fill',
        selector: this._activeModel!.selector,
        signals: [],
        text: inputElement.value,
      });
    }

    if (target.nodeName === 'SELECT') {
      const selectElement = target as HTMLSelectElement;
      if (this._actionInProgress(event))
        return;
      this._performAction({
        name: 'select',
        selector: this._hoveredModel!.selector,
        options: [...selectElement.selectedOptions].map(option => option.value),
        signals: []
      });
    }
  }
	*/
  const onFullScreenshot = () => {
    const action = {
      type: ActionType.FullScreenshot,
    };
		
    appendToRecording(action);
  };
	
	const getLastResizeAction = () => {
    for (let i = recording.length - 1; i >= 0; i--) {
      const action = recording[i];
      if (action.type === 'resize') {
        return action;
      }
    }
    return null;
  };	
	
  const debouncedOnResize = debounce(() => {
    const lastResizeAction = getLastResizeAction();
    const { innerWidth: width, innerHeight: height } = window;

    if (
      lastResizeAction == null ||
      lastResizeAction.width !== width ||
      lastResizeAction.height !== height
    ) {
      const action = {
        type: ActionType.Resize,
        width,
        height,
      };

      appendToRecording(action);
    }
  }, 300);

  const buildBaseAction = (event, overrideTarget) => {
    const target = overrideTarget || event.target;

    return {
      isPassword:
        target instanceof HTMLInputElement &&
        target.type.toLowerCase() === "password",      
      type: event.target,
      tagName: target.tagName.toLowerCase(),
      inputType: target instanceof HTMLInputElement ? target.type : undefined,
      selectors: genSelectors(target) || {},
      timestamp: event.timeStamp,
      hasOnlyText: target.children.length === 0 && target.innerText.length > 0,
      value: undefined,
    };
  };

  useEffect(() => {
    const storedRecording = [];
    setRecording(storedRecording);
			
		window.addEventListener('click', onClick, true);
		window.addEventListener('contextmenu', onContextMenu, true);
		window.addEventListener('dragstart', onDrag, true);
		window.addEventListener('drop', onDrag, true);
		window.addEventListener('keydown', onKeyDown, true);		
		window.addEventListener('input', onInput, true);
		window.addEventListener('resize', debouncedOnResize, true);
		window.addEventListener('wheel', onMouseWheel, true);
		//window.addEventListener('scroll', onScroll, true);
		//window.addEventListener('keyup', onKeyUp, true);
		//window.addEventListener('mouseover', onMouseOver, true);
		//window.addEventListener('mousedown', onMouseDown, true);
		//window.addEventListener('mouseup', onMouseUp, true);
		//window.addEventListener('mousemove', onMouseMove, true);
		//window.addEventListener('mouseleave', onMouseLeave, true);
		//window.addEventListener('focus', onFocus, true);

    return () => {
      window.removeEventListener('click', onClick, true);
			window.removeEventListener('contextmenu', onContextMenu, true);
			window.removeEventListener('dragstart', onDrag, true);
			window.removeEventListener('drop', onDrag, true);
			window.removeEventListener('keydown', onKeyDown, true);			
			window.removeEventListener('input', onInput, true);
			window.removeEventListener('resize', debouncedOnResize, true);
			window.removeEventListener('wheel', onMouseWheel, true);
			//window.removeEventListener('scroll', onScroll, true);
			//window.removeEventListener('keyup', onKeyUp, true);
			//window.removeEventListener('mouseover', onMouseOver, true);
			//window.removeEventListener('mousedown', onMouseDown, true);
			//window.removeEventListener('mouseup', onMouseUp, true);
			//window.removeEventListener('mousemove', onMouseMove, true);
			//window.removeEventListener('mouseleave', onMouseLeave, true);
			//window.removeEventListener('focus', onFocus, true);
    };
  }, []);
	
	return null;

}

export default Recorder;
