import React, { useState, useEffect, useRef } from 'react';
import { utils } from '../../utils';
import { v4 as uuidv4 } from 'uuid';
import { Drawer, Button, Space, Spin, message, Alert } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

window['headerName'] = 'Title';
window['visible'] = false;

const OrderModal = ({
  cartType,
  setCartSubTotal,
  cartSubTotal,
  refId,
  headqId,
  cartData,
  updateCartData,
  orderCart,
  setOpenOrderModal,
  openOrderModal,
  saveStore,
  savedStores,
  setCheckOutError,
  deliveryInfo,
  shopHours,
  shopStoreName,
  shopStoreCity,
  shopStoreZip,
  shopStoreState,
  updateDeliveryData,
}) => {
  const [open, setOpen] = useState(openOrderModal);
  const [gridData, setGridData] = useState([]);
  const [cartId, setCartId] = useState('');
  const [cartTotal, setCartTotal] = useState(0);
  const [cartQuantity, setCartQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [storeId, setStoreId] = useState('');
  const [hqId, setHqId] = useState('');
  const [itemId, setItemId] = useState('');
  const [itemCurrency, setItemCurrency] = useState('');
  const [msgType, setMsgType] = useState('');
  const [msgBody, setMsgBody] = useState('');
  const [msgHead, setMsgHead] = useState('');
  const cartDivRef = useRef(null);
  const authToken = utils.getStorage('__el_data_login_token');
  //const [visible, setVisible] = useState(false);
  //const [orderNote, setOrderNote] = useState("");

  //let addonArr = [];
  useEffect(() => {
    const getData = async () => {
      if (cartType === 'custom') {
        //console.log(headqId, cartData.id);
        await runData(headqId, cartData.id);

        // eslint-disable-next-line
        window['quantity'] = 1;
      } else {
        let prevOrders = orderCart['orderPlaced'];
        for (var i = 0; i < prevOrders.length; i++) {
          if (cartData === prevOrders[i].product.cartId) {
            setItemId(prevOrders[i].product.id);
            setStoreId(prevOrders[i].product.storeId);
            setHqId(prevOrders[i].product.hqId);
            setCartTotal(prevOrders[i].product.displayprice);
            setCartQuantity(prevOrders[i].product.quantity);
            window['quantity'] = prevOrders[i].product.quantity;
            await runData(prevOrders[i].product.hqId, prevOrders[i].product.id);
            // eslint-disable-next-line
          }
        }
        setCartId(cartData);
      }
    };
    getData();
  }, [open, cartData, cartType, orderCart, refId, headqId]);

  useEffect(() => {
    const checkData = () => {
      if (cartType === 'custom') {
        initCustom(refId, cartData.id);
      } else {
        initCustom(storeId, itemId);
        setCartId(cartData);
      }
      setLoading(false);
    };
    checkData();
  }, [gridData, refId, cartData, itemId, storeId, cartType]);

  useEffect(() => {
    window.document.body.style.overflow = open ? 'hidden' : '';
  }, [open]);

  useEffect(() => {
    setHqId(headqId);
  }, [headqId]);

  function scrollToBottom() {
    cartDivRef.current.scrollTop = cartDivRef.current.scrollHeight;
  }

  const runData = async (shopId, itemId) => {
    let found = 0;
    for (let i = 0; i < savedStores.length; i++) {
      const thisStore = await savedStores[i].shopId;
      const thisStoreItem = await savedStores[i].itemId;
      if (thisStore + '' + thisStoreItem === shopId + '' + itemId) {
        setGridData(savedStores[i]?.relationships);
        setStoreId(savedStores[i]?.shopId);
        setItemId(savedStores[i]?.itemId);
        setCartSubTotal(savedStores[i]?.price.amount);
        //console.log('Saved', savedStores[i]?.price.amount);
        setItemCurrency(savedStores[i]?.price.currency);
        found = 1;
      }
    }

    if (found === 0 && loading) {
      try {
        //const target = await utils.STORE_MENU_URL+"?storeId="+shopId+"&itemId="+itemId;
        const target =
          (await utils.STORE_MENU_URL) +
          '?storeId=' +
          shopId +
          '&itemId=' +
          itemId +
          '&options=full';
        const header = new Headers({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + authToken,
        });
        const posturl = utils.BASE_URL + target; //utils.GET_URL;
        const dataToPost = '';
        const resp = await utils.fetchURL(posturl, 'GET', dataToPost, header);
        let response = await resp.json();
        //console.log(response);
        if (
          response?.response &&
          response?.status &&
          response?.status === 'Success'
        ) {
          response = await JSON.parse(utils.cipherDecode(response.response));
        }

        if (response?.relationships) {
          setGridData(response?.relationships);
          setStoreId(response?.shopId);
          setItemId(response?.itemId);
          setCartSubTotal(response?.price.amount);
          //console.log('Relate', response?.price.amount);
          setItemCurrency(response?.price.currency);
          const entry = [...savedStores, response];
          saveStore(entry);
          setLoading(false);
        } else {
          setStoreId(response?.storeId);
          setItemId(response?.itemId);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    }
  };

  const initCustom = (shopId, itemId) => {
    if (Object.keys(gridData).length === 0) {
      return;
    }
  };

  const LoadCustomData = () => {
    window['headerName'] = cartData?.name;
    return (
      <>
        <div className="_a30bb3afbc" tabIndex="0" ref={cartDivRef}>
          {cartData?.image && !cartData.image.includes('_unavailable') && (
            <div className="_12e5920ed3" key={uuidv4()}>
              <picture>
                <source
                  key={uuidv4()}
                  media="(min-width: 1100px)"
                  srcSet={`${cartData?.image}?fit=crop&amp;w=4000&amp;h=2500 2000w,${cartData?.image}?fit=crop&amp;w=3000&amp;h=1874 1500w`}
                  alt={cartData?.name}
                  className="_794b886035"
                />
                <source
                  key={uuidv4()}
                  media="(min-width: 480px)"
                  srcSet={`${cartData?.image}?fit=crop&amp;w=2000&amp;h=1250 1000w,${cartData?.image}?fit=crop&amp;w=1000&amp;h=624 500w`}
                  alt={cartData?.name}
                  className="_794b886035"
                />
                <img
                  key={uuidv4()}
                  src={`${cartData?.image}?fit=crop&amp;w=1000&amp;h=624 500w`}
                  alt={cartData?.name}
                  className="_794b886035"
                />
              </picture>
            </div>
          )}

          <div className="_7e87360585" key={uuidv4()}>
            {cartData?.description ? cartData?.description : ''}
          </div>

          <div className="_4118145a06" key={uuidv4()}>
            {gridData.map((addonData, index) => (
              <AddonData
                addonData={addonData}
                index={index}
                data-checked={index === 0 ? 'true' : 'false'}
                key={uuidv4()}
              />
            ))}
          </div>

          <div className="_7947f1af14" key={uuidv4()}>
            <div className="_2e1869a6f2" key={uuidv4()}>
              <div className="_5d92860664">Notes to store</div>
            </div>

            <div className="_2fe64c4d36" key={uuidv4()}>
              <label className="_63c33e3a1b" htmlFor="specialRequest">
                <textarea
                  placeholder="Have an allergy, dietary restriction, or special request?"
                  maxLength="240"
                  name="specialRequest"
                  className="_da39275192 _49a7b28354"
                ></textarea>
              </label>
            </div>

            <Space className="alertbutton _2fe64c4d36" direction="vertical">
              {window['visible'] && (
                <Alert message={msgHead} description={msgBody} type={msgType} />
              )}
            </Space>
          </div>
        </div>
      </>
    );
  };

  const AddonData = ({ addonData, index }) => {
    window['minlimit' + addonData.id] = addonData.minlimit;
    window['maxlimit' + addonData.id] = addonData.maxlimit;
    const thisRef = uuidv4();

    if (index >= 0) {
      return (
        <div
          className="_b92fb3c088"
          aria-checked={addonData?.productId === itemId ? 'true' : 'false'}
          data-addon={'addons' + addonData?.id}
          data-productparent={'productparent' + addonData?.productId}
          aria-hidden={addonData?.productId === itemId ? 'false' : 'true'}
          key={index + 'addons'}
        >
          <div key={uuidv4()}>
            {addonData.minlimit >= 0 && (
              <div className="_2e1869a6f2" key={uuidv4()}>
                <div className="_7651043074" key={uuidv4()}>
                  {addonData.info}
                </div>
                <div className="_ed0ebb9231" key={uuidv4()}>
                  {getLimit(addonData.minlimit, addonData.maxlimit)}
                </div>
              </div>
            )}

            <div className="_d44445365a">
              {addonData.minlimit >= 0 &&
                addonData.data.map((productData, index) => (
                  <div
                    className="_b4e34f9f22"
                    key={uuidv4()}
                    onClick={() =>
                      CheckOption(
                        productData?.price.amount,
                        addonData.minlimit,
                        addonData.maxlimit,
                        productData?.id,
                        productData?.productId,
                        addonData?.id,
                        thisRef,
                        productData?.next
                      )
                    }
                  >
                    <div
                      className="_3daf1fd212"
                      aria-checked="false"
                      aria-disabled="false"
                      data-popcheckparent={'popcheck' + productData?.id}
                      role="checkbox"
                      tabIndex="0"
                      key={uuidv4()}
                    >
                      <div
                        data-checked="false"
                        data-disabled="false"
                        className="_416bffa7a8"
                        data-popcheck={'popcheck' + productData?.id}
                        data-limid={thisRef}
                        key={uuidv4()}
                      >
                        <div
                          data-testid="unchecked_checkbox"
                          className="_cd87b6a0b5"
                          data-name="unchecked"
                        ></div>
                      </div>

                      {productData?.price?.amount > 0 && (
                        <div
                          className="_238110507a"
                          data-checked="false"
                          data-disabled="false"
                          data-popcheckchild={'popcheck' + productData?.id}
                          data-shift="true"
                          data-clickable="true"
                          key={uuidv4()}
                        >
                          {productData?.name}
                          <span
                            className="_cbf9b207b0"
                            data-checked="false"
                            data-alwayson="true"
                          >
                            {' '}
                            +{productData?.price.currency}
                            {(productData?.price.amount / 100).toFixed(2)}
                          </span>
                        </div>
                      )}

                      {productData?.price?.amount === 0 && (
                        <div
                          className="_238110507a"
                          data-checked="false"
                          data-disabled="false"
                          data-popcheckchild={'popcheck' + productData?.id}
                          data-shift="true"
                          data-clickable="true"
                          key={uuidv4()}
                        >
                          {productData?.name}
                          <span
                            className="_cbf9b207b0"
                            data-checked="false"
                            data-alwayson="true"
                          ></span>
                        </div>
                      )}

                      {productData?.next && (
                        <div
                          className="_238884994a"
                          data-checked="false"
                          data-disabled="false"
                          key={uuidv4()}
                        >
                          <svg
                            width="12"
                            height="12"
                            xmlns="http://www.w3.org/2000/svg"
                            enableBackground="new 0 0 12 12"
                          >
                            <g>
                              <path d="m2.59,10.07c-0.07,0 -0.14,-0.02 -0.19,-0.05c-0.1,-0.07 -0.1,-0.17 0,-0.24l5.9,-3.75l-5.9,-3.75c-0.1,-0.07 -0.1,-0.18 0,-0.25s0.27,-0.07 0.38,0l6.09,3.87c0.1,0.07 0.1,0.17 0,0.24l-6.09,3.87c-0.05,0.03 -0.12,0.05 -0.19,0.05l0,0.01z" />
                            </g>
                          </svg>
                        </div>
                      )}

                      {!productData?.next && (
                        <div
                          className="_238884994a"
                          data-checked="false"
                          data-disabled="false"
                          key={uuidv4()}
                        ></div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const getTotal = () => {
    window['visible'] = false;

    let currency = itemCurrency,
      total = cartSubTotal;
    let quantity = window['quantity'];

    const len = gridData.length;
    if (len === 0) {
      document.getElementById('subtotal').innerHTML =
        currency + '' + ((total * quantity) / 100).toFixed(2);
      return false;
    }
    for (var i = 0; i < len; i++) {
      let thisData = gridData[i]?.data;
      const thislen = thisData?.length;
      for (var j = 0; j < thislen; j++) {
        let id = thisData[j].id;
        let amount = thisData[j].price.amount;
        let target = document.body.querySelectorAll(
          'div[data-popcheck="popcheck' + id + '"]'
        );
        let isTargetCheck = target[0].getAttribute('data-checked');
        //let closeAddon = target[0].closest("._b92fb3c088").getAttribute('data-addon');
        let closeAria = target[0]
          .closest('._b92fb3c088')
          .getAttribute('aria-checked');
        if (isTargetCheck === 'true' && closeAria === 'true') {
          total += amount;
        } else if (isTargetCheck === 'true' && closeAria === 'false') {
          //console.log('clicked '+target[0].getAttribute('data-popcheck'));
          target[0].closest('._b4e34f9f22').click();
        }
        if (i === len - 1 && j === thislen - 1) {
          document.getElementById('subtotal').innerHTML =
            currency + '' + ((total * quantity) / 100).toFixed(2);
        }
      }
    }
  };

  const updateQuantity = (action) => {
    let quantity = parseFloat(window['quantity']);
    if (action === '+') {
      quantity = quantity + 1;
    } else if (action === '-') {
      quantity = quantity === 1 ? quantity : quantity - 1;
    }
    window['quantity'] = quantity;
    document.getElementById('itemQuantity').innerHTML = quantity;
    getTotal();
  };

  const getLimit = (min, max) => {
    if (!min < 0) {
      return;
    }
    if (min === 0 && max > 0) {
      return 'Optional, Select up to ' + max;
    } else if (min === 1 && min === max) {
      return 'Required, choose one';
    } else if (min === 1 && max > min) {
      return 'Required, at least one';
    } else if (max > min) {
      return 'Required, at least ' + min;
    } else if (min > 0 && min === max) {
      return 'Required';
    } else {
      return 'Optional';
    }
  };

  const LoadingData = () => {
    return (
      <Drawer
        title={'Please wait'}
        open={true}
        closable={false}
        maskClosable={true}
        footer={[
          <Button
            key="cancel"
            className="c__button"
            onClick={() => setOpenOrderModal(false)}
          >
            Cancel
          </Button>,
        ]}
        placement="right"
        onClose={() => setOpenOrderModal(false)}
        size={`large`}
      >
        <div
          key="m5"
          style={{ textAlign: 'center', height: 130, marginTop: 100 }}
        >
          <Spin size="large"></Spin>
        </div>
      </Drawer>
    );
  };

  const CheckOption = async (
    amount,
    minlimit,
    maxlimit,
    id,
    productId,
    sectionId,
    ref,
    next = false
  ) => {
    let allcheckbox = document.body.querySelectorAll(
      'div[data-productparent="productparent' + id + '"]'
    );
    let target = document.body.querySelectorAll(
      'div[data-popcheck="popcheck' + id + '"]'
    );
    let isTargetCheck = target[0].getAttribute('data-checked');

    //const minLimit = window['minlimit'+sectionId];
    const maxLimit = window['maxlimit' + sectionId];
    const sectLimid = document.body.querySelectorAll(
      'div[data-limid="' + ref + '"]'
    );

    let limitChecked = 0;
    for (let i = 0; i < sectLimid.length; i++) {
      if (sectLimid[i].getAttribute('data-checked') === 'true') {
        limitChecked++;
      }
    }

    if (isTargetCheck === 'false') {
      limitChecked = limitChecked + 1;

      if (limitChecked <= maxLimit) {
        target[0].innerHTML =
          '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="popcheck"><rect width="16" height="16" rx="2" fill="#201E1E"></rect><path d="M6.47013 9.18555L11.6091 4L13 5.40357L7.86108 10.5891L7.86832 10.5964L6.47737 12L3 8.49107L4.39095 7.0875L6.47013 9.18555Z" fill="white"></path></svg>';
        target[0].setAttribute('data-checked', 'true');
        target[0].nextSibling.setAttribute('data-checked', 'true');
        target[0].nextSibling.nextSibling.setAttribute('data-checked', 'true');
        target[0].parentNode.setAttribute('aria-checked', 'true');
        if (next) {
          for (let i = 0; i < allcheckbox.length; i++) {
            if (allcheckbox[i].getAttribute('aria-checked') === 'false') {
              allcheckbox[i].setAttribute('aria-checked', 'true');
              allcheckbox[i].setAttribute('aria-hidden', 'false');
            }
          }
        }
      }
      if (limitChecked === maxLimit) {
        for (let i = 0; i < sectLimid.length; i++) {
          if (
            sectLimid[i] !== target[0] &&
            sectLimid[i].getAttribute('data-checked') === 'false'
          ) {
            sectLimid[i].setAttribute('data-disabled', 'true');
            sectLimid[i].nextSibling.setAttribute('data-disabled', 'true');
            sectLimid[i].nextSibling.nextSibling.setAttribute(
              'data-disabled',
              'true'
            );
            sectLimid[i].parentNode.setAttribute('aria-disabled', 'true');
          }
        }
      }
    } else {
      target[0].innerHTML =
        '<div data-testid="unchecked_checkbox" class="_cd87b6a0b5" data-name="unchecked"></div>';
      target[0].setAttribute('data-checked', 'false');
      target[0].nextSibling.setAttribute('data-checked', 'false');
      target[0].nextSibling.nextSibling.setAttribute('data-checked', 'false');
      target[0].parentNode.setAttribute('aria-checked', 'false');
      if (next) {
        for (let i = 0; i < allcheckbox.length; i++) {
          if (allcheckbox[i].getAttribute('aria-checked') === 'true') {
            allcheckbox[i].setAttribute('aria-checked', 'false');
            allcheckbox[i].setAttribute('aria-hidden', 'true'); //console.log(allcheckbox[i].getElementsByTagName('div')[5].lastChild.firstChild.getAttribute('data-checked'));			//allcheckbox[i].closest("._b92fb3c088").setAttribute('aria-checked', 'false');
          }
        }
      }
      for (let i = 0; i < sectLimid.length; i++) {
        if (sectLimid[i].getAttribute('aria-disabled') === 'true') {
          sectLimid[i].setAttribute('aria-disabled', 'false');
        }
      }
      if (limitChecked === maxLimit) {
        for (let i = 0; i < sectLimid.length; i++) {
          sectLimid[i].setAttribute('data-disabled', 'false');
          sectLimid[i].nextSibling.setAttribute('data-disabled', 'false');
          sectLimid[i].nextSibling.nextSibling.setAttribute(
            'data-disabled',
            'false'
          );
          sectLimid[i].parentNode.setAttribute('aria-disabled', 'false');
        }
      }
    }
    getTotal();
  };

  const LoadEditData = ({ cartId }) => {
    let prevOrders = orderCart['orderPlaced'];
    let cartData = {};
    let cartNote = '';
    let productChecked = [];
    let productDisabled = [];
    let productUndisabled = [];
    let productAddons = [];
    //let quantity = window['quantity'];
    //let currency = itemCurrency, total = cartSubTotal;
    //let targetIndex = 0;

    for (var i = 0; i < prevOrders.length; i++) {
      if (cartId === prevOrders[i].product.cartId) {
        //targetIndex = i;
        cartData.id = prevOrders[i].product.id;
        cartData.name = prevOrders[i].product.name;
        window['headerName'] = cartData.name;
        cartData.quantity = prevOrders[i].product.quantity;
        cartData.cartId = cartId;
        cartData.currency = prevOrders[i].product.currency;
        cartData.image = prevOrders[i].product.image;
        cartData.description = prevOrders[i].product.description;
        cartNote = prevOrders[i].productNote;
        productChecked = prevOrders[i].productChecked;
        productDisabled = prevOrders[i].productDisabled;
        productUndisabled = prevOrders[i].productUndisabled;
        productAddons = prevOrders[i].productAddons;
      }
    }

    return (
      <>
        <div className="_a30bb3afbc" tabIndex="0" ref={cartDivRef}>
          {cartData?.image && !cartData.image.includes('_unavailable') && (
            <div className="_12e5920ed3" key={uuidv4()}>
              <picture>
                <source
                  key={uuidv4()}
                  media="(min-width: 1100px)"
                  srcSet={`${cartData?.image}?fit=crop&amp;w=4000&amp;h=2500 2000w,${cartData?.image}?fit=crop&amp;w=3000&amp;h=1874 1500w`}
                  alt={cartData?.name}
                  className="_794b886035"
                />
                <source
                  key={uuidv4()}
                  media="(min-width: 480px)"
                  srcSet={`${cartData?.image}?fit=crop&amp;w=2000&amp;h=1250 1000w,${cartData?.image}?fit=crop&amp;w=1000&amp;h=624 500w`}
                  alt={cartData?.name}
                  className="_794b886035"
                />
                <img
                  key={uuidv4()}
                  src={`${cartData?.image}?fit=crop&amp;w=1000&amp;h=624 500w`}
                  alt={cartData?.name}
                  className="_794b886035"
                />
              </picture>
            </div>
          )}

          <div className="_7e87360585" key={uuidv4()}>
            {cartData?.description ? cartData?.description : ''}
          </div>

          <div className="_4118145a06" key={uuidv4()}>
            {gridData.map((addonData, index) => (
              <AddonDataEdit
                addonData={addonData}
                index={index}
                productAddons={productAddons}
                productChecked={productChecked}
                productUndisabled={productUndisabled}
                productDisabled={productDisabled}
                key={uuidv4()}
              />
            ))}
          </div>

          <div className="_7947f1af14" key={uuidv4()}>
            <div className="_2e1869a6f2" key={uuidv4()}>
              <div className="_5d92860664">Notes to store</div>
            </div>

            <div className="_2fe64c4d36" key={uuidv4()}>
              <label className="_63c33e3a1b" htmlFor="specialRequest">
                <textarea
                  placeholder="Have an allergy, dietary restriction, or special request?"
                  maxLength="240"
                  name="specialRequest"
                  className="_da39275192 _49a7b28354"
                  defaultValue={cartNote}
                ></textarea>
              </label>
            </div>

            <Space className="alertbutton _2fe64c4d36" direction="vertical">
              {window['visible'] && (
                <Alert message={msgHead} description={msgBody} type={msgType} />
              )}
            </Space>
          </div>
        </div>
      </>
    );
  };

  const AddonDataEdit = ({
    addonData,
    index,
    productAddons,
    productChecked,
    productDisabled,
    productUndisabled,
  }) => {
    window['minlimit' + addonData.id] = addonData.minlimit;
    window['maxlimit' + addonData.id] = addonData.maxlimit;
    const thisRef = uuidv4();
    let idArr = [];
    let idDisableArr = [];
    let idUnDisableArr = [];

    for (let i = 0; i < productChecked.length; i++) {
      idArr.push(productChecked[i].id);
    }

    for (let i = 0; i < productDisabled.length; i++) {
      idDisableArr.push(productDisabled[i].id);
    }

    for (let i = 0; i < productUndisabled.length; i++) {
      idUnDisableArr.push(productUndisabled[i].id);
    }

    if (index >= 0) {
      return (
        <div
          className="_b92fb3c088"
          aria-checked={
            productAddons.includes('addons' + addonData?.id) ? 'true' : 'false'
          }
          data-addon={'addons' + addonData?.id}
          data-productparent={'productparent' + addonData?.productId}
          aria-hidden={
            productAddons.includes('addons' + addonData?.id) ? 'false' : 'true'
          }
          key={index + 'addons'}
        >
          <div key={uuidv4()}>
            {/*addonData.minlimit === 1 && (<div className="_2e1869a6f2" key={uuidv4()}>
						<div className="_7651043074" key={uuidv4()}>{addonData.info}</div>
						<div className="_ed0ebb9231" key={uuidv4()}>Select only one</div>
					</div>)*/}

            {addonData.minlimit >= 0 && (
              <div className="_2e1869a6f2" key={uuidv4()}>
                <div className="_7651043074" key={uuidv4()}>
                  {addonData.info}
                </div>
                <div className="_ed0ebb9231" key={uuidv4()}>
                  {getLimit(addonData.minlimit, addonData.maxlimit)}
                </div>
              </div>
            )}

            <div className="_d44445365a">
              {/*addonData.minlimit === 1 && addonData.data.map((productData, index) => (
							<div 
								key={uuidv4()}
								onClick={()=> RadioOption(productData?.price.amount, productData?.minlimit, productData?.id, productData?.productId, addonData?.id, productData?.next)}
							>
								<div className="_51fd6a7116" role="radio" tabIndex="0" aria-checked={index === 0 ? "true" : "false"} data-popradioparent={'popradio'+productData?.id} >
								
									<div className="_8b6e596e6a" data-popradio={'popradio'+productData?.id} data-popradioproductid={'popradio'+productData?.productId} data-name="radio" data-checked={index === 0 ? "true" : "false"} data-disabled="false" key={uuidv4()}>
										{index === 0 && (<div data-testid="radio_button_dot" className="_18ac26ac61" data-name="dot" data-checked="true" data-disabled="false"></div>)}
									</div>
									{productData?.price?.amount > 0 && (<div className="_238110507a" data-checked={index === 0 ? "true" : "false"} data-disabled="false" data-shift="true" data-clickable="true" data-popradiochild={'popradio'+productData?.id} key={uuidv4()}>
										{productData?.name}
										<span className="_cbf9b207b0" data-checked={index === 0 ? "true" : "false"} data-alwayson="true">{`+${productData?.price?.currency}${(productData?.price?.amount / 100).toFixed(2)}`}</span>
									</div>)}
									
									{productData?.price?.amount === 0 && (<div className="_238110507a" data-checked={index === 0 ? "true" : "false"} data-disabled="false" data-shift="true" data-clickable="true" data-popradiochild={'popradio'+productData?.id} key={uuidv4()}>
										{productData?.name}
										<span className="_cbf9b207b0" data-checked={index === 0 ? "true" : "false"} data-alwayson="true"></span>
									</div>)}
									
									
								</div>
							</div>
						))*/}

              {addonData.minlimit >= 0 &&
                addonData.data.map((productData, index) => (
                  <div
                    className="_b4e34f9f22"
                    key={uuidv4()}
                    onClick={() =>
                      CheckOption(
                        productData?.price.amount,
                        addonData.minlimit,
                        addonData.maxlimit,
                        productData?.id,
                        productData?.productId,
                        addonData?.id,
                        thisRef,
                        productData?.next
                      )
                    }
                  >
                    {idArr.includes(productData?.id) && (
                      <div
                        className="_3daf1fd212"
                        aria-checked="true"
                        aria-disabled="false"
                        data-popcheckparent={'popcheck' + productData?.id}
                        role="checkbox"
                        tabIndex="0"
                        key={uuidv4()}
                      >
                        <div
                          data-checked="true"
                          data-disabled="false"
                          className="_416bffa7a8"
                          data-popcheck={'popcheck' + productData?.id}
                          data-limid={thisRef}
                          key={uuidv4()}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="popcheck"
                          >
                            <rect
                              width="16"
                              height="16"
                              rx="2"
                              fill="#201E1E"
                            ></rect>
                            <path
                              d="M6.47013 9.18555L11.6091 4L13 5.40357L7.86108 10.5891L7.86832 10.5964L6.47737 12L3 8.49107L4.39095 7.0875L6.47013 9.18555Z"
                              fill="white"
                            ></path>
                          </svg>
                        </div>

                        {productData?.price?.amount > 0 && (
                          <div
                            className="_238110507a"
                            data-checked="true"
                            data-disabled="false"
                            data-popcheckchild={'popcheck' + productData?.id}
                            data-shift="true"
                            data-clickable="true"
                            key={uuidv4()}
                          >
                            {productData?.name}
                            <span
                              className="_cbf9b207b0"
                              data-checked="false"
                              data-alwayson="true"
                            >
                              {' '}
                              +{productData?.price.currency}
                              {(productData?.price.amount / 100).toFixed(2)}
                            </span>
                          </div>
                        )}

                        {productData?.price?.amount === 0 && (
                          <div
                            className="_238110507a"
                            data-checked="true"
                            data-disabled="false"
                            data-popcheckchild={'popcheck' + productData?.id}
                            data-shift="true"
                            data-clickable="true"
                            key={uuidv4()}
                          >
                            {productData?.name}
                            <span
                              className="_cbf9b207b0"
                              data-checked="false"
                              data-alwayson="true"
                            ></span>
                          </div>
                        )}

                        {productData?.next && (
                          <div
                            className="_238884994a"
                            data-checked="true"
                            data-disabled="false"
                            key={uuidv4()}
                          >
                            <svg
                              width="12"
                              height="12"
                              xmlns="http://www.w3.org/2000/svg"
                              enableBackground="new 0 0 12 12"
                            >
                              <g>
                                <path d="m2.59,10.07c-0.07,0 -0.14,-0.02 -0.19,-0.05c-0.1,-0.07 -0.1,-0.17 0,-0.24l5.9,-3.75l-5.9,-3.75c-0.1,-0.07 -0.1,-0.18 0,-0.25s0.27,-0.07 0.38,0l6.09,3.87c0.1,0.07 0.1,0.17 0,0.24l-6.09,3.87c-0.05,0.03 -0.12,0.05 -0.19,0.05l0,0.01z" />
                              </g>
                            </svg>
                          </div>
                        )}

                        {!productData?.next && (
                          <div
                            className="_238884994a"
                            data-checked="false"
                            data-disabled="false"
                            key={uuidv4()}
                          ></div>
                        )}
                      </div>
                    )}

                    {idDisableArr.includes(productData?.id) && (
                      <div
                        className="_3daf1fd212"
                        aria-checked="false"
                        aria-disabled="true"
                        data-popcheckparent={'popcheck' + productData?.id}
                        role="checkbox"
                        tabIndex="0"
                        key={uuidv4()}
                      >
                        <div
                          data-checked="false"
                          data-disabled="true"
                          className="_416bffa7a8"
                          data-popcheck={'popcheck' + productData?.id}
                          data-limid={thisRef}
                          key={uuidv4()}
                        >
                          <div
                            data-testid="unchecked_checkbox"
                            className="_cd87b6a0b5"
                            data-name="unchecked"
                          ></div>
                        </div>

                        {productData?.price?.amount > 0 && (
                          <div
                            className="_238110507a"
                            data-checked="false"
                            data-disabled="true"
                            data-popcheckchild={'popcheck' + productData?.id}
                            data-shift="true"
                            data-clickable="true"
                            key={uuidv4()}
                          >
                            {productData?.name}
                            <span
                              className="_cbf9b207b0"
                              data-checked="false"
                              data-alwayson="true"
                            >
                              {' '}
                              +{productData?.price.currency}
                              {(productData?.price.amount / 100).toFixed(2)}
                            </span>
                          </div>
                        )}

                        {productData?.price?.amount === 0 && (
                          <div
                            className="_238110507a"
                            data-checked="false"
                            data-disabled="true"
                            data-popcheckchild={'popcheck' + productData?.id}
                            data-shift="true"
                            data-clickable="true"
                            key={uuidv4()}
                          >
                            {productData?.name}
                            <span
                              className="_cbf9b207b0"
                              data-checked="false"
                              data-alwayson="true"
                            ></span>
                          </div>
                        )}

                        {productData?.next && (
                          <div
                            className="_238884994a"
                            data-checked="false"
                            data-disabled="true"
                            key={uuidv4()}
                          >
                            <svg
                              width="12"
                              height="12"
                              xmlns="http://www.w3.org/2000/svg"
                              enableBackground="new 0 0 12 12"
                            >
                              <g>
                                <path d="m2.59,10.07c-0.07,0 -0.14,-0.02 -0.19,-0.05c-0.1,-0.07 -0.1,-0.17 0,-0.24l5.9,-3.75l-5.9,-3.75c-0.1,-0.07 -0.1,-0.18 0,-0.25s0.27,-0.07 0.38,0l6.09,3.87c0.1,0.07 0.1,0.17 0,0.24l-6.09,3.87c-0.05,0.03 -0.12,0.05 -0.19,0.05l0,0.01z" />
                              </g>
                            </svg>
                          </div>
                        )}

                        {!productData?.next && (
                          <div
                            className="_238884994a"
                            data-checked="false"
                            data-disabled="true"
                            key={uuidv4()}
                          ></div>
                        )}
                      </div>
                    )}

                    {idUnDisableArr.includes(productData?.id) &&
                      !idArr.includes(productData?.id) && (
                        <div
                          className="_3daf1fd212"
                          aria-checked="false"
                          aria-disabled="false"
                          data-popcheckparent={'popcheck' + productData?.id}
                          role="checkbox"
                          tabIndex="0"
                          key={uuidv4()}
                        >
                          <div
                            data-checked="false"
                            data-disabled="false"
                            className="_416bffa7a8"
                            data-popcheck={'popcheck' + productData?.id}
                            data-limid={thisRef}
                            key={uuidv4()}
                          >
                            <div
                              data-testid="unchecked_checkbox"
                              className="_cd87b6a0b5"
                              data-name="unchecked"
                            ></div>
                          </div>

                          {productData?.price?.amount > 0 && (
                            <div
                              className="_238110507a"
                              data-checked="false"
                              data-disabled="false"
                              data-popcheckchild={'popcheck' + productData?.id}
                              data-shift="true"
                              data-clickable="true"
                              key={uuidv4()}
                            >
                              {productData?.name}
                              <span
                                className="_cbf9b207b0"
                                data-checked="false"
                                data-alwayson="true"
                              >
                                {' '}
                                +{productData?.price.currency}
                                {(productData?.price.amount / 100).toFixed(2)}
                              </span>
                            </div>
                          )}

                          {productData?.price?.amount === 0 && (
                            <div
                              className="_238110507a"
                              data-checked="false"
                              data-disabled="false"
                              data-popcheckchild={'popcheck' + productData?.id}
                              data-shift="true"
                              data-clickable="true"
                              key={uuidv4()}
                            >
                              {productData?.name}
                              <span
                                className="_cbf9b207b0"
                                data-checked="false"
                                data-alwayson="true"
                              ></span>
                            </div>
                          )}

                          {productData?.next && (
                            <div
                              className="_238884994a"
                              data-checked="false"
                              data-disabled="false"
                              key={uuidv4()}
                            >
                              <svg
                                width="12"
                                height="12"
                                xmlns="http://www.w3.org/2000/svg"
                                enableBackground="new 0 0 12 12"
                              >
                                <g>
                                  <path d="m2.59,10.07c-0.07,0 -0.14,-0.02 -0.19,-0.05c-0.1,-0.07 -0.1,-0.17 0,-0.24l5.9,-3.75l-5.9,-3.75c-0.1,-0.07 -0.1,-0.18 0,-0.25s0.27,-0.07 0.38,0l6.09,3.87c0.1,0.07 0.1,0.17 0,0.24l-6.09,3.87c-0.05,0.03 -0.12,0.05 -0.19,0.05l0,0.01z" />
                                </g>
                              </svg>
                            </div>
                          )}

                          {!productData?.next && (
                            <div
                              className="_238884994a"
                              data-checked="false"
                              data-disabled="false"
                              key={uuidv4()}
                            ></div>
                          )}
                        </div>
                      )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const addToCart = async () => {
    try {
      window['visible'] = false;
      let selectProduct = {};
      let ProductChecked = [];
      let ProductDisabled = [];
      let ProductUndisabled = [];
      let ProductAddons = [];
      let quantity = window['quantity'];
      let currency = itemCurrency,
        total = cartSubTotal;
      selectProduct.product = {};

      let priorCart = orderCart['orderPlaced'];
      let newcart = false;

      if (
        priorCart.length > 0 &&
        orderCart?.orderPlaced[0]?.product?.storeId !== refId
      ) {
        newcart = true;
      }

      selectProduct.product.id = cartData.id;
      selectProduct.product.storeId = refId;
      selectProduct.product.storeName = shopStoreName;
      selectProduct.product.storeZip = shopStoreZip;
      selectProduct.product.storeCity = shopStoreCity;
      selectProduct.product.storeState = shopStoreState;
      selectProduct.product.hqId = hqId;
      selectProduct.product.name = cartData.name;
      selectProduct.product.currency = cartData.currency;
      selectProduct.product.cartId = uuidv4();
      selectProduct.product.quantity = quantity;
      selectProduct.product.image = cartData.image;
      selectProduct.product.description = cartData.description;
      selectProduct.productNote = document.getElementsByName(
        'specialRequest'
      )[0].value
        ? document.getElementsByName('specialRequest')[0].value
        : '';

      const len = gridData.length;
      if (len === 0) {
        selectProduct.product.price = total * quantity;
        selectProduct.product.displayprice =
          currency + '' + ((total * quantity) / 100).toFixed(2);
      }

      for (var i = 0; i < len; i++) {
        let refData = gridData[i];
        let thisData = refData.data;
        let targetNode = document.body.querySelectorAll(
          'div[data-addon="addons' + refData.id + '"]'
        );
        let hit = false;
        let minSet = 0;
        if (targetNode[0].getAttribute('aria-checked') === 'true') {
          hit = true;
          minSet = parseFloat(window['minlimit' + refData.id]);
        }
        let checkSet = 0;
        const thislen = thisData.length;
        for (var j = 0; j < thislen; j++) {
          let id = thisData[j].id;
          let name = thisData[j].name;
          let amount = thisData[j].price.amount;
          let currency = thisData[j].price.currency;
          let target = document.body.querySelectorAll(
            'div[data-popcheck="popcheck' + id + '"]'
          );

          let isTargetCheck = target[0].getAttribute('data-checked');
          let closeAria = target[0]
            .closest('._b92fb3c088')
            .getAttribute('aria-checked');
          let isTargetDisable = target[0].getAttribute('data-disabled');
          if (isTargetCheck === 'true' && closeAria === 'true') {
            total += amount;
            checkSet++;
            let nEntry = {};
            nEntry.id = id;
            nEntry.name = name;
            ProductChecked.push(nEntry);
            let dataAddon = target[0]
              .closest('._b92fb3c088')
              .getAttribute('data-addon');
            if (!ProductAddons.includes(dataAddon)) {
              ProductAddons.push(dataAddon);
            }
          } else if (closeAria === 'true') {
            let dataAddon = target[0]
              .closest('._b92fb3c088')
              .getAttribute('data-addon');
            if (!ProductAddons.includes(dataAddon)) {
              ProductAddons.push(dataAddon);
            }
          }
          if (isTargetDisable === 'true') {
            let nEntry = {};
            nEntry.id = id;
            nEntry.name = name;
            ProductDisabled.push(nEntry);
          } else {
            let nEntry = {};
            nEntry.id = id;
            nEntry.name = name;
            ProductUndisabled.push(nEntry);
          }
          if (i === len - 1 && j === thislen - 1) {
            selectProduct.product.price = total * quantity;
            selectProduct.product.displayprice =
              currency + '' + ((total * quantity) / 100).toFixed(2);
          }
        }

        if (hit) {
          if (checkSet < minSet) {
            window['visible'] = true;
            setMsgHead('');
            setMsgBody('Please check the required options');
            setMsgType('error');
            scrollToBottom();
            message.error(`Please check required options`);
            return false;
          }
        }
      }

      selectProduct.productChecked = ProductChecked;
      selectProduct.productDisabled = ProductDisabled;
      selectProduct.productUndisabled = ProductUndisabled;
      selectProduct.productAddons = ProductAddons;

      let fulfillmentType = orderCart['fulfillmentType'];
      let orderPlaced = orderCart['orderPlaced'];

      const sState = await utils.convertAddress(shopStoreState);
      const sCity = await utils.convertAddress(shopStoreCity);
      const sZip = await utils.convertAddress(shopStoreZip);
      const sName = await utils.convertAddress(shopStoreName);
      const sSid = await utils.convertAddress(refId);
      const checkOutLink = `/${sState}/${sCity}/${sZip}/${sName}/${sSid}`;

      //console.log(shopStoreName, shopStoreCity, shopStoreZip, shopStoreState);
      //tx/frisco/75033/ihop/swnt14png

      if (orderPlaced.length > 0 && !newcart) {
        orderPlaced = [...orderPlaced, selectProduct];
      } else {
        orderPlaced = [selectProduct];
        //console.log({hours: shopHours, storeId: refId, hqId: hqId, storeName: shopStoreName, storeCity: shopStoreCity, storeState: shopStoreState, storeZip: shopStoreZip, checkOutLink: checkOutLink});
        const newDeliveryData = {
          ...deliveryInfo,
          hours: shopHours,
          storeId: refId,
          hqId: hqId,
          storeName: shopStoreName,
          storeCity: shopStoreCity,
          storeState: shopStoreState,
          storeZip: shopStoreZip,
          checkOutLink: checkOutLink,
        };
        await updateDeliveryData(newDeliveryData);
      }

      const orderToCart = {
        fulfillmentType: fulfillmentType,
        orderPlaced: orderPlaced,
      };

      //console.log(orderToCart);

      if (newcart) {
        const newDeliveryData2 = {
          ...deliveryInfo,
          checkOutLink: checkOutLink,
        };
        await updateDeliveryData(newDeliveryData2);
        setCheckOutError(`We've added new item to your cart.`);
        window['visible'] = true;
        setMsgHead('');
        setMsgBody(`Previous cart items have been emptied.`);
        setMsgType('info');
        scrollToBottom();
        message.info(`Previous cart items have been emptied.`);
      } else {
        setCheckOutError(``);
      }
      updateCartData(orderToCart);
      closeOrderModal();
    } catch (err) {
      message.error(err.message + ' OK');
    }
  };

  const updateCart = async (cartId) => {
    try {
      window['visible'] = false;
      let prevOrders = orderCart['orderPlaced'];
      let selectProduct = {};
      let ProductChecked = [];
      let ProductDisabled = [];
      let ProductUndisabled = [];
      let ProductAddons = [];
      let quantity = window['quantity'];
      let currency = itemCurrency,
        total = cartSubTotal;
      //let targetIndex = 0;
      selectProduct.product = {};

      for (let i = 0; i < prevOrders.length; i++) {
        if (cartId === prevOrders[i].product.cartId) {
          //targetIndex = i;
          selectProduct.product.id = prevOrders[i].product.id;
          selectProduct.product.name = prevOrders[i].product.name;
          selectProduct.product.storeId = prevOrders[i].product.storeId;
          selectProduct.product.storeName = prevOrders[i].product.storeName;
          selectProduct.product.storeZip = prevOrders[i].product.storeZip;
          selectProduct.product.storeCity = prevOrders[i].product.storeCity;
          selectProduct.product.storeState = prevOrders[i].product.storeState;
          selectProduct.product.hqId = prevOrders[i].product.hqId;
          selectProduct.product.quantity = quantity;
          selectProduct.product.cartId = cartId;
          selectProduct.product.currency = prevOrders[i].product.currency;
          selectProduct.product.image = prevOrders[i].product.image;
          selectProduct.product.description = prevOrders[i].product.description;
        }
      }

      selectProduct.productNote = document.getElementsByName(
        'specialRequest'
      )[0].value
        ? document.getElementsByName('specialRequest')[0].value
        : '';

      const len = gridData.length;

      if (len === 0) {
        selectProduct.product.price = total * quantity;
        selectProduct.product.displayprice =
          currency + '' + ((total * quantity) / 100).toFixed(2);
      }

      for (let i = 0; i < len; i++) {
        let refData = gridData[i];
        let thisData = refData.data;
        const thislen = thisData.length;
        let targetNode = document.body.querySelectorAll(
          'div[data-addon="addons' + refData.id + '"]'
        );
        let hit = false;
        let minSet = 0;
        if (targetNode[0].getAttribute('aria-checked') === 'true') {
          hit = true;
          minSet = parseFloat(window['minlimit' + refData.id]);
        }
        let checkSet = 0;
        for (var j = 0; j < thislen; j++) {
          let id = thisData[j].id;
          let name = thisData[j].name;
          let amount = thisData[j].price.amount;
          let currency = thisData[j].price.currency;
          let target = document.body.querySelectorAll(
            'div[data-popcheck="popcheck' + id + '"]'
          );
          let isTargetCheck = target[0].getAttribute('data-checked');
          let isTargetDisable = target[0].getAttribute('data-disabled');
          if (isTargetCheck === 'true') {
            total += amount;
            checkSet++;
            let nEntry = {};
            nEntry.id = id;
            nEntry.name = name;
            ProductChecked.push(nEntry);
            let dataAddon = target[0]
              .closest('._b92fb3c088')
              .getAttribute('data-addon');
            if (!ProductAddons.includes(dataAddon)) {
              ProductAddons.push(dataAddon);
            }
          }
          if (isTargetDisable === 'true') {
            let nEntry = {};
            nEntry.id = id;
            nEntry.name = name;
            ProductDisabled.push(nEntry);
          } else {
            let nEntry = {};
            nEntry.id = id;
            nEntry.name = name;
            ProductUndisabled.push(nEntry);
          }
          if (i === len - 1 && j === thislen - 1) {
            selectProduct.product.price = total * quantity;
            selectProduct.product.displayprice =
              currency + '' + ((total * quantity) / 100).toFixed(2);
          }
        }

        if (hit) {
          if (checkSet < minSet) {
            window['visible'] = true;
            setMsgHead('');
            setMsgBody('Please check the required options');
            setMsgType('error');
            scrollToBottom();
            message.error(`Please check the required options`);
            return false;
          }
        }
      }

      selectProduct.productChecked = ProductChecked;
      selectProduct.productDisabled = ProductDisabled;
      selectProduct.productUndisabled = ProductUndisabled;
      selectProduct.productAddons = ProductAddons;
      let fulfillmentType = orderCart['fulfillmentType'];

      let newOrders = [];
      for (let i = 0; i < prevOrders.length; i++) {
        if (cartId === prevOrders[i].product.cartId) {
          newOrders = [...newOrders, selectProduct];
        } else {
          newOrders = [...newOrders, prevOrders[i]];
        }
      }
      let orderPlaced = newOrders;

      const orderToCart = {
        fulfillmentType: fulfillmentType,
        orderPlaced: orderPlaced,
      };
      updateCartData(orderToCart);
      closeOrderModal();
    } catch (err) {
      message.error(err.message);
    }
  };

  const getHeader = () => {
    if (cartType === 'custom') {
      window['headerName'] = cartData?.name;
    } else {
      let prevOrders = orderCart['orderPlaced'];
      let cart = {};
      for (let i = 0; i < prevOrders.length; i++) {
        if (cartData === prevOrders[i].product.cartId) {
          cart.name = prevOrders[i].product.name;
          window['headerName'] = cart.name;
        }
      }
    }
    return window['headerName'];
  };

  const getButton = () => {
    const size = 'large';
    if (
      (cartType === 'custom' &&
        loading === false &&
        gridData?.length > 0 &&
        storeId) ||
      (cartType === 'custom' &&
        loading === false &&
        gridData?.length === 0 &&
        cartSubTotal > 0 &&
        storeId)
    ) {
      return [
        <div>
          <Button
            key={uuidv4()}
            icon={<MinusOutlined />}
            shape={'circle'}
            size={size}
            type={'dashed'}
            style={{
              marginRight: 4,
              color: '#ffffff',
              backgroundColor: '#201e1e',
              border: '#201e1e',
            }}
            onClick={() => updateQuantity('-')}
          />
          <Button
            key={uuidv4()}
            shape={'circle'}
            size={size}
            type={'text'}
            style={{ marginRight: 4 }}
            id="itemQuantity"
          >
            1
          </Button>
          <Button
            key={uuidv4()}
            icon={<PlusOutlined />}
            shape={'circle'}
            size={size}
            type={'dashed'}
            style={{
              marginRight: 4,
              color: '#ffffff',
              backgroundColor: '#201e1e',
              border: '#201e1e',
            }}
            onClick={() => updateQuantity('+')}
          />
        </div>,
        <Button
          key={uuidv4()}
          className="c__button"
          onClick={() => addToCart()}
        >
          {/*Add to cart - */}
          {`+ `}
          <span id="subtotal">{`$${(cartSubTotal / 100).toFixed(2)}`}</span>
        </Button>,
      ];
    } else if (
      (cartType === 'edit' &&
        loading === false &&
        gridData?.length > 0 &&
        storeId) ||
      (cartType === 'edit' &&
        loading === false &&
        gridData?.length === 0 &&
        cartSubTotal > 0 &&
        storeId)
    ) {
      return [
        <div>
          <Button
            key={uuidv4()}
            icon={<MinusOutlined />}
            shape={'circle'}
            size={size}
            type={'dashed'}
            style={{
              marginRight: 4,
              color: '#ffffff',
              backgroundColor: '#201e1e',
              border: '#201e1e',
            }}
            onClick={() => updateQuantity('-')}
          />
          <Button
            key={uuidv4()}
            shape={'circle'}
            size={size}
            type={'text'}
            style={{ marginRight: 4 }}
            id="itemQuantity"
          >
            {cartQuantity}
          </Button>
          <Button
            key={uuidv4()}
            icon={<PlusOutlined />}
            shape={'circle'}
            size={size}
            type={'dashed'}
            style={{
              marginRight: 4,
              color: '#ffffff',
              backgroundColor: '#201e1e',
              border: '#201e1e',
            }}
            onClick={() => updateQuantity('+')}
          />
        </div>,
        <Button
          key={uuidv4()}
          className="c__button"
          onClick={() => updateCart(cartId)}
        >
          {/*Update cart - */}
          {`+ `}
          <span id="subtotal">{`${cartTotal}`}</span>
        </Button>,
      ];
    } else {
      return [
        <Button
          key={uuidv4()}
          className="c__button"
          onClick={() => closeOrderModal()}
        >
          Close
        </Button>,
      ];
    }
  };

  const closeOrderModal = () => {
    window.document.body.style.overflow = '';
    setOpenOrderModal(false);
    setOpen(false);
  };

  return (
    <>
      <Drawer
        title={getHeader()}
        open={open}
        closable={false}
        className="ordermodal"
        maskClosable={true}
        footer={getButton()}
        extra={[
          <Button
            key="cancel"
            className="c__button"
            onClick={() => closeOrderModal()}
          >
            Close
          </Button>,
        ]}
        placement="right"
        onClose={() => closeOrderModal()}
        size={`large`}
      >
        {cartType === 'custom' &&
          loading === false &&
          gridData?.length > 0 &&
          storeId && <LoadCustomData />}

        {cartType === 'custom' &&
          loading === false &&
          gridData?.length === 0 &&
          cartSubTotal > 0 &&
          storeId && <LoadCustomData />}

        {cartType === 'custom' && loading === false && !storeId && (
          <LoadingData />
        )}

        {cartType === 'custom' &&
          loading === false &&
          gridData?.length < 1 &&
          cartSubTotal < 1 &&
          storeId && (
            <p>{`Product item is not available at this time. Please try again later.`}</p>
          )}

        {cartType === 'edit' &&
          loading === false &&
          gridData?.length > 0 &&
          storeId && <LoadEditData cartId={cartId} />}

        {cartType === 'edit' &&
          loading === false &&
          gridData?.length === 0 &&
          cartSubTotal > 0 &&
          storeId && <LoadEditData cartId={cartId} />}

        {cartType === 'edit' &&
          loading === false &&
          gridData?.length < 1 &&
          cartSubTotal < 1 &&
          storeId && (
            <p>{`Product item is not available at this time. Please try again later.`}</p>
          )}

        {cartType === 'edit' && loading === false && !storeId && (
          <LoadingData />
        )}
      </Drawer>
    </>
  );
};

export default OrderModal;
