import React,{useEffect} from "react";
import { MainNavbar, SubHeading, SubFooter } from '../../components';
import { motion, useScroll, useSpring  } from "framer-motion";
import { useNavigate, useLocation } from 'react-router-dom';	

const NotFoundScreen = ({sendApp, infoDisplay, logPage}) => {
	
	const location = useLocation();
	
	useEffect(() => {
		document.body.style.overflow = "auto";
	}, []);
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, []);
	
	const navigate = useNavigate();
	const { scrollYProgress } = useScroll();
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001
	});
	
	const SwitchRef = (ref) => {
		navigate(ref);
	}
	
	return (
		<>
			<MainNavbar sendApp={sendApp} infoDisplay={infoDisplay}/>
			<motion.div className="progress-bar" style={{ scaleX }} />
			  <div className="app__header app__wrapper section__padding notfound__bg">
				<motion.div
					initial={{ opacity: 0, x: -200 }}
					animate={{ opacity: 1, x: 0 }}
					exit={{ opacity: 0, x: -200 }}
					className="app__wrapper_info"
				>
				  <SubHeading title="" />
				  <h1 className="app__header-h1">Resource not found</h1>
				  <span onClick={()=> SwitchRef('/')}><button type="button" className="custom__button">Home Page</button></span>
				</motion.div>

				<div className="app__wrapper_img" id="apply">
				  
					</div>
			  </div>
			<SubFooter sendApp={sendApp} infoDisplay={infoDisplay}/>
		</>
	)
};

export default NotFoundScreen;
