import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { utils } from "../../utils";
import { MapUpdate, FormInput } from "../../components";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import {
  selectSavedAddresses,
  setSavedAddresses,
} from "../../includes/navSlice";

import {
  Button,
  Input,
  Form,
  Select,
  Divider,
  message,
  Space,
  Radio,
	Drawer
} from "antd";
const { Option } = Select;

const AddressModal = ({
  fulfillmentType,
  setFulfillmentType,
  address,
  setAddress,
  openLogin,
  errandInfo,
  deliveryInfo,
  shopHours,
	updateErrandData,
  updateDeliveryData,
  addressModalOpen,
  setAddressModalOpen,
  shopTimeZone,
  gps,
}) => {
	
const dispatch = useDispatch();
let savedAddresses = useSelector(selectSavedAddresses);
savedAddresses =
typeof savedAddresses === "object"
? savedAddresses
: JSON.parse(savedAddresses);

const [open, setOpen] = useState(addressModalOpen);
const [schedule, setSchedule] = useState(false);
const [loading, setLoading] = useState(false);
const [change, setChange] = useState(false);
const [adjust, setAdjust] = useState(false);
const [next, setNext] = useState(false);
const [houseNo, setHouseNo] = useState("");
const [locationAddress, setLocationAddress] = useState("");
const [city, setCity] = useState("");
const [state, setState] = useState("");
const [toggleApt, setToggleApt] = useState(false);
const [lat, setLat] = useState(0);
const [lng, setLng] = useState(0);
const [load, setLoad] = useState(false);
const [marker, setMarker] = useState(null);
const [day, setDay] = useState("");
const [time, setTime] = useState("");
const [params, setParams] = useState({});
const [mapData, setMapData] = useState([]);
const [fee, setFee] = useState(0);
const [aptNo, setAptNo] = useState("");
const [accessCode, setAccessCode] = useState("");
const [delTime, setDelTime] = useState("");
const [delTime2, setDelTime2] = useState("");
const [asapTime, setAsapTime] = useState("");
const authToken = utils.getStorage("__el_data_login_token");
const ellogin = utils.getStorage("__el_data_login_status");

const [timeData, setTimeData] = useState([
	{ label: "Select Time", value: "", disable: false },
]);

const [dayData, setDayData] = useState([
	{ label: "Select Day", value: "", disable: false },
]);

const [addresses, setAddresses] = useState(savedAddresses || []);
//console.log('85', savedAddresses)
const [defaultAddress, setDefaultAddress] = useState(
	addresses?.find((address) => address?.addressMain)?.id || ""
);

useEffect(() => {
	const now = moment.tz(shopTimeZone);
	const initDayOfWeek = now.format("ddd");
	const daysInterval = [{ label: "Select Day", value: "", disable: false }];
	const mapping = [];
	
	for (let i = 0; i < 7; i++) {
		const tday = now.clone().add(i, "days").format("MM/DD/YYYY");
		const dayOfWeek = now.clone().add(i, "days").format("ddd");
		
		Object.entries(shopHours).forEach((entry) => {
			const [key, val] = entry;
			const thisday =
			key === "thur" ? "Thu" : utils.capitalizeFirstLetter(key);
			if (dayOfWeek === thisday) {
				const timeIntervals = [
					{ label: "Select Time", value: "", disable: false },
				];
				const tOpen = val["open"];
				const tClose = val["close"];
				if(tOpen !== 'CLOSED'){
					
					let tOpenTime = tOpen.toString();
					tOpenTime = tOpenTime.slice(0, -2) + ":" + tOpenTime.slice(-2);
					let tCloseTime = tClose.toString();
					tCloseTime = tCloseTime.slice(0, -2) + ":" + tCloseTime.slice(-2);
					
					let openTime = moment.tz(
						tday + " " + tOpenTime,
						"MM/DD/YYYY hh:mm",
						shopTimeZone
					);
					let closeTime = moment.tz(
						tday + " " + tCloseTime,
						"MM/DD/YYYY hh:mm",
						shopTimeZone
					);
					if (closeTime.clone().isBefore(openTime)) {
						const closeDay = now.clone().add(1, "days").format("MM/DD/YYYY");
						closeTime = moment.tz(
							closeDay + " " + tCloseTime,
							"MM/DD/YYYY hh:mm",
							shopTimeZone
						);
					}
					openTime =
					now.clone().isAfter(openTime) && initDayOfWeek === dayOfWeek
					? now
					: openTime;
					
					let currentTime = openTime.clone();
					while (currentTime.isSameOrBefore(closeTime)) {
						// Add the current time to the options array
						let dTime = `${currentTime.format("hh:mm A")}`;
						dTime = utils.convertToNearest15Minutes(dTime);
						
						const tentry = {
							label: dTime,
							value: dTime,
							disable: false,
						};
						
						timeIntervals.push(tentry);
						
						// Add 15 minutes to the current time
						currentTime = currentTime.clone().add(15, "minutes");
					}
					
					if (timeIntervals.length > 1) {
						const mapEntry = { day: thisday, times: timeIntervals };
						const entry = {
							label:
							initDayOfWeek === thisday ? "Today" : `${thisday}, ${tday}`,
							value: `${thisday}`,
							disable: false,
						};
						daysInterval.push(entry);
						mapping.push(mapEntry);
					}
				}
			}
		});
	}
	setMapData(mapping);
	setDayData(daysInterval);
	
	let seen = false;
	let last = [{ label: "Select Time", value: "", disable: false }];
	Object.entries(mapping).forEach((entry) => {
		const [key, val] = entry;
		last = val["times"];
		if (val["day"] === initDayOfWeek) {
			setTimeData(val["times"]);
			seen = true;
      } else if (key) {
		}
	});
	
	if (!seen) {
		setTimeData(last);
	}
}, [open, fulfillmentType]);

useEffect(() => {
	
	const initial = async() => {		
		const getAddr = await utils.getLocalCookie("elAddr");		
		//handleLocationAddress(deliveryInfo?.address ?? "");
		await parseAddress(deliveryInfo?.address, 'houseNo');
		await parseAddress(deliveryInfo?.address, 'streetName');
		setCity(deliveryInfo?.city ?? "");
		setState(deliveryInfo?.state ?? "");
		setAptNo(deliveryInfo?.apartment ?? "");
		setAccessCode(deliveryInfo?.accessCode ?? "");
		setDelTime(deliveryInfo?.day);
		setDelTime2(deliveryInfo?.time);
		setAsapTime(deliveryInfo?.time);
		if (deliveryInfo?.latitude || getAddr?.latitude) {			
			const mph = await utils.getDriveData('mph');
			const perHrEarnings = await utils.getDriveData('perHrEarnings');		
			const meterPerMile = await utils.getDriveData('meterPerMile');
			const start = [gps?.latitude, gps?.longitude];
			const end = [
				deliveryInfo?.latitude || getAddr?.latitude,
				deliveryInfo?.longitude || getAddr?.longitude,
			];
			const distanceInMeters = utils.gis.calculateDistance(start, end);
			const miles = distanceInMeters / meterPerMile;
			const deliveryFee = utils.deliveryFee(miles, mph, perHrEarnings);
			setFee(`${deliveryInfo?.currency}${deliveryFee}`);
			
			if (deliveryInfo?.day === "Today") {
				const now = moment.tz(shopTimeZone);
				const tday = now.clone().format("MM/DD/YYYY");
				const time = deliveryInfo?.time;
				const scheduleDate = moment.tz(
					`${tday} ${time}`,
					"MM/DD/YYYY hh:mm A",
					shopTimeZone
				);
				
				if (now.clone().isAfter(scheduleDate)) {
					handleASAP();
				}
			}
		}
	}
	
	initial();
}, [shopTimeZone, deliveryInfo]);

useEffect(() => {
	window.document.body.style.overflow = open ? "hidden" : "";
}, [open]);

useEffect(() => {
	setAdjust(adjust);
}, [adjust]);

useEffect(() => {
	const initial = async() => {
		if (lat !== 0 && marker?.lat) {
			setAdjust(true);			
			const newErrandData = {
				...errandInfo,        
				latitude: marker?.lat,
				longitude: marker?.lng,
			};    
			
			const newDeliveryData = {
				...deliveryInfo,        
				latitude: marker?.lat,
				longitude: marker?.lng,
			};
			await updateErrandData(newErrandData);
			await updateDeliveryData(newDeliveryData);
			
			let selected,
			newEntry = [];
			for (var i = 0; i < addresses.length; i++) {
				if (
					addresses[i].id === defaultAddress &&
					addresses[i]?.address !== "null" &&
					addresses[i]?.address !== null &&
					addresses[i]?.address !== ""
					) {
					selected = {
						...addresses[i],
						addressMain: true,
						latitude: marker?.lat,
						longitude: marker?.lng,
					};
					const getAddr = await utils.getLocalCookie("elAddr");
					
					let cookieData = {
						...getAddr,
						latitude: marker?.lat,
						longitude: marker?.lng,
					}
					await utils.setLocalCookie("elAddr", cookieData);
					newEntry.push(selected);
					} else if (
					addresses[i]?.address !== "null" &&
					addresses[i]?.address !== ""
					) {
					newEntry.push({ ...addresses[i], addressMain: false });
				}
				if (i === addresses.length - 1) {
					dispatch(setSavedAddresses(JSON.stringify(newEntry)));
					//console.log('301', newEntry);
					setAddresses(newEntry);
				}
			}
		}
		
	}
	initial();
}, [marker]);

useEffect(() => {
	if (lat) {
		setLoad(true);
	}
}, [lat]);

const parseAddress = async (address, type) => {
	await utils.parseAddress(address, function(err, addressObj){
		if(type === 'houseNo'){
			setHouseNo(addressObj.house_number ?? '');
			return addressObj.house_number ?? '';
		}else	if(type === 'streetName'){				
			setLocationAddress(addressObj.street_name ?? '');
			return addressObj.street_name ?? '';
		}else{
			return '';
		}
	});	
}

const handleAptNo = async(value) => {
	setAptNo(value);
	
	const newErrandData = {
		...errandInfo,
		apartment: value,
	};
	await updateErrandData(newErrandData);		
	
	const newDeliveryData = {
		...deliveryInfo,
		apartment: value,
	};
	await updateDeliveryData(newDeliveryData);		
	
	const getAddr = await utils.getLocalCookie("elAddr");		
	let cookieData = {
		...getAddr,
		apt: value,
	}
	await utils.setLocalCookie("elAddr", cookieData);	
};

const handleAccessCode = async(value) => {
	setAccessCode(value);
	
	const newErrandData = {
		...errandInfo,
		accessCode: value,
	};
	await updateErrandData(newErrandData);	
	
	const newDeliveryData = {
		...deliveryInfo,
		accessCode: value,
	};
	await updateDeliveryData(newDeliveryData);		
	
	const getAddr = await utils.getLocalCookie("elAddr");		
	let cookieData = {
		...getAddr,
		access: value,
	}
	await utils.setLocalCookie("elAddr", cookieData);	
};

const handleChange = () => {
	setSchedule(false);
	setChange(true);
};

const handleLocationAddress = (value) => {
	setLocationAddress(value);
	setLat(0);
	setLng(0);
};

const handleSetDay = async (value) => {
	await setDay(value);
	await setTime("");
	Object.entries(mapData).forEach(async (entry) => {
		const [key, val] = entry;
		if (val["day"] === value) {
			await setDay(value);
			await setTime("");
			await setTimeData([
				{ label: "Select Time", value: "", disable: false },
			]);
			await setTimeData(val["times"]);
      } else if (key) {
		}
	});
};

const handleSchedule = () => {
	setSchedule(true);
	setChange(false);
};

const handleScheduleUpdate = () => {
	if (!day) {
		message.error("Please select delivery day");
		return false;
	}
	if (!time) {
		message.error("Please select delivery time");
		return false;
	}
	
	Object.entries(dayData).forEach((entry) => {
		const [key, val] = entry;
		if (val["value"] === day) {
			setDelTime(val["label"]);
			setDelTime2(time);
			setSchedule(false);
			setChange(false);
			const newDeliveryData = {
				...deliveryInfo,
				day: val["label"],
				time: time,
			};
			updateDeliveryData(newDeliveryData);
			
      } else if (key) {
		}
	});
};

const handleChangeUpdate = async () => {
	if (!locationAddress && addresses.length === 0) {
		message.error("Please enter delivery address");
		return false;
	}
	if (!locationAddress) {
		setLoading(true);
		let selected,
		newEntry = [];
		for (var i = 0; i < addresses.length; i++) {
			if (
				addresses[i].id === defaultAddress &&
				addresses[i]?.address !== "null" &&
				addresses[i]?.address !== null &&
				addresses[i]?.address !== ""
        ) {
				let elat, elng;
				if (marker?.lat) {
					elat = marker?.lat;
					elng = marker?.lng;
          } else {
					const getAddr = await utils.getLocalCookie("elAddr");			
					if(getAddr?.latitude){
						elat = getAddr?.latitude;
						elng = getAddr?.longitude;
						}else{
						elat = 0;
						elng = 0;
					}
				}
				selected = { ...addresses[i], addressMain: true };
				newEntry.push(selected);
				setLat(elat);
				setLng(elng);
				setAddress(addresses[i]?.address);
        } else if (
				addresses[i]?.address !== "null" &&
				addresses[i]?.address !== ""
        ) {
				newEntry.push({ ...addresses[i], addressMain: false });
			}
			//console.log('AA', newEntry);
			if (i === addresses.length - 1) {
				dispatch(setSavedAddresses(JSON.stringify(newEntry)));
				//console.log('483', newEntry);
				setAddresses(newEntry);
				setMarker(null);
				setAdjust(false);
				setNext(false);
				setSchedule(false);
				setChange(false);
			}
		}
		setLoading(false);
    } else {
		setLoad(false);
		const searchAddress = `${houseNo} ${locationAddress}, ${city}, ${state}`;
		let fetchParams = await search(searchAddress);
		//console.log('BB', fetchParams);
		setParams(fetchParams);
		setLat(fetchParams?.data?.loc?.latitude);
		setLng(fetchParams?.data?.loc?.longitude);
	}
};

const handleNextButton = async () => {
	
	if (!locationAddress) {
		if (marker?.lat) {
			setLat(marker?.lat);
			setLng(marker?.lng);
			setMarker(null);
		}
		setAdjust(false);
		setNext(false);
		setSchedule(false);
		setChange(false);
    } else {
		setLat(0);
		setLng(0);
		setAdjust(false);
		setNext(true);
	}
};

const handleSaveButton = async () => {
	
	setNext(false);
	const nlat = marker?.lat ? marker?.lat : params?.data?.loc?.latitude;
	const nlng = marker?.lng ? marker?.lng : params?.data?.loc?.longitude;
	const ncity = params?.data?.city;
	const nstate = params?.data?.state;
	const nzip = params?.data?.zip;
	const ncountry = params?.data?.country;
	setLat(nlat);
	setLng(nlng);
	setAddress(houseNo+' '+locationAddress);		
	
	const getAddr = await utils.getLocalCookie("elAddr");
	
	let cookieData = {
		...getAddr,
		address: houseNo+' '+locationAddress,
		city: ncity,
		state: nstate,
		zip: nzip,
		country: ncountry,
		apt: aptNo,
		access: accessCode,
		latitude: nlat,
		longitude: nlng,
	}
	await utils.setLocalCookie("elAddr", cookieData);		
	
	setFulfillmentType("Delivery");
	setSchedule(false);
	setChange(false);
	
	const mph = await utils.getDriveData('mph');
	const perHrEarnings = await utils.getDriveData('perHrEarnings');		
	const meterPerMile = await utils.getDriveData('meterPerMile');
	const start = [gps?.latitude, gps?.longitude];
	const end = [nlat, nlng];
	const distanceInMeters = utils.gis.calculateDistance(start, end);
	const miles = distanceInMeters / meterPerMile;
	const deliveryFee = utils.deliveryFee(miles, mph, perHrEarnings);
	setFee(`${deliveryInfo?.currency}${deliveryFee}`);
	
	
	const newErrandData = {
		...errandInfo,
		type: "Errand",
		address: houseNo+' '+locationAddress,
		apartment: aptNo,
		latitude: "" + nlat,
		longitude: "" + nlng,
		city: ncity,
		state: nstate,
		zip: nzip,
		country: ncountry,
		accessCode: accessCode,
	};
	
	//console.log(newErrandData);
	
	const newDeliveryData = {
		...deliveryInfo,
		type: "Delivery",
		deliveryFee: deliveryFee,
		address: houseNo+' '+locationAddress,
		apartment: aptNo,
		latitude: "" + nlat,
		longitude: "" + nlng,
		city: ncity,
		state: nstate,
		zip: nzip,
		country: ncountry,
		accessCode: accessCode,
	};
	
	await updateErrandData(newErrandData);
	await updateDeliveryData(newDeliveryData);
	
	let newSavedData = {
		id: "default",
		addressLabel: "",
		address: houseNo+' '+locationAddress,
		addressMain: true,
		apartment: aptNo,
		latitude: "" + nlat,
		longitude: "" + nlng,
		city: ncity,
		state: nstate,
		zip: nzip,
		country: ncountry,
		accessCode: accessCode,
		instruction: "",
	};
	//console.log('617', [newSavedData, ...addresses])
	
	dispatch(setSavedAddresses(JSON.stringify([newSavedData, ...addresses])));
	setAddresses([newSavedData, ...addresses]);
	
};

const handleASAP = () => {
	const newDeliveryData = { ...deliveryInfo, day: "ASAP", time: asapTime };
	setDelTime("ASAP");
	setDelTime2(asapTime);
	setAsapTime(asapTime);
	updateDeliveryData(newDeliveryData);
	setSchedule(false);
};

const handleOpenLogin = async () => {
	await closeAddressModal();
	openLogin();
};

const openAddressModal = async () => {
	await setAddressModalOpen(true);
	await setOpen(true);
};

const closeAddressModal = async () => {
	await setAddressModalOpen(false);
	await setOpen(false);
};

const handleScheduleButton = async () => {
	await setSchedule(false);
	await setChange(false);
};

const handleChangeButton = async () => {
	await setSchedule(false);
	await setChange(false);
};

const handleDefaultAddress = async(e) => {
	setDefaultAddress(e.target.value);
	
	let newEntry = [];
	let selected = {};
	for (var i = 0; i < addresses.length; i++) {
		if (
			addresses[i].id === e.target.value &&
			addresses[i]?.address !== "null" &&
			addresses[i]?.address !== null &&
			addresses[i]?.address !== ""
      ) {
			selected = { ...addresses[i], addressMain: true };
			newEntry.push(selected);
      } else if (
			addresses[i]?.address !== "null" &&
			addresses[i]?.address !== null &&
			addresses[i]?.address !== ""
      ) {
			newEntry.push({ ...addresses[i], addressMain: false });
		}
		
		if (i === addresses.length - 1) {			
			const nlat = selected?.lat ?? selected?.latitude;
			const nlng = selected?.lng ?? selected?.longitude;
			const ncity = selected?.city;
			const nstate = selected?.state;
			const nzip = selected?.zip;
			const ncountry = selected?.country;
			const naddress = selected?.address;
			const napt = selected?.apartment || "";
			const naccess = selected?.accessCode || "";
			setLat(parseFloat(nlat));
			setLng(parseFloat(nlng));
			setAddress(naddress);
			setAptNo(napt);
			setAccessCode(naccess);
			
			const getAddr = await utils.getLocalCookie("elAddr");
			
			let cookieData = {
				...getAddr,
				address: naddress,
				city: ncity,
				state: nstate,
				zip: nzip,
				country: ncountry,
				apt: napt,
				access: naccess,
				latitude: nlat,
				longitude: nlng,
			}
			await utils.setLocalCookie("elAddr", cookieData);					
			
			setFulfillmentType("Delivery");
			setSchedule(false);
			setChange(false);
			setMarker(null);
			
			const mph = await utils.getDriveData('mph');
			const perHrEarnings = await utils.getDriveData('perHrEarnings');		
			const meterPerMile = await utils.getDriveData('meterPerMile');
			
			const start = [gps?.latitude, gps?.longitude];
			const end = [nlat, nlng];
			const distanceInMeters = utils.gis.calculateDistance(start, end);
			const miles = distanceInMeters / meterPerMile;
			const deliveryFee = await utils.deliveryFee(miles, mph, perHrEarnings);
			console.log('OK', start, end, naddress);
			setFee(`${deliveryInfo?.currency}${deliveryFee}`);			
			
			const newErrandData = {
				...errandInfo,
				type: "Errand",
				address: naddress,
				apartment: napt,
				latitude: parseFloat(nlat),
				longitude: parseFloat(nlng),
				city: ncity,
				state: nstate,
				zip: nzip,
				country: ncountry,
				accessCode: naccess,
			};
			
			const newDeliveryData = {
				...deliveryInfo,
				type: "Delivery",
				deliveryFee: deliveryFee,
				address: naddress,
				apartment: napt,
				latitude: parseFloat(nlat),
				longitude: parseFloat(nlng),
				city: ncity,
				state: nstate,
				zip: nzip,
				country: ncountry,
				accessCode: naccess,
			};
			
			dispatch(setSavedAddresses(JSON.stringify(newEntry)));
			//console.log('759', newEntry);
			setAddresses(newEntry);
			await updateErrandData(newErrandData);
			await updateDeliveryData(newDeliveryData);
			let mileRadius = await utils.getDriveData('mileRadius');
			mileRadius = parseFloat(mileRadius);
			
			if (miles > mileRadius) {
				message.error(`The delivery address is too far away.`);			
			}			
		}
	}
};

const radioStyle = {
	display: "block",
	height: "30px",
	lineHeight: "30px",
	fontFamily: "Montserrat",
	fontWeight: 600,
	color: "#3e5725",
};

const getHeader = () => {
	if (fulfillmentType && schedule && !change) {
		return "Order Ahead";
    } else if (fulfillmentType && !schedule && change) {
		return "Delivery Address";
    } else if (fulfillmentType && !schedule && !change) {
		return "Update Address";
    } else {
		return "Update Address";
	}
};

const getButton = () => {
	if (fulfillmentType && schedule && !change) {
		return [
			<Button
			key={uuidv4()}
			className="c__button"
			onClick={() => handleScheduleButton()}
			>
			Cancel
			</Button>,
			<Button
			key={uuidv4()}
			className="c__button"
			onClick={() => handleScheduleUpdate()}
			>
			Update
			</Button>,
		];
    } else if (fulfillmentType && !schedule && change) {
		if (lat !== 0) {
			return [
				<Button
				key={uuidv4()}
				className="c__button"
				onClick={() => handleChangeButton()}
				>
				Cancel
				</Button>,
				<Button
				key={uuidv4()}
				className="c__button"
				onClick={() => handleNextButton()}
				>
				Next
				</Button>,
			];
      } else if (next) {
			return [
				<Button
				key={uuidv4()}
				className="c__button"
				onClick={() => handleSaveButton()}
				>
				Save
				</Button>,
			];
      } else {
			return [
				<Button
				key={uuidv4()}
				className="c__button"
				onClick={() => handleChangeButton()}
				>
				Cancel
				</Button>,
				<Button
				key={uuidv4()}
				className="c__button"
				loading={loading}
				onClick={() => handleChangeUpdate()}
				>
				Use
				</Button>,
			];
		}
    } else if (fulfillmentType && !schedule && !change) {
		return [
			<Button
			key="back"
			className="c__button"
			onClick={() => closeAddressModal()}
			>
			Close
			</Button>,
		];
    } else if (!fulfillmentType && !schedule && !change) {
		if (lat !== 0) {
			return [
				<Button
				key={uuidv4()}
				className="c__button"
				onClick={() => closeAddressModal()}
				>
				Cancel
				</Button>,
				<Button
				key={uuidv4()}
				className="c__button"
				onClick={() => handleNextButton()}
				>
				Next
				</Button>,
			];
      } else if (next) {
			return [
				<Button
				key={uuidv4()}
				className="c__button"
				onClick={() => handleSaveButton()}
				>
				Save
				</Button>,
			];
      } else {
			return [
				<Button
				key={uuidv4()}
				className="c__button"
				onClick={() => handleChangeButton()}
				>
				Cancel
				</Button>,
				<Button
				key={uuidv4()}
				className="c__button"
				loading={loading}
				onClick={() => handleChangeUpdate()}
				>
				Use
				</Button>,
			];
		}
    } else {
		return null;
	}
};

const search = async (query) => {
	try {
		if (!query) {
			return;
		}
		const region = await utils.getLocalCookie('__user_region');	
		let target = '';
		
		if(region){
			target =
			utils.USER_CALL + "?request=address&address=" + query + '&country='+region;
			}else{
			target =
			utils.USER_CALL + "?request=address&address=" + query;
		}
		
		const header = new Headers({
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: "Bearer " + authToken,
		});
		const posturl = utils.BASE_URL + target; //utils.GET_URL;
		
		const dataPost = "";
		const res = await utils.fetchURL(posturl, "GET", dataPost, header);
		const response = await res.json();
		return response;
    } catch (error) {
		//console.error(error);
	}
};

return (
	<>
	{fulfillmentType && (
		<div className="_bucj1r" data-name="cart.deliveryInfo.address.pickup">
		<svg
		className="_17lw9oo"
		aria-label="Delivery icon"
		width="20"
		height="25"
		viewBox="0 0 17 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		>
		<title>Delivery icon</title>
		<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M8.5 0C4.0879 0 0.5 3.57642 0.5 7.99164C0.5 9.84522 1.14432 11.6412 2.32265 13.072L7.72807 19.6357C7.91803 19.8664 8.20119 20 8.5 20C8.79881 20 9.08197 19.8664 9.27193 19.6357L14.6773 13.072C15.8557 11.6412 16.5 9.84522 16.5 7.99164C16.5 3.57642 12.9121 0 8.5 0ZM6.5 8C6.5 6.89543 7.39543 6 8.5 6C9.60457 6 10.5 6.89543 10.5 8C10.5 9.10457 9.60457 10 8.5 10C7.39543 10 6.5 9.10457 6.5 8Z"
		fill="#4C4C4C"
		></path>
		</svg>
		<div className="_1ucqjmf">
		<div>
		<div data-name="delivery-name" className="_15mkp10 _1upj7ie">
		<p data-name="delivery-title-name">Delivery</p>
		<p data-name="delivery-date-time">
		{deliveryInfo?.day}
		{deliveryInfo?.day !== "ASAP"
			? " - " + deliveryInfo?.time
		: ""}
		</p>
		</div>
		<div className="_1r1eulg">
		<p data-name="delivery-address" className="_17y0oyu">
		{address}{" "}
		{aptNo && aptNo.substring(0, 1) !== "#" ? "#" + aptNo : aptNo}
		</p>
		</div>
		</div>
		<button
		aria-disabled="false"
		className="_1JdD97nX-WGcPASVXFJXKw _1SEARqWERjoS6g0rgsTmMe _1p5njgr"
		tabIndex="0"
		type="button"
		id="deliveryAddress"
		onClick={() => openAddressModal()}
		>
		Edit
		</button>
		</div>
		</div>
	)}
	
	{!fulfillmentType && (
		<button className="_164jj58" onClick={() => openAddressModal()}>
		<p className="_1e55dq5">Delivery or Pick up available</p>
		<svg
		width="23"
		height="23"
		viewBox="0 0 23 23"
		fill="none"
		aria-hidden="true"
		xmlns="http://www.w3.org/2000/svg"
		>
		<title>Order online</title>
		<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M11.5 22.5C17.574 22.5 22.5 17.575 22.5 11.5C22.5 5.426 17.574 0.5 11.5 0.5C5.426 0.5 0.5 5.426 0.5 11.5C0.5 17.575 5.426 22.5 11.5 22.5V22.5Z"
		stroke="black"
		strokeLinejoin="round"
		></path>
		<path
		d="M5.5 11.5H18.5"
		stroke="black"
		strokeLinecap="round"
		strokeLinejoin="round"
		></path>
		<path
		d="M14 7L18.5 11.5L14 16"
		stroke="black"
		strokeLinecap="round"
		strokeLinejoin="round"
		></path>
		</svg>
		</button>
	)}
	
	<Drawer
	title={getHeader()}
	open={addressModalOpen}
	closable={false}
	maskClosable={false}
	footer={getButton()}
	placement="right"        
	onClose={() => closeAddressModal()}
	size={`large`}
	>
	{/*Change Address Display*/}
	{fulfillmentType && !schedule && change && (
		<>
		<Form
		style={{ backgroundColor: "rgba(204,220,205,0.2)", padding: 20 }}
		initialValues={{ locationAddress: houseNo+' '+locationAddress }}
		>
		
		<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: -20}}>
		<FormInput
		label={`House No`}
		placeholder="No"
		secureTextEntry="text"
		inputType="input"
		inputId="houseNo"
		onChange={(e) => setHouseNo(e.target.value)}
		value={houseNo}
		containerStyle={{
			marginTop: 15,
			width: '28%',
			textAlign: 'left',
		}}
		inputContainerStyle={{
			backgroundColor: '#ffffff',
			width: '100%'
		}}
		inputLabelStyle={{
			fontSize: 12
		}}
		inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}
		/>
		
		<FormInput
		label={`Street Name *`}
		placeholder="Street Name"
		secureTextEntry="text"
		inputType="input"
		inputId="locationAddress"
		onChange={(e) => handleLocationAddress(e.target.value)}
		value={locationAddress}
		containerStyle={{
			marginTop: 15,
			width: '70%',
			textAlign: 'left',
		}}
		inputContainerStyle={{
			backgroundColor: '#ffffff',
			width: '100%'
		}}
		inputLabelStyle={{
			fontSize: 12
		}}
		inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}	
		/>		
		</div>
		
		<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: -20}}>				
		<FormInput
		label={`City *`}
		placeholder="City"
		secureTextEntry="text"
		inputType="input"
		inputId="city"
		onChange={(e) => setCity(e.target.value)}
		value={city}
		containerStyle={{
			marginTop: 15,
			width: '49%',
			textAlign: 'left',
		}}
		inputContainerStyle={{
			backgroundColor: '#ffffff',
			width: '100%'
		}}
		inputLabelStyle={{
			fontSize: 12
		}}
		inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}	
		/>
		
		<FormInput
		label={`State/Province *`}
		placeholder="State/Province"
		secureTextEntry="text"
		inputType="input"
		inputId="state"
		onChange={(e) => setState(e.target.value)}
		value={state}
		containerStyle={{
			marginTop: 15,
			width: '49%',
			textAlign: 'left',
		}}
		inputContainerStyle={{
			backgroundColor: '#ffffff',
			width: '100%'
		}}
		inputLabelStyle={{
			fontSize: 12
		}}
		inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}	
		/>
		</div>
		
		
		{lat !== 0 && load && (
			<div className="mapupdate">
			<MapUpdate
			lat={lat}
			lng={lng}
			height={300}
			width={"100%"}
			setMarker={setMarker}
			/>
			</div>
		)}
		</Form>
		
		{ellogin === "1" && addresses?.length > 0 && (
			<>
			<br />
			<div style={{ ...radioStyle, textDecoration: "underline" }}>
			SAVED DELIVERY ADDRESSES
			</div>
			<Radio.Group
			onChange={handleDefaultAddress}
			value={defaultAddress}
			>
			{addresses.map((address) => (
				<Radio
				style={radioStyle}
				key={address.id}
				value={address.id}
				>
				{`${address.addressLabel ? address.addressLabel : ""}${
					address.addressLabel ? ":" : ""
				} ${address.address}`}
				</Radio>
			))}
			</Radio.Group>
			</>
		)}
		
		{ellogin === "1" && addresses.length === 0 && (
			<div style={radioStyle}>No Saved Address</div>
		)}
		
		{ellogin === "0" && (
			<button
			type="button"
			onClick={() => handleOpenLogin()}
			style={{
				border: "none",
				paddingLeft: 0,
				marginLeft: 0,
				minWidth: 0,
				...radioStyle,
			}}
			>
			<span style={{ color: "#000", textDecoration: "underline" }}>
			Log in for saved address
			</span>
			</button>
		)}
		</>
	)}
	
	{/*Schedule Display*/}
	{fulfillmentType && schedule && !change && (
		<Form
		style={{ backgroundColor: "rgba(204,220,205,0.2)", padding: 20 }}
		initialValues={{ day: day, time: time }}
		>
		<Form.Item name="day" label="Day">
		<Select
		size="large"
		className="cus_selec"
		style={{
			width: "100%",
		}}
		value={day}
		onChange={(value) => handleSetDay(value)}
		>
		{dayData.map(({ label, value, disable }) => (
			<Option value={value} disabled={disable} key={uuidv4()}>
			{label}
			</Option>
		))}
		</Select>
		</Form.Item>
		<Form.Item name="time" label="Time">
		<Select
		size="large"
		className="cus_selec"
		style={{
			width: "100%",
		}}
		value={time}
		onChange={(value) => setTime(value)}
		>
		{timeData?.map(({ label, value, disable }) => (
			<Option value={value} disabled={disable} key={uuidv4()}>
			{label}
			</Option>
		))}
		</Select>
		</Form.Item>
		</Form>
	)}
	
	{/*Default Display*/}
	{fulfillmentType && !schedule && !change && (
		<>
		<div style={{ backgroundColor: "rgb(204,220,205,0.2)" }}>
		<h2 style={{ fontSize: 18, paddingTop: 20, marginLeft: 20 }}>Where</h2>
		<div className="_bucj1r">
		<svg
		className="_17lw9oo"
		aria-label="Delivery icon"
		width="20"
		height="25"
		viewBox="0 0 17 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		>
		<title>Delivery icon</title>
		<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M8.5 0C4.0879 0 0.5 3.57642 0.5 7.99164C0.5 9.84522 1.14432 11.6412 2.32265 13.072L7.72807 19.6357C7.91803 19.8664 8.20119 20 8.5 20C8.79881 20 9.08197 19.8664 9.27193 19.6357L14.6773 13.072C15.8557 11.6412 16.5 9.84522 16.5 7.99164C16.5 3.57642 12.9121 0 8.5 0ZM6.5 8C6.5 6.89543 7.39543 6 8.5 6C9.60457 6 10.5 6.89543 10.5 8C10.5 9.10457 9.60457 10 8.5 10C7.39543 10 6.5 9.10457 6.5 8Z"
		fill="#4C4C4C"
		></path>
		</svg>
		<div className="_1ucqjmf">
		<div>
		<div className="_1r1eulg">
		<p data-name="delivery-address" className="_17y0oyu">
		{address}{" "}
		{aptNo && aptNo.substring(0, 1) !== "#" ? (
			<>
			{aptNo && <br />}
			<span
			style={{
				color: "#3e5725",
				fontFamily: "Montserrat",
				fontWeight: 600,
				fontSize: 13,
			}}
			>{`${aptNo && "Apt/Ste:"}`}</span>
			{` #${aptNo}`}
			</>
			) : (
			<>
			{aptNo && <br />}
			<span
			style={{
				color: "#3e5725",
				fontFamily: "Montserrat",
				fontWeight: 600,
				fontSize: 13,
			}}
			>{`${aptNo && "Apt/Ste:"}`}</span>
			{` ${aptNo}`}
			</>
		)}
		{accessCode && (
			<>
			{accessCode && <br />}
			<span
			style={{
				color: "#3e5725",
				fontFamily: "Montserrat",
				fontWeight: 600,
				fontSize: 13,
			}}
			>{`${accessCode && "Bldg Access Code:"}`}</span>
			{` ${accessCode}`}
			<br />
			</>
		)}
		<button
		type="button"
		onClick={() => setToggleApt(!toggleApt)}
		style={{
			border: "none",
			paddingLeft: 0,
			marginLeft: 0,
			minWidth: 0,
		}}
		>
		<span
		style={{
			color: "#000",
			textDecoration: "underline",
			marginLeft: 10
		}}
		>
		{toggleApt &&
			aptNo &&
		"Add Apt/Ste & Access Code #"}
		{toggleApt &&
			!aptNo &&
		"Add Apt/Ste & Access Code #"}
		{!toggleApt &&
			!aptNo &&
		"Add Apt/Ste & Access Code #"}
		{!toggleApt &&
			aptNo &&
		"Edit Apt/Ste & Access Code #"}
		</span>
		</button>
		{toggleApt && (
			<>
			<br />
			<Input
			size="large"
			placeholder="Apt #"
			style={{ width: 100, marginRight: 10 }}
			value={aptNo}
			onChange={(e) => handleAptNo(e.target.value)}
			/>
			<Space direction="horizontal" />
			<Input
			size="large"
			placeholder="Access #"
			style={{ width: 100 }}
			value={accessCode}
			onChange={(e) => handleAccessCode(e.target.value)}
			/>
			</>
		)}
		</p>
		</div>
		</div>
		<button
		className="_1JdD97nX-WGcPASVXFJXKw _1SEARqWERjoS6g0rgsTmMe _1p5njgr"
		type="button"
		onClick={() => handleChange()}
		>
		Change
		</button>
		</div>
		</div>
		<Divider style={{ marginTop: 0 }} />
		<h2 style={{ fontSize: 18, marginTop: -8, marginLeft: 20 }}>When</h2>
		<div className="_bucj1r">
		<svg
		className="_17lw9oo"
		aria-label="Delivery icon"
		width="21"
		height="21"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		>
		<title>Clock icon</title>
		<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.5 4.47715 16.0228 0 10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20ZM11.5 9V4H9.5V9C9.5 9.94427 9.94458 10.8334 10.7 11.4L13.9 13.8L15.1 12.2L11.9 9.8C11.6482 9.61115 11.5 9.31476 11.5 9Z"
		fill="#4C4C4C"
		></path>
		</svg>
		<div className="_1ucqjmf">
		<div>
		<div
		data-name="delivery-dayname"
		className="_15mkp10 _1upj7ie"
		>
		<p data-name="delivery-date-daytime">{delTime}</p>
		</div>
		<div className="_1r1eulg">
		<p data-name="delivery-time" className="_17y0oyu">
		{`${delTime === "ASAP" ? "" : delTime2}`}
		{delTime !== "ASAP" && (
			<>
			<br />
			<button
			type="button"
			onClick={() => handleASAP()}
			style={{
				border: "none",
				paddingLeft: 0,
				marginLeft: 0,
				minWidth: 0,
			}}
			>
			<span
			style={{
				color: "#000",
				textDecoration: "underline",
			}}
			>
			Change to ASAP
			</span>
			</button>
			</>
		)}
		</p>
		</div>
		</div>
		<button
		className="_1JdD97nX-WGcPASVXFJXKw _1SEARqWERjoS6g0rgsTmMe _1p5njgr"
		type="button"
		onClick={() => handleSchedule()}
		>
		Schedule
		</button>
		</div>
		</div>
		<Divider style={{ marginTop: 0 }} />
		<h2 style={{ fontSize: 18, marginTop: -8, marginLeft: 20 }}>Delivery Fee</h2>
		<div className="_bucj1r">
		<svg
		className="_17lw9oo"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="43.016 19.535 21.6 22.6"
		width="21.6"
		height="22.6"
		>
		<g transform="matrix(1, 0, 0, 1, -180.236936, -57.598224)">
		<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M 241.011 78.97 L 227.342 78.97 C 226.129 78.97 225.128 80.021 225.128 81.339 C 225.128 81.842 224.72 82.25 224.217 82.25 C 223.714 82.25 223.306 81.842 223.306 81.339 C 223.306 79.033 225.102 77.147 227.342 77.147 L 241.922 77.147 C 242.426 77.147 242.834 77.555 242.834 78.058 L 242.834 81.339 C 242.834 81.842 242.426 82.25 241.922 82.25 C 241.419 82.25 241.011 81.842 241.011 81.339 L 241.011 78.97 Z"
		fill="black"
		></path>
		<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M 243.066 93.759 C 243.066 93.255 243.474 92.847 243.977 92.847 C 244.48 92.847 244.888 93.255 244.888 93.759 L 244.888 98.862 C 244.888 99.365 244.48 99.773 243.977 99.773 L 227.314 99.773 C 226.789 99.773 226.067 99.647 225.328 99.259 C 224.058 98.592 223.278 97.363 223.278 95.582 L 223.278 81.365 C 223.278 80.862 223.686 80.454 224.189 80.454 L 243.977 80.454 C 244.48 80.454 244.888 80.862 244.888 81.365 L 244.888 86.833 C 244.888 87.336 244.48 87.744 243.977 87.744 C 243.474 87.744 243.066 87.336 243.066 86.833 L 243.066 82.277 L 225.1 82.277 L 225.1 95.582 C 225.1 96.67 225.492 97.287 226.175 97.645 C 226.607 97.872 227.057 97.951 227.314 97.951 L 243.066 97.951 L 243.066 93.759 Z"
		fill="black"
		></path>
		<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M 243.977 86.186 C 244.48 86.186 244.888 86.594 244.888 87.097 C 244.888 87.601 244.48 88.009 243.977 88.009 L 239.811 88.009 C 238.599 88.009 237.598 89.06 237.598 90.378 C 237.598 91.696 238.599 92.747 239.811 92.747 L 243.977 92.747 C 244.48 92.747 244.888 93.155 244.888 93.659 C 244.888 94.162 244.48 94.57 243.977 94.57 L 239.811 94.57 C 237.572 94.57 235.775 92.684 235.775 90.378 C 235.775 88.072 237.572 86.186 239.811 86.186 L 243.977 86.186 Z"
		fill="black"
		></path>
		<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M 240.631 90.248 C 240.631 90.853 240.165 91.342 239.589 91.342 C 239.014 91.342 238.548 90.853 238.548 90.248 C 238.548 89.643 239.014 89.155 239.589 89.155 C 240.165 89.155 240.631 89.643 240.631 90.248 Z"
		fill="black"
		></path>
		<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M 243.064 87.154 C 243.064 86.65 243.472 86.242 243.975 86.242 C 244.479 86.242 244.887 86.65 244.887 87.154 L 244.887 93.715 C 244.887 94.218 244.479 94.626 243.975 94.626 C 243.472 94.626 243.064 94.218 243.064 93.715 L 243.064 87.154 Z"
		fill="black"
		></path>
		</g>
		</svg>
		<div className="_1ucqjmf">
		<div>
		<div className="_1r1eulg" style={{ marginTop: 0 }}>
		<p data-name="delivery-address" className="_17y0oyu">
		{`${fee ? fee : "Free"}`}
		</p>
		</div>
		</div>
		</div>
		</div>
		</div>
		</>
	)}
	
	{/*Address Display*/}
	{!fulfillmentType && !schedule && !change && (
		<>
		<Form
		style={{ backgroundColor: "rgba(204,220,205,0.2)", padding: 20 }}
		initialValues={{ locationAddress: houseNo+' '+locationAddress }}
		>
		
		<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: -20}}>
		<FormInput
		label={`House No`}
		placeholder="No"
		secureTextEntry="text"
		inputType="input"
		inputId="houseNo"
		onChange={(e) => setHouseNo(e.target.value)}
		value={houseNo}
		containerStyle={{
			marginTop: 15,
			width: '28%',
			textAlign: 'left',
		}}
		inputContainerStyle={{
			backgroundColor: '#ffffff',
			width: '100%'
		}}
		inputLabelStyle={{
			fontSize: 12
		}}
		inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}
		/>
		
		<FormInput
		label={`Street Name *`}
		placeholder="Street Name"
		secureTextEntry="text"
		inputType="input"
		inputId="locationAddress"
		onChange={(e) => handleLocationAddress(e.target.value)}
		value={locationAddress}
		containerStyle={{
			marginTop: 15,
			width: '70%',
			textAlign: 'left',
		}}
		inputContainerStyle={{
			backgroundColor: '#ffffff',
			width: '100%'
		}}
		inputLabelStyle={{
			fontSize: 12
		}}
		inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}	
		/>		
		</div>
		
		<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: -20}}>				
		<FormInput
		label={`City *`}
		placeholder="City"
		secureTextEntry="text"
		inputType="input"
		inputId="city"
		onChange={(e) => setCity(e.target.value)}
		value={city}
		containerStyle={{
			marginTop: 15,
			width: '49%',
			textAlign: 'left',
		}}
		inputContainerStyle={{
			backgroundColor: '#ffffff',
			width: '100%'
		}}
		inputLabelStyle={{
			fontSize: 12
		}}
		inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}	
		/>
		
		<FormInput
		label={`State/Province *`}
		placeholder="State/Province"
		secureTextEntry="text"
		inputType="input"
		inputId="state"
		onChange={(e) => setState(e.target.value)}
		value={state}
		containerStyle={{
			marginTop: 15,
			width: '49%',
			textAlign: 'left',
		}}
		inputContainerStyle={{
			backgroundColor: '#ffffff',
			width: '100%'
		}}
		inputLabelStyle={{
			fontSize: 12
		}}
		inputClass={'cus_input _1lSOQ6q2xcOkaxVT1smcn7'}	
		/>
		</div>
		{lat !== 0 && load && (
			<div className="mapupdate">
			<MapUpdate
			lat={lat}
			lng={lng}
			height={300}
			width={"100%"}
			setMarker={setMarker}
			/>
			</div>
		)}
		</Form>
		
		<button
		type="button"
		onClick={() => handleOpenLogin()}
		style={{
			border: "none",
			paddingLeft: 0,
			marginLeft: 0,
			minWidth: 0,
		}}
		>
		<span style={{ color: "#000", textDecoration: "underline" }}>
		Log in for saved address
		</span>
		</button>
		</>
	)}
	
	</Drawer>
	</>
);
};

export default AddressModal;
