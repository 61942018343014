import images from './images';

const left = [
  {
    title: 'Be Informed',
    price: '',
    tags: 'Be familiar with how to schedule, pick offer and so on',
  }, 
  {
    title: "Schedule",
    price: '',
    tags: 'Pick available hours you are able to work',
  },
];

const right = [
  {
    title: 'Sign Up',
    price: '',
    tags: 'Submit your application and drivers license',
  },
  {
    title: "Get Ready",
    price: '',
    tags: 'Get your T-shirt, warming bag from ErrandLocal or use your own',
  },
];

const requirements = [
  {
    imgUrl: images.req1,
    title: 'Transport',
    subtitle: "Any car, bike or scooter in some selected cities.",
  },
  {
    imgUrl: images.req2,
    title: 'Auto Insurance',
    subtitle: "Driver must be at least 18 years old with auto insurance for drivers.",
  },
  {
    imgUrl: images.req3,
    title: 'Valid ID & License',
    subtitle: "Valid state I.D. or driver's license for bike riders.",
  },
  {
    imgUrl: images.req4,
    title: 'Smart Phone',
    subtitle: 'An iOS or Android smartphone will be required.',
  },
];

const merchantbenefits = [
  {
    imgUrl: images.req1,
    title: 'Maximize Profit',
    subtitle: "Your restaurant, your food, your profits, Stop letting third-party eat your profit.",
  },
  {
    imgUrl: images.req2,
    title: 'Own your orders',
    subtitle: "Export your customers data anytime in CSV format, it is absolutely free.",
  },
  {
    imgUrl: images.req3,
    title: 'Delivery Ease',
    subtitle: "Fleets of drivers on standby to deliver to your customers, while you focus on in-house customers.",
  },
  {
    imgUrl: images.req4,
    title: 'Low subscription fee',
    subtitle: "Make the most of your profit. You can save up to 75% on commission and so much more.",
  },
];

export default { right, left, requirements, merchantbenefits };
