import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Cookies from "js-cookie";
import { Divider, Tabs, message, Spin } from "antd";
import { v4 as uuidv4 } from "uuid";
import { utils } from "../../utils";
import { motion } from "framer-motion";
import { images } from "../../constants";
import {
  AlertModal,
  AddressNavbar,
  StoreListMap,
	ErrandFormModal,
	ErrandAddressModal,
	ErrandStoreAddressModal,
	ErrandCheckOutInstructionModal,
  RestaurantRequestModal,
} from "../../components";

import { useSelector, useDispatch } from "react-redux";
import {
  selectOrderData,
  selectSavedAddresses,
	selectSavedErrandAddresses,
	selectErrandOrderCart,	
	selectErrandOrderData,
	selectErrandApp,
	setErrandOrderCart,
  setOrderData,
  setErrandOrderData,
  setSavedAddresses,
  setSavedErrandAddresses,
  setSavedCards,
  setPaymentCardData,
  setAddressData,
  setErrandAddressData,
  setErrandApp,
} from "../../includes/navSlice";
import { useNavigate, useParams, useLocation } from "react-router-dom";
const { TabPane } = Tabs;

const tabs = [
	{
		title: "Run Errand",
		content: "Content 1",
		key: "errands",
	},
	{
		title: "All Restaurants",
		content: "Content 2",
		key: "restaurants",
	},
];

//let scrollPositionA = 0;
//let scrollPositionB = 0;


const StoreListScreen = ({logPage}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
	
  const preOrderData = useSelector(selectOrderData);
  const orderData =
    typeof preOrderData === "object" ? preOrderData : JSON.parse(preOrderData);
		
  let savedAddresses = useSelector(selectSavedAddresses);
  savedAddresses =
    typeof savedAddresses === "object"
      ? savedAddresses
      : JSON.parse(savedAddresses);			
			
	let savedErrandAddresses = useSelector(selectSavedErrandAddresses);
  savedErrandAddresses =
    typeof savedErrandAddresses === "object"
      ? savedErrandAddresses
      : JSON.parse(savedErrandAddresses);	
			
  const preErrandOrderCart = useSelector(selectErrandOrderCart);
  const preErrandOrderData = useSelector(selectErrandOrderData);
  let errandApp = useSelector(selectErrandApp);
	
	errandApp  = typeof errandApp === "object" ? errandApp : JSON.parse(errandApp);
	
  const errandCartOrder =
    typeof preErrandOrderCart === "object" ? preErrandOrderCart : JSON.parse(preErrandOrderCart);
		
  const errandOrderData =
    typeof preErrandOrderData === "object" ? preErrandOrderData : JSON.parse(preErrandOrderData); 
				

  const [errandOrderPlaced, setErrandOrderPlaced] = useState([]);
  const [errandOrderCart, setErrandCartOrder] = useState(errandCartOrder);
  const [errandCartLength, setErrandCartLength] = useState(0);
  const [errandAmountTotal, setErrandAmountTotal] = useState(0);
  const [errandQtyTotal, setErrandQtyTotal] = useState(0);
	const [errandType, setErrandType] = useState("Errand");			
						
			
  const sessionemail = utils.getStorage("__el_data_email");
  const authToken = utils.getStorage("__el_data_login_token");
  const ellogin = utils.getStorage("__el_data_login_status");
  const lastName = utils.getStorage("__el_data_last_name");
  const firstName = utils.getStorage("__el_data_first_name");	
	
	const [shopHours, setShopHours] = useState({});
  const [restaurants, setRestaurants] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [errandMarkers, setErrandMarkers] = useState([]);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState([]);
  const [mainParamsFromServer, setMainParamsFromServer] = useState([]);
  const [initoverflow, setOverflow] = useState("auto");
  const [address, setAddress] = useState("");
  const [top, setTop] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [addresses, setAddresses] = useState(savedAddresses);
  const [closeModal, setCloseModal] = useState(false);
  const [addressDataLog, setAddressDataLog] = useState([]);
  const [errandAddressDataLog, setErrandAddressDataLog] = useState([]);

  const [alertModalTitle, setAlertModalTitle] = useState("");
  const [alertModalBody, setAlertModalBody] = useState("");
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertModalButton, setAlertModalButton] = useState("");
  const [alertModalButtonFunc, setAlertModalButtonFunc] = useState();
  const [alertModalButtonFunc2, setAlertModalButtonFunc2] = useState();
	const [isErrand, setIsErrand] = useState(false);
	const [isErrandMsg, setIsErrandMsg] = useState('');
	const [isErrandTitle, setIsErrandTitle] = useState('');
	
	const [activeTab, setActiveTab] = useState('0');		
	const [checking, setChecking] = useState(true);
	
	const [addressModalOpen, setAddressModalOpen] = useState(false);
	const [openSignIn, setOpenSignIn] = useState(false);
	const [moreInfo, setMoreInfo] = useState(false);
	const [errandInfo, setErrandInfo] = useState(errandOrderData);
	const [deliveryInfo, setDeliveryInfo] = useState(orderData);
	const [scrollPositionA, setScrollPositionA] = useState(0);
	const [scrollPositionB, setScrollPositionB] = useState(0);
	const [checkOutError, setCheckOutError] = useState("");
	
	const {actionTab} = useParams();
  
	
	// Restore the scroll position when rendering
  useEffect(() => {
		const scrolltab = `scroll${activeTab}`;
		const scrollContainer = document.getElementById(scrolltab);
		
		if (scrollContainer) {
			if(scrolltab === 'scroll0'){
				scrollContainer.scrollTo(0, scrollPositionA);
				//console.log(scrolltab, scrollPositionA);
				/*scrollContainer.scrollTo({
					top: scrollPositionA,
					behavior: 'smooth', // Enable smooth scrolling
				});*/
			}
			if(scrolltab === 'scroll1'){
				scrollContainer.scrollTo(0, scrollPositionB);
				//console.log(scrolltab, scrollPositionB);
				/*scrollContainer.scrollTo({
					top: scrollPositionB,
					behavior: 'smooth', // Enable smooth scrolling
				});*/
			}
		}
		
  }, [activeTab]);
	
	const handleScroll = () => {
		const scrolltab = `scroll${activeTab}`;
		const scrollContainer = document.getElementById(scrolltab);
		if (scrollContainer) {
			const scrollPosition = scrollContainer.scrollTop;
			//console.log(scrollPosition)
			if(scrolltab === 'scroll0'){
				//scrollPositionA = scrollPosition;
				setScrollPositionA(scrollPosition);
			}
			if(scrolltab === 'scroll1'){
				//scrollPositionB = scrollPosition;
				setScrollPositionB(scrollPosition);
			}
		}
	};	

  useEffect(() => {
    let elAddress, elAddressLat, elAddressLng, elCity, elState;
		const getLoad = async() => {
			const getAddr = await utils.getLocalCookie("elAddr");
			if (getAddr?.address || (!getAddr && !ellogin)) {
				
				elAddress = getAddr?.address;
				elAddressLat = getAddr?.latitude;
				elAddressLng = getAddr?.longitude;
				elCity = getAddr?.city;
				elState = getAddr?.state;
				if(elAddress && elAddressLat && elAddressLng){
					await loadCoordinateInfo(elAddress, elAddressLat, elAddressLng);
				}else if(!elAddressLat && elAddress && elState){					
					const lookAddress = `${elAddress}, ${elCity}, ${elState}`;
					const lookCountry = await utils.getCountryByStateProvince(elState);
					if(lookCountry){
						loadAddressInfo(lookAddress, lookCountry);
					}
				}
				setChecking(false);
			}else if((!getAddr && ellogin === '0')){		
				localStorage.clear();
				const cookies = Object.keys(Cookies.get());
				cookies.forEach(cookie => {
					if(cookie !== 'cookieconsent_status'){
						Cookies.remove(cookie);
					}
				});
				navigate('/');
			}
		}
		getLoad();
  }, []);
	
  useEffect(() => {
		const checkHrs = async () => {
			if (!shopHours?.mon && errandApp?.mon) {
				setShopHours(errandApp);
			}else if (shopHours?.mon) {
				setShopHours(shopHours);
			}else{
				const errandHrs = await errandData();
				if(errandHrs){
					setShopHours(errandHrs);
					await dispatch(setErrandApp(errandHrs));
				}
			}
		}		
    checkHrs();
  }, [errandApp, shopHours]);
	
  useEffect(() => {
    if (markers.length > 0) {			
      setLoad(true);
			setLoading(false);
    }
  }, [markers]);
	
	useEffect(() => {
		const ref = document.referrer;
    const loc = location.pathname;
		logPage(ref, loc);
	}, []);
	
	useEffect(() => {		
		const markArr = [];
		if(errandInfo?.latitude){
			const destination = {
				id: 'destination',
				lat: parseFloat(errandInfo?.latitude),
				lng: parseFloat(errandInfo?.longitude),
				info: "<div id=\"contentInfo\"><div id=\"headerName\"><b>Destination</b></div><h1 id=\"headerInfo\" class=\"headerInfo\">"+errandInfo?.address+"</h1></div>",
			}
			markArr.push(destination);
		}
		
		if(errandInfo?.storeLat){
			const origin = {
				id: 'origin',
				lat: parseFloat(errandInfo?.storeLat),
				lng: parseFloat(errandInfo?.storeLng),
				info: "<div id=\"contentInfo\"><div id=\"headerName\"><b>Pickup - "+errandInfo?.storeLabel+"</b></div><h1 id=\"headerInfo\" class=\"headerInfo\">"+errandInfo?.storeAddress+"</h1></div>",
			}
			markArr.push(origin);
		}
		setErrandMarkers(markArr);
		
  }, [errandInfo]);
	
  useEffect(() => {
		const initial = async() => 
		{
			const markArr = [];
			const getAddr = await utils.getLocalCookie("elAddr");
			if (getAddr?.latitude) {			
				const destination = {
					id: 'destination',
					lat: parseFloat(getAddr?.latitude),
					lng: parseFloat(getAddr?.longitude),
					info: "<div id=\"contentInfo\"><div id=\"headerName\"><b>Destination</b></div><h1 id=\"headerInfo\" class=\"headerInfo\">"+getAddr?.address+"</h1></div>",
				}
				markArr.push(destination);
			}
			
			if(errandInfo?.storeLat){
				const origin = {
					id: 'origin',
					lat: parseFloat(errandInfo?.storeLat),
					lng: parseFloat(errandInfo?.storeLng),
					info: "<div id=\"contentInfo\"><div id=\"headerName\"><b>Pickup - "+errandInfo?.storeLabel+"</b></div><h1 id=\"headerInfo\" class=\"headerInfo\">"+errandInfo?.storeAddress+"</h1></div>",
				}
				markArr.push(origin);
			}
			setErrandMarkers(markArr);
		}
		initial();
  }, [markers, errandInfo]);
	
  useEffect(() => {
    document.body.style.overflow = initoverflow;
  }, [initoverflow]);
	
	useEffect(() => {
    if (actionTab) {
			let stat = false;
			{tabs.map((tab, index) => {
					if(tab.key === actionTab){
						setActiveTab(''+index);
						stat = true;
					}			
			})}
			if(!stat){
				navigate('/search');
			}
    }else{
			navigate('/search');
		}
  }, [actionTab]);

  useEffect(() => {
    const checkLogin = async () => {
      const redirect = await utils.checkLogin(sessionemail);
			const getAddr = await utils.getLocalCookie("elAddr");
			
      if ((redirect && sessionemail) || redirect) {
				
      } 
			else if (!getAddr?.address && sessionemail && ellogin === "1")
			{
				setChecking(true);
				
				let data = mainParamsFromServer;   
				if(data?.user) 
				{
					if (data?.user?.address && data?.user?.gps?.latitude) {
						
						let cookieData = {
							address: data?.user?.address,
							city: data?.user?.city,
							state: data?.user?.state,
							zip: data?.user?.zip,
							country: data?.user?.country,
							phone: data?.user?.phone,
							apt: data?.user?.aptsuite,
							access: data?.user?.accesscode,
							instruction: data?.user?.instruction,
							latitude: data?.user?.gps?.latitude,
							longitude: data?.user?.gps?.longitude,
						}
						await utils.setLocalCookie("elAddr", cookieData);
						
						let newDeliveryData = {
							...deliveryInfo,
							accessCode: data?.user?.accesscode,
							apartment: data?.user?.aptsuite,
							city: data?.user?.city,
							state: data?.user?.state,
							zip: data?.user?.zip,
							country: data?.user?.country,
							phone: data?.user?.phone,
							address: data?.user?.address,
							firstName: data?.user?.firstName,
							lastName: data?.user?.lastName,
						};
						
						let newErrandData = {
							...errandInfo,
							accessCode: data?.user?.accesscode,
							apartment: data?.user?.aptsuite,
							city: data?.user?.city,
							state: data?.user?.state,
							zip: data?.user?.zip,
							country: data?.user?.country,
							phone: data?.user?.phone,
							address: data?.user?.address,
							firstName: data?.user?.firstName,
							lastName: data?.user?.lastName,
						};

						if (
							data?.user?.addresses[0]?.address !== "null" &&
							data?.user?.addresses[0]?.address !== null &&
							data?.user?.addresses[0]?.address !== ""
						) {
							await dispatch(
								setSavedAddresses(JSON.stringify(data?.user?.addresses))
							);
							await dispatch(setAddressData(data?.user?.addresses));
						}
						
						await updateDeliveryData(newDeliveryData);
						await updateErrandData(newErrandData);
						
						await dispatch(
							setSavedCards(JSON.stringify(data?.user?.paymentCards))
						);
						
						await dispatch(setPaymentCardData(data?.user?.paymentCards));
						await dispatch(setErrandAddressData(data?.user?.errandaddresses));
						
						setAddress(data?.user?.address);
						
						loadCoordinateInfo(
							data?.user?.address,
							data?.user?.gps?.latitude,
							data?.user?.gps?.longitude
						);
						setCloseModal(true);						
					}
					
				}
				setChecking(false);
				
      } 
			else if (getAddr?.address && sessionemail && ellogin === "1") 
			{
				
        const cookieadd = getAddr?.address;
        const cookielat = getAddr?.latitude;
        const cookielng = getAddr?.longitude;
        const cookiecity = getAddr?.city;
        const cookiestate = getAddr?.state;
        const cookiezip = getAddr?.zip;
        const cookiecountry = getAddr?.country;
        const cookiephone = getAddr?.phone;
        const cookieapt = getAddr?.apt;
        const cookieaccess = getAddr?.access;
        const cookieinstruction = getAddr?.instruction;
								
        let newSavedData = {
          id: "default",
          addressLabel: "",
          address: cookieadd,
          addressMain: true,
          latitude: cookielat,
          longitude: cookielng,
          city: cookiecity,
          state: cookiestate,
          zip: cookiezip,
          country: cookiecountry,
          phone: cookiephone,
          accessCode: cookieaccess,
          apartment: cookieapt,
          instruction: cookieinstruction,
        };
				
        let newDeliveryData = {
          ...deliveryInfo,
          accessCode: cookieaccess,
          apartment: cookieapt,
          city: cookiecity,
          state: cookiestate,
          zip: cookiezip,
          country: cookiecountry,
          phone: cookiephone,
          address: cookieadd,
          firstName: firstName,
          lastName: lastName,
        };
				
        let newErrandData = {
          ...errandInfo,
          accessCode: cookieaccess,
          apartment: cookieapt,
          city: cookiecity,
          state: cookiestate,
          zip: cookiezip,
          country: cookiecountry,
          phone: cookiephone,
          address: cookieadd,
          firstName: firstName,
          lastName: lastName,
        };

        await updateDeliveryData(newDeliveryData);
        await updateErrandData(newErrandData);

        if (addresses.length === 1) {
					
					let newEntry = [];
          let dbl = false;					
					let data = mainParamsFromServer; 
					if(data?.user) 
					{
						let naddresses = data?.user?.addresses;
						//console.log(naddresses);
						
						for (var i = 0; i < naddresses.length; i++) {
							if (
								naddresses[i].id !== null &&
								naddresses[i].id !== "default" &&
								naddresses[i].address !== cookieadd &&
								naddresses[i].latitude !== cookielat &&
								cookielat &&
								naddresses[i]?.address !== "null" &&
								naddresses[i]?.address !== null &&
								naddresses[i]?.zip &&
								naddresses[i]?.address !== ""
							) {
								newEntry.push({ ...naddresses[i], addressMain: false });
							} else if (
								naddresses[i].address === cookieadd &&
								naddresses[i]?.address !== "null" &&
								naddresses[i]?.address !== null &&
								naddresses[i]?.zip &&
								naddresses[i]?.address !== ""
							) {
								newEntry.push({ ...naddresses[i], addressMain: true });
								setAddress(cookieadd);
								loadCoordinateInfo(cookieadd, cookielat, cookielng);
								setCloseModal(true);
								dbl = true;
							}
							if (i === naddresses.length - 1) {
								if (dbl) {
									setAddresses([...newEntry]);
									dispatch(setSavedAddresses(JSON.stringify([...newEntry])));
									dispatch(
										setSavedCards(JSON.stringify(data?.user?.paymentCards))
									);
									dispatch(setAddressData(data?.user?.addresses));
									dispatch(setPaymentCardData(data?.user?.paymentCards));
									dispatch(setErrandAddressData(data?.user?.errandaddresses));
								} else {
									setAddresses([newSavedData, ...newEntry]);
									dispatch(
										setSavedAddresses(JSON.stringify([newSavedData, ...newEntry]))
									);
									dispatch(
										setSavedCards(JSON.stringify(data?.user?.paymentCards))
									);
									dispatch(setAddressData(data?.user?.addresses));
									dispatch(setPaymentCardData(data?.user?.paymentCards));
									dispatch(setErrandAddressData(data?.user?.errandaddresses));
								}
							}
						}
					
					}
				} else {
          setAddresses([newSavedData]);
          dispatch(setSavedAddresses(JSON.stringify([newSavedData])));
        }
      }
    };
    checkLogin();
  }, [mainParamsFromServer]);

  useEffect(() => {
    if (width <= 1150) {
      setOverflow("auto");
    } else {
      setOverflow("hidden");
    }
  }, [width]);

  useEffect(() => {
    setOverflow("hidden");
  }, []);
	
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    document.title = `Order Food Deliveries With No Hidden Fees | Order Online | ErrandLocal`;
    initEL();
  }, []);

  useEffect(() => {
    setTop(top);
    const handleScrollA = () => {
      setTop(window.scrollY);
    };
    window.addEventListener("scroll", handleScrollA);
    return () => {
      window.removeEventListener("scroll", handleScrollA);
    };
  }, []);

  useEffect(() => {
    const runParam = () => {
			setLoading(true);
      setMarkers([]);
      setLoad(false);
      let storeOperationMsg,
        naddress,
        nmarker,
        nmarkers = [],
        narr = [],
        idarr = [];
      for (let i = 0; i < params?.length; i++) {
        // Get the open and close times from the JSON data
        const { open, close, next, isClose, isBreak, timeZone } =
          params[i]?.today;

        if (params[i]["days"][next]) {
          const nextOpen = params[i]["days"][next]["open"];

          // Get the current date and time in the 'America/Chicago' timezone
					
          const now = moment.tz(timeZone);

          // Get the abbreviated day of the week
          //const dayOfWeek = now.format('ddd');
          const tday = now.clone().format("MM/DD/YYYY");

          let tOpenTime = open.toString();
          tOpenTime = tOpenTime.slice(0, -2) + ":" + tOpenTime.slice(-2);
          let tCloseTime = close.toString();
          tCloseTime = tCloseTime.slice(0, -2) + ":" + tCloseTime.slice(-2);

          let openTime = moment.tz(
            tday + " " + tOpenTime,
            "MM/DD/YYYY hh:mm",
            timeZone
          );
          let closeTime = moment.tz(
            tday + " " + tCloseTime,
            "MM/DD/YYYY hh:mm",
            timeZone
          );
          if (closeTime.clone().isBefore(openTime)) {
            const closeDay = now.clone().add(1, "days").format("MM/DD/YYYY");
            closeTime = moment.tz(
              closeDay + " " + tCloseTime,
              "MM/DD/YYYY hh:mm",
              timeZone
            );
          }

          if (isClose) {
            storeOperationMsg = `Opens On ${
              utils.convertToFullDay(next) +
              " " +
              utils.convertTimeTo12HourFormat(nextOpen)
            }`;
          } else if (isBreak) {
            storeOperationMsg = `Opens On ${
              utils.convertToFullDay(next) +
              " " +
              utils.convertTimeTo12HourFormat(nextOpen)
            }`;
          } else if (now.isBefore(openTime)) {
            storeOperationMsg = `Opening By ${utils.convertTimeTo12HourFormat(
              open
            )}`;
          } else if (now.isAfter(closeTime)) {
            storeOperationMsg = `CLOSE`;
          } else {
            storeOperationMsg = `OPEN`;
          }

          if (params[i]?.id) {
            const locId = params[i]?.id.toLowerCase();
            idarr.push(locId);
            //window[locId] = false;

            naddress = {
              id: locId,
              name: params[i]?.name,
              image: params[i]?.avatar,
              address: params[i]?.address,
              city: params[i]?.city,
              state: params[i]?.state,
              zip: params[i]?.zip,
              openingTime: storeOperationMsg,
              cuisine: params[i]?.categoryname,
              promo: params[i]?.promo,
              latitude: params[i]?.gps?.latitude,
              longitude: params[i]?.gps?.longitude,
              distance: params[i]?.gps?.distance,
            };

            const contentString =
              '<div id="contentInfo">' +
              '<div id="headerName"><b>' +
              params[i]?.name +
              "</b>" +
              "</div>" +
              '<h1 id="headerInfo" class="headerInfo">' +
              params[i]?.address +
              "</h1>" +
              "</div>";
            nmarker = {
              id: locId,
              lat: params[i]?.gps?.latitude,
              lng: params[i]?.gps?.longitude,
              info: contentString,
            };
          }

          narr.push(naddress);
          nmarkers.push(nmarker);

          if (i === params.length - 1) {
            window["allArr"] = idarr;
            setRestaurants(narr);
            setMarkers(nmarkers);						
          }
        }
      }
    };
    if (params?.length > 0) {
      //console.log(params);			
      runParam();
    }else{
			setRestaurants([]);
		}
  }, [params]);

	useEffect(() => {
    Object.entries(errandOrderCart).forEach((entry) => {
      const [key, val] = entry;
      if (key === "orderPlaced") {
        if (val.length > 0) {
          setErrandCartLength(val.length);
          setErrandOrderPlaced(val);
        }
      }
      if (key === "fulfillmentType") {
        //setFulfillmentType(val);
      }
    });
  }, [errandOrderCart]);

  useEffect(() => {
    updateTotal();
  }, [errandOrderCart, errandOrderPlaced, errandInfo]);
	
  useEffect(() => {
    setErrandCartOrder(errandCartOrder);
  }, []);
	
	
	useEffect(() => 
	{
		const getParams = async () => {
			try{	
				if(sessionemail && ellogin === "1"){
					let paramsFromServer;
					paramsFromServer = await fetchParams2();
					
					if(paramsFromServer?.user?.addresses[0]?.address !== null){
						setAddressDataLog(paramsFromServer?.user?.addresses);
						dispatch(setAddressData(paramsFromServer?.user?.addresses));
					}	
					if(paramsFromServer?.user?.errandaddresses[0]?.address !== null){
						setErrandAddressDataLog(paramsFromServer?.user?.errandaddresses);
						dispatch(setErrandAddressData(paramsFromServer?.user?.errandaddresses));
					}
					dispatch(setPaymentCardData(paramsFromServer?.user?.paymentCard));
					setMainParamsFromServer(paramsFromServer);
				}
			}catch(e){}	
		}	
		getParams();
	}, [sessionemail]);
	
	const ExecSignOut = () => {
		const cookies = Object.keys(Cookies.get());
		cookies.forEach(cookie => {
			if (!utils.cookieExceptions.includes(cookie)) {
				Cookies.remove(cookie);
			}
		});

		const storage = Object.keys(localStorage);
		storage.forEach(store => {
			if (!utils.storeExceptions.includes(store)) {
				localStorage.removeItem(store);
			}
		});

		navigate('/');		
	};
	
	const fetchParams2 = async () => {
		try{
			
			setChecking(true);
			const target = utils.USER_CALL+'?email='+sessionemail;
			const header = new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+authToken,
			});
			const posturl = utils.BASE_URL+target; //utils.GET_URL;
			const dataToPost = '';			
			const res = await utils.fetchURL(posturl, 'GET', dataToPost, header);
			const response = await res?.json();
			setChecking(false);
			if(response?.response && response?.status && response?.status === 'Success'){
				const d = await utils.cipherDecode(response.response);
				const mainresponse = typeof d === 'object' ? d : JSON.parse(d);
				return mainresponse;
			}else if(response?.error && response?.msg.toLowerCase() === 'invalid user'){
				ExecSignOut();
			}else if(response?.error){
				ExecSignOut();
			}else{
				ExecSignOut();
			}
			return response;
		}catch(e){setChecking(false);}
		
	};
	
	const errandData = async () => {
		try{			
			setChecking(true);
			const target = utils.USER_CALL+'?request=erranddata&errandlist=true';
			const header = new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+authToken,
			});
			const posturl = utils.BASE_URL+target; //utils.GET_URL;
			const dataToPost = '';			
			const res = await utils.fetchURL(posturl, 'GET', dataToPost, header);
			const response = await res?.json();
			setChecking(false);
			if(response?.data){
				const mainresponse = response?.data?.errandHrs;
				return mainresponse;
			}
			return false;
		}catch(e){setChecking(false);}		
	};

	const updateTotal = async () => {
    let newOrder = 0;
    let newqtyOrder = 0;
    for (let i = 0; i < errandOrderPlaced.length; i++) {
      //newOrder = newOrder + errandOrderPlaced[i].product.price;
      newqtyOrder = newqtyOrder + parseFloat(errandOrderPlaced[i].product.quantity);
    }
    setErrandAmountTotal(errandInfo?.deliveryFee);
    setErrandQtyTotal(newqtyOrder);
    setAddress(errandInfo?.address);
  };
	
	const openLogin = async () => {
    try {
      await setOpenSignIn(true);
    } catch (e) {}
  };		
	
	const updateErrandData = async (data) => {
    try {
      setErrandInfo(data);
      dispatch(setErrandOrderData(JSON.stringify(data)));
    } catch (e) {}
  };
	
	const updateDeliveryData = async (data) => {
    try {
      setDeliveryInfo(data);
      dispatch(setOrderData(JSON.stringify(data)));
    } catch (e) {}
  };
	
	const updateErrandCartData = async (data) => {
    try {
      await dispatch(setErrandOrderCart(JSON.stringify(data)));
      await setErrandCartOrder(data);
      await setErrandCartLength(data["orderPlaced"].length);
      await setErrandOrderPlaced(data["orderPlaced"]);
    } catch (e) {
      //console.log(e.message);
    }
  };

  const displayInfo = (title, description) => {
    setAlertModalTitle(title ? title : "Title");
    const b = description.split("|");
    let mainbody = "";
    for (let i = 0; i < b.length; i++) {
      mainbody += "<p style='font-size: 18px'>" + b[i] + "</p>";
    }
    setAlertModalBody(
      mainbody +
        "<br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>"
    );
    setAlertModalButton("1");
    setAlertModalButtonFunc("");
    setAlertModalButtonFunc2("cancel");
    setAlertModalOpen(!alertModalOpen);
  };

  const initEL = async () => {
    try {
      const ellogin = await utils.getStorage("__el_data_el_login");
      const email = await utils.getStorage("__el_data_email");
      if (!utils.isValidEmail(email) && ellogin === "0") {
        //await navigate('/login');
      } else if (!utils.isValidEmail(email)) {
        //await navigate('/login');
      } else if (ellogin === "0") {
        //await navigate('/login');
      }
    } catch (e) {}
  };
	
	const simpleHash = (input) => {
		let hash = 0;

		if (input.length === 0) {
			return hash;
		}

		for (let i = 0; i < input.length; i++) {
			const char = input.charCodeAt(i);
			hash = (hash << 5) - hash + char;
			hash &= hash; // Convert to 32bit integer
		}

		return hash;
	};
	
	const getLookupStorageKey = (lat, lng) => {
		if(!lat){return}
		if(!lng){return}
		const coordinatesString = lat.toString() + lng.toString();
		const hashValue = simpleHash(coordinatesString);
		return `${hashValue}_reverse`;
	};

	const loadCoordinateInfo2 = async (address, lat, lng) => {
    try {
			
      const paramsFromServer = await loadCoordinate(lat, lng);
			
      if (paramsFromServer?.data?.storeInfo.length < 1) {				
        displayInfo(
          "Out of coverage!",
          "There are no restaurants located around the search address. You may want to consider searching another address or alternatively, request or suggest a restaurant for us to consider listing."
        );
				setLoading(false);
      } else if (paramsFromServer?.data?.storeInfo) {
				if(paramsFromServer?.data?.storeInfo?.length === 0){
					setLoading(false);				
				}
        setParams(paramsFromServer?.data?.storeInfo);
      } else if (paramsFromServer?.error) {
				setLoading(false);			
        setParams([]);
				utils.removeLocalCookie('__user_region');
				displayInfo(
          paramsFromServer?.msg,
          "You are accessing from location we do not serve. Please hold while we redirect you."
        );
				
				setTimeout(()=>{
					window.location.reload();
				}, 5000);
      } else {
				setLoading(false);			
        setParams([]);
      }
      window["lat"] = paramsFromServer?.data?.searchInfo?.loc?.latitude ?? 0;
      window["lng"] = paramsFromServer?.data?.searchInfo?.loc?.longitude ?? 0;
			
      setAddress(address);
    } catch (error) {}
  };

	const loadCoordinateInfo3 = async (address, lat, lng) => {
		try {
			
			const paramsFromServer = await loadCoordinate(lat, lng);
			const lookupStorageKey = getLookupStorageKey(lat, lng);
      const lastLookupTimestamp = localStorage.getItem(lookupStorageKey);
			
			setIsErrand(paramsFromServer?.data?.isErrand);
			setIsErrandMsg(paramsFromServer?.data?.errandMsg);
			setIsErrandTitle(paramsFromServer?.data?.errandTitle);

			if ((paramsFromServer?.data?.count < 1 || !paramsFromServer?.data?.isRestaurant) || (paramsFromServer?.data?.count < 1 && !paramsFromServer?.data?.isRestaurant)) {				
				if (lastLookupTimestamp && Date.now() - lastLookupTimestamp >= 1800000) {
					// Display the error after 1 hour only if the last lookup is the same
					setTimeout(() => {
						displayInfo(
							paramsFromServer?.data?.responseTitle || "Out of coverage!",
							paramsFromServer?.data?.responseMsg || "You are accessing from a location we do not serve. Please wait while we redirect you 1."
						);
						// Set the timestamp for the current lookup
						localStorage.setItem(lookupStorageKey, Date.now());
					}, 5000); // Delayed error display
				} else if(!lastLookupTimestamp) {
					displayInfo(
						paramsFromServer?.data?.responseTitle || "Out of coverage!",
						paramsFromServer?.data?.responseMsg || "You are accessing from a location we do not serve. Please wait while we redirect you."
					);
					// Set the timestamp for the current lookup
					localStorage.setItem(lookupStorageKey, Date.now());
				}
				//console.log(Date.now() - lastLookupTimestamp);
					
				const storage = Object.keys(localStorage);
				storage.forEach(store => {
					if (!utils.storeExceptions.includes(store)) {
						if (lookupStorageKey !== store && store.endsWith('_reverse')) {
							localStorage.removeItem(store);
						}
					}
				});
				
				if(paramsFromServer?.data?.count > 0){					
					setParams(paramsFromServer?.data?.storeInfo);
				}
				
			} else if (paramsFromServer?.data?.storeInfo?.length > 0) {
				setParams(paramsFromServer?.data?.storeInfo);
			}

			if (paramsFromServer?.error) {
				setParams([]);
				utils.removeLocalCookie('__user_region');
				displayInfo(
					paramsFromServer?.msg,
					"You are accessing from a location we do not serve. Please wait while we redirect you 3."
				);

				setTimeout(() => {
					window.location.reload();
				}, 5000);
			}

			window["lat"] = paramsFromServer?.data?.searchInfo?.loc?.latitude ?? 0;
			window["lng"] = paramsFromServer?.data?.searchInfo?.loc?.longitude ?? 0;

			setAddress(address);
		} catch (error) {
			console.log("An error occurred:", error);
		} finally {
			setLoading(false);
		}
	};

	const loadAddressInfo = async (address, country) => {
		try {
			
			const paramsFromServer = await loadAddress(address, country);
					
			const lat = paramsFromServer?.data?.searchInfo?.loc?.latitude;
			const lng = paramsFromServer?.data?.searchInfo?.loc?.longitude;
			const ncity = paramsFromServer?.data?.searchInfo?.city;
			const nstate = paramsFromServer?.data?.searchInfo?.state;
			const nzip = paramsFromServer?.data?.searchInfo?.zip;
			const ncountry = paramsFromServer?.data?.searchInfo?.country;
			const getAddr = await utils.getLocalCookie("elAddr");
						
			const lookupStorageKey = getLookupStorageKey(lat, lng);			
			if(!lookupStorageKey){return}			
			
			let cookieData = {
				...getAddr,
				zip: nzip,
				country: ncountry,
				latitude: lat,
				longitude: lng,
			}
			await utils.setLocalCookie("elAddr", cookieData);
			
			let newDeliveryData = {
				...deliveryInfo,
				zip: nzip,
				country: ncountry
			};
			
			let newErrandData = {
				...errandInfo,
				zip: nzip,
				country: ncountry,
			};
			
			await updateDeliveryData(newDeliveryData);
			await updateErrandData(newErrandData);
			const lastLookupTimestamp = localStorage.getItem(lookupStorageKey);

			const hasResults = paramsFromServer?.data?.count > 0 && paramsFromServer?.data?.isRestaurant;

			if (!hasResults) {
				if (lastLookupTimestamp && Date.now() - lastLookupTimestamp >= 1800000) {
					// Display the error after 1 hour only if the last lookup is the same
					setTimeout(() => {
						displayInfo(
							paramsFromServer?.data?.responseTitle || "Out of coverage!",
							paramsFromServer?.data?.responseMsg || "You are accessing from a location we do not serve. Please wait while we redirect you."
						);
						// Set the timestamp for the current lookup
						localStorage.setItem(lookupStorageKey, Date.now());
					}, 5000); // Delayed error display
				} else if (!lastLookupTimestamp) {
					displayInfo(
						paramsFromServer?.data?.responseTitle || "Out of coverage!",
						paramsFromServer?.data?.responseMsg || "You are accessing from a location we do not serve. Please wait while we redirect you."
					);
					// Set the timestamp for the current lookup
					localStorage.setItem(lookupStorageKey, Date.now());
				}
				// Remove outdated reverse lookup entries
				Object.keys(localStorage).forEach(store => {
					if (!utils.storeExceptions.includes(store) && lookupStorageKey !== store && store.endsWith('_reverse')) {
						localStorage.removeItem(store);
					}
				});

				if (paramsFromServer?.data?.count > 0) {
					setLoad(false);
					setParams([]);
					//setParams(paramsFromServer?.data?.storeInfo);
				}				
			} else {
				// Set params if there are results
				setParams(paramsFromServer?.data?.storeInfo);
			}

			if (paramsFromServer?.error) {
				setParams([]);
				utils.removeLocalCookie('__user_region');
				displayInfo(
					paramsFromServer?.msg,
					"You are accessing from a location we do not serve. Please wait while we redirect you."
				);

				setTimeout(() => {
					window.location.reload();
				}, 5000);
			}

			window["lat"] = paramsFromServer?.data?.searchInfo?.loc?.latitude ?? 0;
			window["lng"] = paramsFromServer?.data?.searchInfo?.loc?.longitude ?? 0;

			//setAddress(address);
			
		} catch (error) {
			console.log("An error occurred:", error);
		} finally {
			setLoading(false);
		}
	};
	
	const loadCoordinateInfoErrand = async (address, lat, lng) => {
		try {
		
			const paramsFromServer = await loadCoordinate(lat, lng);		
			const lookupStorageKey = getLookupStorageKey(lat, lng);
			
			if(!lookupStorageKey){return}
			
			const lastLookupTimestamp = localStorage.getItem(lookupStorageKey);

			setIsErrand(paramsFromServer?.data?.isErrand);
			setIsErrandMsg(paramsFromServer?.data?.errandMsg);
			setIsErrandTitle(paramsFromServer?.data?.errandTitle);

			const hasResults = paramsFromServer?.data?.count > 0 && paramsFromServer?.data?.isRestaurant;

			if (!hasResults) {				
				if (lastLookupTimestamp && Date.now() - lastLookupTimestamp >= 1800000) {
					// Display the error after 1 hour only if the last lookup is the same
					setTimeout(() => {						
						// Set the timestamp for the current lookup
						localStorage.setItem(lookupStorageKey, Date.now());
					}, 5000); // Delayed error display
				} else if (!lastLookupTimestamp) {
					
					// Set the timestamp for the current lookup
					localStorage.setItem(lookupStorageKey, Date.now());
				}
				

				// Remove outdated reverse lookup entries
				Object.keys(localStorage).forEach(store => {
					if (!utils.storeExceptions.includes(store) && lookupStorageKey !== store && store.endsWith('_reverse')) {
						localStorage.removeItem(store);
					}
				});

				if (paramsFromServer?.data?.count > 0) {					
					setLoad(false);
					setParams([]);
					//setParams(paramsFromServer?.data?.storeInfo);
				}else{
					setLoad(false);
					setParams([]);
				}	
			} else {
				// Set params if there are results
				setParams(paramsFromServer?.data?.storeInfo);
			}

			if (paramsFromServer?.error) {
				setParams([]);
				utils.removeLocalCookie('__user_region');
				setTimeout(() => {
					window.location.reload();
				}, 5000);
			}

			window["lat"] = paramsFromServer?.data?.searchInfo?.loc?.latitude ?? 0;
			window["lng"] = paramsFromServer?.data?.searchInfo?.loc?.longitude ?? 0;

			setAddress(address);
		} catch (error) {
			console.log("An error occurred:", error);
		} finally {
			setLoading(false);
		}
	};

	const loadCoordinateInfo = async (address, lat, lng) => {
		try {
		
			const paramsFromServer = await loadCoordinate(lat, lng);		
			const lookupStorageKey = getLookupStorageKey(lat, lng);
			
			if(!lookupStorageKey){return}
			
			const lastLookupTimestamp = localStorage.getItem(lookupStorageKey);

			setIsErrand(paramsFromServer?.data?.isErrand);
			setIsErrandMsg(paramsFromServer?.data?.errandMsg);
			setIsErrandTitle(paramsFromServer?.data?.errandTitle);

			const hasResults = paramsFromServer?.data?.count > 0 && paramsFromServer?.data?.isRestaurant;

			if (!hasResults) {
				if (lastLookupTimestamp && Date.now() - lastLookupTimestamp >= 1800000) {
					// Display the error after 1 hour only if the last lookup is the same
					setTimeout(() => {
						displayInfo(
							paramsFromServer?.data?.responseTitle || "Out of coverage!",
							paramsFromServer?.data?.responseMsg || "You are accessing from a location we do not serve. Please wait while we redirect you."
						);
						// Set the timestamp for the current lookup
						localStorage.setItem(lookupStorageKey, Date.now());
					}, 5000); // Delayed error display
				} else if (!lastLookupTimestamp) {
					displayInfo(
						paramsFromServer?.data?.responseTitle || "Out of coverage!",
						paramsFromServer?.data?.responseMsg || "You are accessing from a location we do not serve. Please wait while we redirect you."
					);
					// Set the timestamp for the current lookup
					localStorage.setItem(lookupStorageKey, Date.now());
				}
				

				// Remove outdated reverse lookup entries
				Object.keys(localStorage).forEach(store => {
					if (!utils.storeExceptions.includes(store) && lookupStorageKey !== store && store.endsWith('_reverse')) {
						localStorage.removeItem(store);
					}
				});

				if (paramsFromServer?.data?.count > 0) {
					setLoad(false);
					setParams([]);
					//setParams(paramsFromServer?.data?.storeInfo);
				}				
			} else {
				// Set params if there are results
				setParams(paramsFromServer?.data?.storeInfo);
			}

			if (paramsFromServer?.error) {
				setParams([]);
				utils.removeLocalCookie('__user_region');
				displayInfo(
					paramsFromServer?.msg,
					"You are accessing from a location we do not serve. Please wait while we redirect you."
				);

				setTimeout(() => {
					window.location.reload();
				}, 5000);
			}

			window["lat"] = paramsFromServer?.data?.searchInfo?.loc?.latitude ?? 0;
			window["lng"] = paramsFromServer?.data?.searchInfo?.loc?.longitude ?? 0;

			setAddress(address);
		} catch (error) {
			console.log("An error occurred:", error);
		} finally {
			setLoading(false);
		}
	};

  const loadCoordinate = async (lat, lng) => {
    try {
      if (!lat) {
        return;
      }
      if (!lng) {
        return;
      }			  	
			const region = await utils.getLocalCookie('__user_region');	
			let target = '';			
			if(region){
				target =
        utils.USER_CALL + "?request=storelist&lat=" + lat + "&lng=" + lng + '&country='+region;
			}else{
				target =
        utils.USER_CALL + "?request=storelist&lat=" + lat + "&lng=" + lng;
			}
      const header = new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + authToken,
      });
      const posturl = utils.BASE_URL + target; //utils.GET_URL;
      const dataToPost = "";
      const res = await utils.fetchURL(posturl, "GET", dataToPost, header);
      const data = await res.json();
      return data;
    } catch (e) {}
  };
	
	const loadAddress = async (address, country) => {
    try {
      if (!address) {
        return;
      }	  	
			let region = await utils.getLocalCookie('__user_region');	
			
			if(region && country && country !== region){
				region = country;
				await utils.setLocalCookie('__user_region', country);
			}
			
			let target =
        utils.USER_CALL + "?request=storelist&address=" + address + '&country='+region;
				
		  const header = new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + authToken,
      });
      const posturl = utils.BASE_URL + target; //utils.GET_URL;
      const dataToPost = "";
      const res = await utils.fetchURL(posturl, "GET", dataToPost, header);
      const data = await res.json();
      return data;
    } catch (e) {}
  };
	
  const Restaurant = ({
    id,
    name,
    image,
    address,
    city,
    state,
    zip,
    openingTime,
    cuisine,
    promo,
    latitude,
    longitude,
    distance,
  }) => (
    <div
      key={uuidv4()}
      className="restaurant-wrap _1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu"
      onClick={() =>
        navigate(
          `/shop/${utils.convertAddress(state)}/${utils.convertAddress(
            city
          )}/${zip}/${utils.convertAddress(name)}/${id}`,
          { replace: false }
        )
      }
    >
      {image && !image.includes("_unavailable") && (
        <div key={uuidv4()} className="restaurant-mainimage">
          <span
            key={uuidv4()}
            style={{
              boxSizing: "border-box",
              display: "block",
              width: "initial",
              height: "initial",
              background: "none",
              opacity: "1",
              border: "0px none",
              margin: "0px",
              padding: "25.7386% 0px 0px",
            }}
          ></span>
          <img
            key={uuidv4()}
            alt="image"
            src={image}
            style={{
              position: "absolute",
              inset: "0px",
              boxSizing: "border-box",
              padding: "0px",
              borderRadius: "10px",
              border: "medium none",
              margin: "auto",
              display: "block",
              width: "0px",
              height: "0px",
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "100%",
              maxHeight: "100%",
              objectFit: "cover",
            }}
          />
        </div>
      )}

      <div key={uuidv4()} className="restaurant">
        {!image && (
          <img
            key={uuidv4()}
            src={image}
            alt={name}
            className="restaurant-image"
          />
        )}
        <div key={uuidv4()} className="restaurant-info">
          <div key={uuidv4()} className="restaurant-name">
            {name}
          </div>
          <div
            key={uuidv4()}
            className="restaurant-address"
            style={{ color: "#201e1e", fontSize: 14, fontWeight: 500 }}
          >{`${address}, ${state} ${zip}`}</div>
          <div
            key={uuidv4()}
            className="restaurant-cuisine"
            style={{
              color: "#201e1e",
              fontSize: 14,
              fontWeight: 500,
              textTransform: "uppercase",
            }}
          >
            {`${cuisine}`}{" "}
            <span style={{ color: "#1677ff", textTransform: "none" }}>
              ~ {distance} mins
            </span>
          </div>

          {openingTime === "OPEN" && (
            <div
              key={uuidv4()}
              className="restaurant-opening-time"
              style={{ color: "#32b390", fontSize: 16, fontWeight: 600 }}
            >
              {openingTime} NOW
            </div>
          )}

          {openingTime === "CLOSE" && (
            <div
              key={uuidv4()}
              className="restaurant-opening-time"
              style={{ color: "#ff642d", fontSize: 16, fontWeight: 600 }}
            >
              {openingTime} -{" "}
              <span style={{ color: "#206cc3" }}>ORDER AHEAD</span>
            </div>
          )}

          {openingTime !== "CLOSE" && openingTime !== "OPEN" && (
            <div
              key={uuidv4()}
              className="restaurant-opening-time"
              style={{ color: "#201e1e", fontSize: 16, fontWeight: 600 }}
            >
              <span style={{ opacity: 0.5 }}>{openingTime}</span> -{" "}
              <span style={{ color: "#206cc3" }}>ORDER AHEAD</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const searchAddress = (elAddress) => {
    setAddress(elAddress);
  };

  const searchCoordinate = (lat, lng) => {};	
	
	const openAddressModal = async () => {
    try {
      await setAddressModalOpen(true);
    } catch (e) {}
  };
	
  const onErrandCheckOut = async() => {
		
		setCheckOutError("");
				
    const priorCart = errandOrderCart["orderPlaced"];
		if (priorCart.length < 1) {
			setCheckOutError(`No item on the list`);
      return;
		}
		
		if (!isErrand) {
			setCheckOutError(`${isErrandTitle}`);
			displayInfo(
				`${isErrandTitle}`,
				`${isErrandMsg}`
			);
			return;
		}		
		
		const storeOpen = false;
		
    const deliveryDay =
      errandInfo?.day === "ASAP"
        ? errandInfo?.day
        : errandInfo?.day.split(",")[1];
				
    const deliveryTime = errandInfo?.time;
    const unix = deliveryTime ? `${deliveryDay} ${deliveryTime}` : null;
    const unixTime = new Date(unix);
    const unixDeliveryTime = unixTime.getTime();

    const date = new Date();
    const daytime = date.getTime();
		
		//console.log(unix, unixDeliveryTime, daytime, deliveryTime, errandInfo?.day);

		if (!address) {
			openAddressModal();
			message.error(`Add delivery address`);
			setCheckOutError(`Add delivery address`);
			return;
		} /*else if (deliveryDay === "ASAP" && !storeOpen) {
			openAddressModal();
			message.error(`Please order ahead`);
			setCheckOutError(`Please order ahead`);
			return;
		} else if (unixDeliveryTime < daytime) {
			openAddressModal();
			message.error(`Please order ahead`);
			setCheckOutError(`Please order ahead`);
			return;
		}*/ else if (errandInfo?.latitude) {
			const { latitude, longitude, storeLat, storeLng } = errandInfo;
			
			let mph = await utils.getDriveData('mph');
			mph = parseFloat(mph);
			let perHrEarnings = await utils.getDriveData('perHrEarnings');
			let xtra = await utils.getDriveData('errandExtra');
			perHrEarnings = parseFloat(perHrEarnings) + parseFloat(xtra);
			let meterPerMile = await utils.getDriveData('meterPerMile');
			meterPerMile = parseFloat(meterPerMile);
			let waitdelay = await utils.getDriveData('waitDelay');
			waitdelay = parseFloat(waitdelay);
			let driverdelay = await utils.getDriveData('driverDelay');
			driverdelay = parseFloat(driverdelay);
			let minTip = await utils.getDriveData('minTip');
			minTip = parseFloat(minTip);
			let mileRadius = await utils.getDriveData('mileRadius');
			mileRadius = parseFloat(mileRadius);
			
			const start = [storeLat, storeLng];
			const end = [latitude, longitude];
			const distanceInMeters = utils.gis.calculateDistance(start, end);
			const miles = distanceInMeters / meterPerMile;
			const deliveryFee = utils.errandDeliveryFee(miles, mph, perHrEarnings);
			if (miles > mileRadius) {
				setCheckOutError(`Restaurant is too far!`);
				displayInfo(
					"Too far!",
					`The restaurant is located too far away. Can you please locate a nearby restaurant that is no more than ${mileRadius} miles away?`
				);
				return;
			}
		}		

		let orderToCart = { ...errandOrderCart, fulfillmentType: 'Errand' };
		updateErrandCartData(orderToCart);
		let newErrandData = { ...errandInfo, type: 'Errand' };
		updateErrandData(newErrandData);
		
		const checkOutData = priorCart[0]?.product;
		const navStore = utils.convertAddress(newErrandData?.storeName);
		//alert(navStore); 
		//return false;
		
		navigate(
			`/errand/checkout/${navStore}`,
			{ replace: false }
		);
	};	
	
	const onErrandCartRemove = async (cartId) => {
    let newOrders = [];
    let prevOrders = errandOrderCart["orderPlaced"];
    for (let i = 0; i < prevOrders.length; i++) {
      if (cartId !== prevOrders[i].product.cartId) {
        newOrders = [...newOrders, prevOrders[i]];
      }
    }

    let orderToCart = { ...errandOrderCart, orderPlaced: newOrders };
    updateErrandCartData(orderToCart);
  };	
	
  const ListRegion = () => {
		return (
			<div className="restaurant-list-wrapper" key={`restaurant-list-wrapper`} id={`scroll${activeTab}`}>
				<Tabs 
					activeKey={activeTab} 
					onChange={(key) => setActiveTab(key)} 
					style={{marginTop: -40}} 
					onScroll={handleScroll}					
					//items={tabs.map((tab, index) => ({ key: index.toString(), title: tab.title }))}
				>
					{tabs.map((tab, index) => (
						<TabPane key={index} tab={tab.title}>
							{tab.content === 'Content 1' && (
							
							<section id={`scroll${activeTab}${activeTab}`} style={{position: 'relative', paddingBottom: 0}}>				
								
								<h1 className="restaurant-title">
									Let's run the errand
								</h1>
								<div className="_1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu">
									<ErrandAddressModal
										fulfillmentType={errandType}
										setFulfillmentType={setErrandType}
										address={address}
										setAddress={setAddress}
										openLogin={openLogin}
										errandInfo={errandInfo}
										updateDeliveryData={updateDeliveryData}
										deliveryInfo={deliveryInfo}
										shopHours={shopHours}
										updateErrandData={updateErrandData}
										addressModalOpen={addressModalOpen}
										setAddressModalOpen={setAddressModalOpen}
										shopTimeZone={`America/Chicago`}	
										addressDataLog={addressDataLog}
										loadCoordinateInfo={loadCoordinateInfoErrand}
									/>
								</div>
								<div className="_1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu">
									<ErrandCheckOutInstructionModal
										updateErrandData={updateErrandData}
										errandInfo={errandInfo}
										address={address}
									/>
								</div>								
								<div className="_1lSOQ6q2xcOkaxVT1smcn7 _3gouMfJy504Yjz5rHjoHpu">
									<ErrandStoreAddressModal
										updateErrandData={updateErrandData}
										errandInfo={errandInfo}
										updateDeliveryData={updateDeliveryData}
										deliveryInfo={deliveryInfo}
										address={address}
										errandAddressDataLog={errandAddressDataLog}
									/>
								</div>							
								
								<Divider/>
								<ErrandFormModal
									moreInfo={moreInfo}
									setMoreInfo={setMoreInfo}
									errandInfo={errandInfo}
									updateErrandData={updateErrandData}
									errandOrderPlaced={errandOrderPlaced}
									updateErrandCartData={updateErrandCartData}
								/>
								<Divider/>							
							
								{errandCartLength === 0 && (
								
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											fontSize: 20,
											fontWeight: 700,
											opacity: 0.2,
											margin: "0 auto",
											marginTop: 50,
											marginBottom: 50,
										}}
									>
										NO ITEM ON LIST
									</div>							
								)}

								{errandCartLength > 0 && (
								<div
									data-name="cart.products"
									className="_1pclOaC04dlaY0UHl3zyWt"
								>
									{errandOrderPlaced.map((errandOrderData, index) => (
										<div
											className="B7a69Q2IoGnNLBxAiDcbR"
											data-name="cart.product"
											style={{ cursor: "pointer" }}
											key={uuidv4()}
										>
											<div className="_25s2omhUhQfcHCtikP0XBk" key={uuidv4()}>
												<div
													className="_8Tpso4fqXWiM2ktK2uB2K"
													data-name="cart.product.quantity"
													key={uuidv4()}
												>{`${errandOrderData?.product?.quantity}x`}</div>
												<div
													className="_28IBU17s5t7XL-a2xuGSRc"
													key={uuidv4()}
												>
													<div
														className="_3KjnRbm5_nWZGCD1uQPzjP"
														key={uuidv4()}
													>
														<div
															className="h8oaw1War6h7kCEs3RATY"
															key={uuidv4()}															
														>
															<div
																className="_3KsO2l-LNdW3dDO2uuHK6N"
																data-name="cart.product.name"
																key={uuidv4()}
															>{`${errandOrderData?.product?.name}`}</div>
															<div
																className="_3T8zb1IA4akzgtCDPmtwOd"
																key={uuidv4()}
															></div>
															<div
																className="_3eybxaF9nXFCPI55vgY_Gx"
																data-name="cart.product.price"
																key={uuidv4()}
															>{`#${index+1}`}</div>
														</div>
														<div
															className="_3qdtGWGgRqWfao-VslXx0u"
															key={uuidv4()}															
														>
															<div
																className="_3ylDuZwTTDugvTa4GQcSov"
																key={uuidv4()}
															>
																<ul
																	className="MIwqEkDopWWLPJ0sgK7Sa"
																	data-name="product_detail_list"
																	key={uuidv4()}
																>
																	<li
																		className="_30zFN38AZ0D4RcGWr47sgk"
																		data-name={`product-description`}
																		key={uuidv4()}
																	>
																		<div>{`${errandOrderData?.product?.description}`}</div>
																	</li>
																	
																	{errandOrderData?.productNote && (
																		<li
																			className="_30zFN38AZ0D4RcGWr47sgk"
																			data-name={`product-note`}
																			key={uuidv4()}
																		>
																			<div>{`${errandOrderData?.productNote}`}</div>
																		</li>
																	)}
																	
																	{errandOrderData?.product?.length && errandOrderData?.product?.width && errandOrderData?.product?.height && (
																		<li
																			className="_30zFN38AZ0D4RcGWr47sgk"
																			data-name={`product-note`}
																			key={uuidv4()}
																		>
																			<div>{`Dimension: ${errandOrderData?.product?.length} * ${errandOrderData?.product?.width} * ${errandOrderData?.product?.height}`}</div>
																		</li>
																	)}
																</ul>
															</div>
														</div>
														<div className="_23zWnWb43MZ_hovn0xDJL6">
															<button
																data-name="delete_item"
																className="_2w2jqstPutCrrmGTTRZg5X"
																onClick={() =>
																	onErrandCartRemove(errandOrderData?.product?.cartId)
																}
															>
																Remove
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
							)}

								<Divider/>								
								
                <div
                  className="_2r-vdmLSoI1KSdsmnMT_l9"
                  data-name="cart.placeOrder.button"
                >
                  <div data-name="cart.placeOrder.button">
                    {checkOutError && errandOrderPlaced.length > 0 && (
                      <div className="_1qovpV8LpPfvmFSsbXj-DR eSlRH0G6u0haaQUiGCluU _2uN2zP5QFqNQqEbJS8CzOu">
                        <div className="_38hkkZ9YqW58C_n3Ke-lJV">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            aria-label="Information icon"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9.5 4.5C9.5 5.32843 8.82843 6 8 6C7.17157 6 6.5 5.32843 6.5 4.5C6.5 3.67157 7.17157 3 8 3C8.82843 3 9.5 3.67157 9.5 4.5ZM9 8V13H7V8H9Z"
                              fill="#201E1E"
                            ></path>
                          </svg>
                        </div>
                        <span className="_3VpT59o1JAnGF5Luv-ZduG">{`${checkOutError}`}</span>
                      </div>
                    )}

                    {/*!load && !loading && errandOrderPlaced.length > 0 && (
                      <button
                        aria-disabled="false"
                        className="_1JdD97nX-WGcPASVXFJXKw _1CwjZK_2Wbc4SetuxezVf9 _2s2DfQ5MrOu2S-UPSvVNvV _1xPD7V9facbrjoKQLjyWQL"
                        tabIndex="0"
                        type="button"
                        onClick={() => onErrandCheckOut()}
												disabled
                      >
                        <div className="_15EJq4yLpZOO3b1FQ32X32">
                          <span className="_2YWgulDMXDKTMsKU8JdXQs">
                            Checkout
                          </span>													
                          <span className="_2T1auHz6PdoVoXHFFUbElm">{`$${errandAmountTotal}`}</span>
                        </div>
                        <span className="_3sB4RNUJzURH4mii1IcjPI">
                          <div className="_3CTlUEiPeJKa55n-Ucpoki _2jB9r-9rzlZqqWUH1E8muO">{`${errandQtyTotal}`}</div>
                        </span>
                      </button>
                    )*/}
										
										{errandOrderPlaced.length > 0 && (
                      <button
                        aria-disabled="false"
                        className="_1JdD97nX-WGcPASVXFJXKw _1CwjZK_2Wbc4SetuxezVf9 _2s2DfQ5MrOu2S-UPSvVNvV _1xPD7V9facbrjoKQLjyWQL"
                        disabled=""
                        tabIndex="0"
                        type="button"
                        onClick={() => onErrandCheckOut()}				
                      >
                        <div className="_15EJq4yLpZOO3b1FQ32X32">
                          <span className="_2YWgulDMXDKTMsKU8JdXQs">
                            Checkout
                          </span>													
                          <span className="_2T1auHz6PdoVoXHFFUbElm">{`$${errandAmountTotal}`}</span>
                        </div>
                        <span className="_3sB4RNUJzURH4mii1IcjPI">
                          <div className="_3CTlUEiPeJKa55n-Ucpoki _2jB9r-9rzlZqqWUH1E8muO">{`${errandQtyTotal}`}</div>
                        </span>
                      </button>
                    )}
                  
									</div>
                </div>								
              </section>
							
							)}
							{tab.content === 'Content 2' && (
							<section id={`scroll${activeTab}${activeTab}`} style={{position: 'relative', paddingBottom: 0}}>				
								<h1 className="restaurant-title">
									Order Food
								</h1>
								{loading && (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											fontSize: 20,
											fontWeight: 700,
											opacity: 0.2,
											margin: "0 auto",
											marginTop: 100,
											marginBottom: 100,
										}}
									>
										Loading Restaurants...
									</div>
								)}
								{!loading && restaurants.map((restaurant, sindex) => (
									<Restaurant key={`restaurant-${sindex}`} {...restaurant} />
								))}
								<RestaurantRequestModal key={`restaurant-request-modal`} />
							</section>										
							)}
						</TabPane>
					))}
				</Tabs>
			</div>
		)		
	}
	
  const MapRegion = (activeTab) => {
		return (
			<div
				className="restaurant-map-wrapper"
				key={`restaurant-map-wrapper`}
				style={{ outline: "none" }}
			>
				{load && !loading && activeTab !== '0' && (
					<StoreListMap
						markers={markers}
						height={"calc(100vh - 80px - 2rem)"}
						width={"100%"}
						searchCoordinate={searchCoordinate}
						title={`Restaurants near you`}
					/>
				)}

				{!load && !loading  && activeTab !== '0' && (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							fontSize: 40,
							fontWeight: 700,
							opacity: 0.2,
							position: "relative",
							top: "40%",
							width: "80%",
							margin: "0 auto",
						}}
					>
						No restaurant listings can be found in the surrounding area.
					</div>
				)}
				
				{loading && activeTab !== '0' && (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							fontSize: 40,
							fontWeight: 700,
							opacity: 0.2,
							position: "relative",
							top: "40%",
							width: "80%",
							margin: "0 auto",
						}}
					>
						Loading Map...
					</div>
				)}
				
				{activeTab === '0' && (					
					<StoreListMap
						markers={errandMarkers}
						height={"calc(100vh - 80px - 2rem)"}
						width={"100%"}
						searchCoordinate={searchCoordinate}
						title={`Location Points`}
					/>
				)}
				
				{!load && !loading  && activeTab === '0' && (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							fontSize: 40,
							fontWeight: 700,
							opacity: 0.2,
							position: "relative",
							top: "40%",
							width: "80%",
							margin: "0 auto",
						}}
					>
						No available data to show.
					</div>
				)}
			</div>
		)
	};
	

  return (
    <>
      <motion.div />
      <div className="el-global--wrapper" key={`el-global-wrapper`}>
        {!checking && (<div className="el-entry el-page--transition">
          <AddressNavbar
            searchAddress={searchAddress}
            address={address}
            loadCoordinateInfo={loadCoordinateInfo}
            closeModal={closeModal}
						errandInfo={errandInfo}
						deliveryInfo={deliveryInfo}
						updateDeliveryData={updateDeliveryData}
						updateErrandData={updateErrandData}
          />
          <div
            className="restaurant-wrapper"
            style={{ outline: "none" }}
          >
						{MapRegion(activeTab)}
						{ListRegion()}
          </div>					
        </div>)}
				
				{checking && <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffffff'}}><Spin size={`large`} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '25%'}}/></div>}
      </div>

      {alertModalOpen && (
        <AlertModal
          setAlertOpenModal={setAlertModalOpen}
          alertModalTitle={alertModalTitle}
          alertModalBody={alertModalBody}
          alertModalButton={alertModalButton}
          setAlertModalButtonFunc={() => setAlertModalOpen(false)}
          setAlertModalButtonFunc2={() => setAlertModalOpen(false)}
          alertType="alert"
        />
      )}
    </>
  );
};

export default StoreListScreen;
