import React, {useState, useEffect} from 'react';
import { images } from '../../constants';
import { v4 as uuidv4 } from "uuid";
import { Modal, CountrySelector } from '../../components';
import { utils } from '../../utils';
import { message } from "antd";
import './SubFooter.css';

const SubFooter = () => {
  const [click, setClick] = useState(false);
  const [sitePhone, setSitePhone] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalPage, setModalPage] = useState('');
  const [modalButton, setModalButton] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalButtonFunc, setModalButtonFunc] = useState(null);
  const [year, setYear] = useState('2023');
  
  useEffect(() => {		
		window.document.body.style.overflow = modalOpen ? "hidden" : "auto";
	}, [modalOpen]);
  
  useEffect(() => {		
		setClick(click);
	}, [click]);
	
  useEffect(() => {		
		const date = new Date();
		const yr = date.getFullYear();
		setYear(yr);
	}, []);
	
	useEffect(() => {		
		const getHis = async() => 
		{		
			const region = await utils.getLocalCookie('__user_region');
			const isPresent = await utils.supportedCountries.find(country => country.code === region);
			
			if(region){
				setSitePhone(isPresent?.phone);
			}
		}
		getHis();
	}, []);
  
  const sendLink = async (obj) => {
		try{
			let url, state = document.getElementById('modal-status');
			setClick(true);
			const authToken = await utils.getStorage('__el_data_login_token');
			if(modalPage === 'merchant'){
				url = utils.MERCHANT_URL;
				}else if(modalPage === 'driver'){
				url = utils.DRIVER_URL;
				}else if(modalPage === 'customer'){
				url = utils.USER_URL;
			}
			const header = {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+authToken
			};
			const dataToPost = await utils.cipherEncode(JSON.stringify(obj));
			const res = await utils.fetchURL(url, 'POST', dataToPost, header);
			const data = await res.json();
			setClick(false);
			
			if(data.msg === 'Success'){
				await setModalOpen(!modalOpen);
				message.success('Download link was sent to your phone');				
				}else{				
				state.style.display = 'block';
				state.className = 'cus_error center show';
				state.innerHTML = '<b>'+data.msg+'</b>';
			}
			}catch(e){
			setClick(false);
			message.error(e.message);
		}
		
	}
	
  const ExecOperation = (type) => {
		
		let linkphonetype;				
		let state = document.getElementById('modal-status');
		let linkphone = document.getElementById('linkphone').value;		
		
		if(type === 'notspecific'){
			if(!document.getElementById('android').checked && !document.getElementById('ios').checked){
				state.style.display = 'block';
				state.className = 'cus_error center show';		
				state.innerHTML = '<b>Choose phone operating system</b>';
				return;
			}
			if(document.getElementById('android').checked){
				linkphonetype = 'android';
				}else if(document.getElementById('ios').checked){
				linkphonetype = 'ios';
				}else {
				return;
			}
			}else{
			if(type){
				linkphonetype = type;
				}else {
				return;
			}
		}
		
		if(!linkphone){
			state.style.display = 'block';
			state.className = 'cus_error center show';		
			state.innerHTML = '<b>Phone number is required</b>';
			return;
		}
		
		var obj = {
			'data_type': 'linkrequest',
			'data_os': linkphonetype,			
			'data_phone': linkphone,
		}
		
		sendLink(obj);	
	}
  
  const sendAppCustomer = (app) => {
		setModalPage('customer');
		if(app){	
			setModalTitle('ErrandLocal App - '+app);
			setModalBody("<label key='l1'>Phone number *</label><br/><input maxlength=10 type='phone' id='linkphone' placeholder='' class='modal-text-input' name='linkphone'/><br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
			setModalButton(true);
			setModalButtonFunc(app);
			setModalOpen(!modalOpen);
			window.document.body.style.overflow = 'hidden';
			}else{
			setModalTitle('ErrandLocal App');
			setModalBody("<label key='l1'>Choose operating system *</label><br/><br/>Android <input type='radio' id='android' class='modal-radio' name='os'/> | iOS <input type='radio' id='ios' class='modal-radio' name='os'/> <br/><br/><label key='l2'>Phone number *</label><br/><input maxlength=10 type='phone' id='linkphone' class='modal-text-input' name='linkphone'/><br/><br/><div class='card-number' style='outline: none' key='d1'><div id='modal-status' style='display: none' key='d2'></div></div>");
			setModalButton(true);
			setModalButtonFunc('notspecific');
			setModalOpen(!modalOpen);
			window.document.body.style.overflow = 'hidden';
		}
	}
  
  
  const retFalse = () => {
	  return false;
	}
	
  return(
		<>
    <div className="pg-footer">
    <footer className="footer">
		<svg className="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
		<path className="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
		</svg>
		<div className="footer-content">
		<div className="footer-content-column">
		<div className="footer-logo">
		<a className="footer-logo-link" onClick={()=> sendAppCustomer('Android')} style={{marginRight: 10}}>
		<span className="hidden-link-text"></span>
		<img src={images.googleplay} style={{height: 50, width: 100, marginTop: 25}} alt="Play store" />
		</a> 
		<a className="footer-logo-link" onClick={()=> sendAppCustomer('iOS')}>
		<span className="hidden-link-text"></span>
		<img src={images.appstore} style={{height: 50, width: 100}} alt="App store" />
		</a>
		</div>
		<div className="footer-menu">
		<h2 className="footer-menu-name"> Get to know us</h2>
		<ul id="menu-get-started" className="footer-menu-list">
		<li className="menu-item menu-item-type-post_type menu-item-object-product">
		<a onClick={()=> retFalse()}>About ErrandLocal</a>
		</li>
		<li className="menu-item menu-item-type-post_type menu-item-object-product">
		<a onClick={()=> sendAppCustomer()}>Our Apps</a>
		</li>              
		<li className="menu-item menu-item-type-post_type menu-item-object-product">
		{/*<a onClick={()=> retFalse()}>Our Blog</a>*/}
		</li>
		</ul>
		</div>
		</div>
		<div className="footer-content-column">
		{/*<div className="footer-menu">
			<h2 className="footer-menu-name"> Company</h2>
			<ul id="menu-company" className="footer-menu-list">
			<li className="menu-item menu-item-type-post_type menu-item-object-page">
			<a onClick={()=> retFalse()}>Contact</a>
			</li>
			<li className="menu-item menu-item-type-taxonomy menu-item-object-category">
			<a onClick={()=> retFalse()}>News</a>
			</li>
			<li className="menu-item menu-item-type-post_type menu-item-object-page">
			<a onClick={()=> retFalse()}>Careers</a>
			</li>
			</ul>
		</div>*/}
		<div className="footer-menu">
		<h2 className="footer-menu-name"> Legal</h2>
		<ul id="menu-legal" className="footer-menu-list">
		<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
		<a href="https://errandlocal.com/privacy">Privacy Policy</a>
		</li>
		<li className="menu-item menu-item-type-post_type menu-item-object-page">
		<a href="https://errandlocal.com/tos">Terms of Service</a>
		</li>
		{/*<li className="menu-item menu-item-type-post_type menu-item-object-page">
			<a onClick={()=> retFalse()}>Do not sell my information</a>
		</li>*/}
		</ul>
		</div>
		<div className="footer-menu">
		<h2 className="footer-menu-name"> Quick Links</h2>
		<ul id="menu-quick-links" className="footer-menu-list">
		
		<li className="menu-item menu-item-type-post_type menu-item-object-page">
		<a href="https://driver.errandlocal.com/">Drive with ErrandLocal</a>
		</li>
		
		</ul>
		</div>
		</div>
		{/*
			<div className="footer-content-column">
			<div className="footer-menu">
			<h2 className="footer-menu-name"> Quick Links</h2>
			<ul id="menu-quick-links" className="footer-menu-list">
			
			<li className="menu-item menu-item-type-custom menu-item-object-custom">
			<a rel="noopener noreferrer" onClick={()=> retFalse()}>Gift Cards</a>
			</li>
			<li className="menu-item menu-item-type-custom menu-item-object-custom">
			<a rel="noopener noreferrer" onClick={()=> retFalse()}>Support Center</a>
			</li>
			<li className="menu-item menu-item-type-custom menu-item-object-custom">
			<a rel="noopener noreferrer" onClick={()=> retFalse()}>Service Status</a>
			</li>
			<li className="menu-item menu-item-type-post_type menu-item-object-page">
			<a href="https://get.errandlocal.com/">Restaurant Owner</a>
			</li>
			
			<li className="menu-item menu-item-type-post_type menu-item-object-page">
			<a href="https://driver.errandlocal.com/">Drive with ErrandLocal</a>
			</li>
			
			<li className="menu-item menu-item-type-post_type_archive menu-item-object-customer">
			<a onClick={()=> retFalse()}>Customers</a></li>
			<li className="menu-item menu-item-type-post_type menu-item-object-page">
			<a onClick={()=> retFalse()}>Reviews</a>
			</li>
			
			</ul>
			</div>
			</div>
		*/}
		<div className="footer-content-column">
		
		<div className="footer-menu">
		<h2 className="footer-menu-name"> Social Media</h2>
		<ul id="menu-quick-links" className="footer-menu-list">
		<li className="menu-item menu-item-type-custom menu-item-object-custom">
		<a target="_blank" rel="noopener noreferrer" href="https://twitter.com/errandlocal">Twitter / X</a>
		</li>
		<li className="menu-item menu-item-type-custom menu-item-object-custom">
		<a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/MyErrandLocal/">Facebook</a>
		</li>
		<li className="menu-item menu-item-type-custom menu-item-object-custom">
		<a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/errandlocal/">Instagram</a>
		</li>
		<li className="menu-item menu-item-type-custom menu-item-object-custom">
		<a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@errandlocal">Tiktok</a>
		</li>
		<li className="menu-item menu-item-type-custom menu-item-object-custom">
		<a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/errandlocal">Linkedin</a>
		</li>
		</ul>
		</div>
		
		</div>  
		<div className="footer-content-column">		
		<div className="footer-menu">
		<h2 className="footer-menu-name"> Get In Touch</h2>
		<ul id="menu-quick-links" className="footer-menu-list">
		<li className="menu-item menu-item-type-custom menu-item-object-custom">
		<a className="footer-call-to-action-link" target="_self" href={`tel:${sitePhone}`}>{utils.convertToUSPhoneFormat(sitePhone)}</a>
		</li>
		<li className="menu-item menu-item-type-post_type menu-item-object-product">
		<a href="https://help.errandlocal.com">FAQ</a>
		</li>
		<li className="menu-item menu-item-type-post_type menu-item-object-product" style={{display: 'inline-grid'}}>
		<CountrySelector />
		</li>
		</ul>
		</div>
		
		</div> 
		</div>
		<div className="footer-copyright">
		<div className="footer-copyright-wrapper">
		<p className="footer-copyright-text">
		<a className="footer-copyright-link" onClick={()=> retFalse()}>{`ErrandLocal ©${year} | All rights reserved. `}</a>
		</p>
		</div>
		</div>
    </footer>
		</div>
		{modalOpen && (<Modal setOpenModal={setModalOpen} modalTitle={modalTitle} modalBody={modalBody} modalButton={modalButton} setModalButtonFunc={()=>ExecOperation(modalButtonFunc)} key={uuidv4()} />)}
		</>
		
	);
	
	
}
export default SubFooter;
