import React from 'react';
import { SubHeading, MerchantForm } from '../../components';
import { images, data } from '../../constants';
import { motion, useScroll, useSpring  } from "framer-motion";

const Merchant = ({onPostMerchant, mstartpoint, mstage}) => {
	
	const { scrollYProgress } = useScroll();
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001
	});
	
	const BenCard = ({ award: { imgUrl, title, subtitle } }) => (
	  <div className="app__laurels_awards-card">
		<img src={imgUrl} alt="awards" />
		<div className="app__laurels_awards-card_content">
		  <p className="p__cormorant">{title}</p>
		  <p className="p__mont">{subtitle}</p>
		</div>
	  </div>
	);
	
	const Benefits = () => (
	  <div className="app__bg app__wrapper section__padding" id="benefits">
		<div className="app__wrapper_info">
		  <SubHeading title="Benefits" />
		  <h1 className="headtext__cormorant">What you stand to gain</h1>

		  <div className="app__laurels_awards">
			{data.merchantbenefits.map((award) => <BenCard award={award} key={award.title} />)}
		  </div>
		</div>

		<div className="app__wrapper_img">
		  <img src={images.foodcap} alt="dl_img" />
		</div>
	  </div>
	);
	
	const SpecialMenu = () => (
	  <div className="app__specialMenu flex__center section__padding" id="schedule">
		<div className="app__specialMenu-title">
		  <SubHeading title="Wear the smile" />
		  <h1 className="headtext__cormorant">We want you to smile</h1>
		</div>

		<div className="app__specialMenu-menu">
		  
		
		  <div className="app__specialMenu-menu_img smiling_section">
			<img src={images.smilingman} alt="driver__img" />
		  </div>

		</div>

		<div style={{ marginTop: 15 }}>
		  <a href="#apply"><button type="button" className="custom__button">Apply Now</button></a>
		</div>
	  </div>
	);


	return (
	<>
	  <motion.div className="mprogress-bar" style={{ scaleX }} />
	  <div className="app__header app__wrapper section__padding restaurant_bg">
		<motion.div
			initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -200 }}
			className="app__wrapper_info"
		>
		  <SubHeading title="Grow your restaurant’s margin" />
		  <h1 className="app__header-h1">Make the most of your Profit</h1>
		  <p className="p__mont" style={{ margin: '2rem 0' }}>Expand your reach without sacrificing your margins! ErrandLocal delivers your orders from all our platforms to your customers.</p>
		  <a href="#apply"><button type="button" className="custom__button">Apply Now</button></a>
		</motion.div>

		<div className="app__wrapper_img" id="apply">
		  <MerchantForm onPostMerchant={onPostMerchant} mstartpoint={mstartpoint} mstage={mstage} />
		</div>
	  </div>
	  <Benefits />
	  <SpecialMenu />
	  
	</>
	)
}

export default Merchant;
