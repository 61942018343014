import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import './App.css';
import './index.css';
import './el.css';
import { utils } from './utils';


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<BrowserRouter><GoogleOAuthProvider clientId={utils.GOOGLE_CLIENT_ID}><App /></GoogleOAuthProvider></BrowserRouter>);