import CryptoJS from "crypto-js";
import moment from 'moment-timezone';
import Cookies from "js-cookie";

//const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY;
//const PORT = process.env.REACT_APP_PORT;
const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
const SPLITTER = process.env.REACT_APP_SPLITTER;
const BASE_URL = process.env.REACT_APP_BASE_URL;
const squareAppId = process.env.REACT_APP_SQUARE_APP_ID;
const squareLocationId = process.env.REACT_APP_SQUARE_LOCATION_ID;
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const GOOGLE_CLIENT_SECRET = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const USER_URL = process.env.REACT_APP_USER_URL;
const PARTNER_URL = process.env.REACT_APP_PARTNER_URL;
const MERCHANT_URL = process.env.REACT_APP_MERCHANT_URL;
const MERCHANT_SIGNUP_URL = process.env.REACT_APP_MERCHANT_SIGNUP_URL;
const DRIVER_URL = process.env.REACT_APP_DRIVER_URL;
const DRIVER_SIGNUP_URL = process.env.REACT_APP_DRIVER_SIGNUP_URL;
const USER_CALL = process.env.REACT_APP_USER_CALL;
const USER_DRIVE = process.env.REACT_APP_USER_DRIVE;
const USER_REPORT = process.env.REACT_APP_USER_REPORT;
const DRIVER_REPORT = process.env.REACT_APP_DRIVER_REPORT;
const MERCHANT_REPORT = process.env.REACT_APP_MERCHANT_REPORT;
const STORE_MENU_URL = process.env.REACT_APP_STORE_MENU_URL;
const SITE_PHONE_NUMBER = process.env.REACT_APP_SITE_PHONE_NUMBER;
const MAP_MARKER_1 = process.env.REACT_APP_MAP_MARKER_1;
const MAP_MARKER_2 = process.env.REACT_APP_MAP_MARKER_2;
const supportemail = process.env.REACT_APP_SUPPORTEMAIL;
const APP_DOWNLOAD = process.env.REACT_APP_DOWNLOAD;
/*
const supportedCountriesMain = [
    { code: 'US', lng: 'United States of America' },
    { code: 'NG', lng: 'Nigeria' },
    { code: 'CA', lng: 'Canada' },
    { code: 'GB', lng: 'United Kingdom' }
];
*/

const supportedCountries = [
    { code: 'US', lng: 'United States of America', shrt: 'United States', phone: 4694855595 },
    { code: 'CA', lng: 'Canada', shrt: 'Canada', phone: 7828225525 }
];

const cookieExceptions = ['cookieconsent_status'];
const storeExceptions = ['userDetails', 'lastVisit', '__user_region'];


function getRandomColor() {
  var letters = "0123456789ABCDEF"; // Possible values for a color code
  var color = "#"; // Start with a hash symbol
  var rr = 101; 
  var gg = 101; 
  var bb = 101;
  
  while (rr > 100 && gg > 100 && bb > 100) {
    // Generate a random color code
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    
    rr = parseInt(color.substr(1, 2), 16); 
    gg = parseInt(color.substr(3, 2), 16);
    bb = parseInt(color.substr(5, 2), 16);
	
    if (rr < 100 && gg < 100) {}else{
      color = "#"; // Reset the color variable
	  rr = 101;
	  gg = 101;
	  bb = 101;
    }
  }
 
  return color; // Return the final color code
}

function getLightRandomColor() {
  var letters = "0123456789ABCDEF"; // Possible values for a color code
  var color = "#"; // Start with a hash symbol
  var rr = 221; 
  var gg = 221; 
  var bb = 221;
  
  while (rr < 220 && gg < 220 && bb > 220) {
    // Generate a random color code
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    
    rr = parseInt(color.substr(1, 2), 16); 
    gg = parseInt(color.substr(3, 2), 16);
    bb = parseInt(color.substr(5, 2), 16);
	
    if (rr > 220 && gg > 220) {}else{
      color = "#"; // Reset the color variable
	  rr = 221;
	  gg = 221;
	  bb = 221;
    }
  }
 
  return color; // Return the final color code
}

const dashboardOptions = [
	{
		"id": '0',
		"title": 'Pending Orders',
		"description": 'View pending orders',
		"label": 'm18.69,29.43a0.5,0.5 0 0 1 0.5,-0.5l10,0a0.5,0.5 0 0 1 0,1l-10,0a0.5,0.5 0 0 1 -0.5,-0.5zm0,-4a0.5,0.5 0 0 1 0.5,-0.5l10,0a0.5,0.5 0 0 1 0,1l-10,0a0.5,0.5 0 0 1 -0.5,-0.5zm0,-4a0.5,0.5 0 0 1 0.5,-0.5l10,0a0.5,0.5 0 0 1 0,1l-10,0a0.5,0.5 0 0 1 -0.5,-0.5z',
		"href": '/pending-orders',
		"fill": getRandomColor(),
	},
	{
		"id": '1',
		"title": 'Past Orders',
		"description": 'View past orders',
		"label": 'm18.69,29.43a0.5,0.5 0 0 1 0.5,-0.5l10,0a0.5,0.5 0 0 1 0,1l-10,0a0.5,0.5 0 0 1 -0.5,-0.5zm0,-4a0.5,0.5 0 0 1 0.5,-0.5l10,0a0.5,0.5 0 0 1 0,1l-10,0a0.5,0.5 0 0 1 -0.5,-0.5zm0,-4a0.5,0.5 0 0 1 0.5,-0.5l10,0a0.5,0.5 0 0 1 0,1l-10,0a0.5,0.5 0 0 1 -0.5,-0.5z',
		"href": '/past-orders',
		"fill": getRandomColor(),
	},
	{
		"id": '2',
		"title": 'Delivery',
		"description": 'Manage delivery addresses',
		"label": 'm32,27.3l0,1.5c0,0.55 -0.42,1 -0.93,1l-0.97,0c-0.22,1.42 -1.38,2.51 -2.77,2.51c-1.39,0 -2.54,-1.08 -2.77,-2.51l-3.81,0c-0.22,1.42 -1.38,2.51 -2.77,2.51c-1.39,0 -2.54,-1.08 -2.76,-2.51l-0.97,0c-0.52,0 -0.93,-0.45 -0.93,-1l0,-3.51c0,-0.85 0.49,-1.57 1.18,-1.87l1.22,-3.26c0.43,-1.14 1.46,-1.89 2.6,-1.89l5.31,0c0.85,0 1.63,0.42 2.19,1.13l2.92,3.91c1.84,0.22 3.26,1.94 3.26,3.95l0,0.03zm-13.69,-7.02c-0.38,0 -0.72,0.22 -0.87,0.63l-0.88,2.38l3.29,0l0,-3.01l-1.54,0zm2.94,3.01l5.06,0l-1.96,-2.63c-0.18,-0.24 -0.47,-0.38 -0.73,-0.38l-2.37,0l0,3.01zm7.4,6.52c0.05,-0.18 0.08,-0.33 0.08,-0.5c0,-0.83 -0.63,-1.5 -1.4,-1.5c-0.77,0 -1.4,0.67 -1.4,1.5c0,0.18 0,0.32 0.08,0.5c0.19,0.58 0.71,1 1.32,1c0.61,0 1.13,-0.42 1.32,-1zm-9.34,0c0.05,-0.18 0.08,-0.33 0.08,-0.5c0,-0.83 -0.63,-1.5 -1.4,-1.5c-0.77,0 -1.4,0.67 -1.4,1.5c0,0.18 0,0.32 0.08,0.5c0.19,0.58 0.71,1 1.32,1c0.61,0 1.13,-0.42 1.32,-1z',
		"href": '/delivery-addresses',
		"fill": getRandomColor(),
	},
	{
		"id": '3',
		"title": 'Errand',
		"description": 'Manage errand addresses',
		"label": 'm20.61,19.16c0,-0.37 0.32,-0.67 0.71,-0.67l0.47,0c0.39,0 0.71,0.3 0.71,0.67c0,0.37 -0.32,0.67 -0.71,0.67l-0.47,0c-0.39,0 -0.71,-0.3 -0.71,-0.67zm0,2.25c0,-0.37 0.32,-0.67 0.71,-0.67l0.47,0c0.39,0 0.71,0.3 0.71,0.67c0,0.37 -0.32,0.67 -0.71,0.67l-0.47,0c-0.39,0 -0.71,-0.3 -0.71,-0.67zm-5.69,2.25c0,-0.37 0.32,-0.67 0.71,-0.67l0.47,0c0.39,0 0.71,0.3 0.71,0.67c0,0.37 -0.32,0.67 -0.71,0.67l-0.47,0c-0.39,0 -0.71,-0.3 -0.71,-0.67zm15.3,1.57c0.45,0 0.81,0.35 0.81,0.77c0,2.05 -1.4,3.79 -3.35,4.4c0.02,0.07 0.03,0.15 0.03,0.23c0,0.5 -0.42,0.9 -0.95,0.9l-7.58,0c-0.52,0 -0.95,-0.4 -0.95,-0.9c0,-0.08 0.01,-0.16 0.03,-0.23c-1.95,-0.61 -3.35,-2.35 -3.35,-4.4c0,-0.42 0.36,-0.77 0.81,-0.77l14.49,0zm-8.43,-2.25c0.39,0 0.71,0.3 0.71,0.67c0,0.37 -0.32,0.67 -0.71,0.67l-0.47,0c-0.39,0 -0.71,-0.3 -0.71,-0.67c0,-0.37 0.32,-0.67 0.71,-0.67l0.47,0zm-2.84,0c0.39,0 0.71,0.3 0.71,0.67c0,0.37 -0.32,0.67 -0.71,0.67l-0.47,0c-0.39,0 -0.71,-0.3 -0.71,-0.67c0,-0.37 0.32,-0.67 0.71,-0.67l0.47,0zm-1.18,-1.57c0,-0.37 0.32,-0.67 0.71,-0.67l0.47,0c0.39,0 0.71,0.3 0.71,0.67c0,0.37 -0.32,0.67 -0.71,0.67l-0.47,0c-0.39,0 -0.71,-0.3 -0.71,-0.67zm6.87,1.57c0.39,0 0.71,0.3 0.71,0.67c0,0.37 -0.32,0.67 -0.71,0.67l-0.47,0c-0.39,0 -0.71,-0.3 -0.71,-0.67c0,-0.37 0.32,-0.67 0.71,-0.67l0.47,0zm-1.18,-1.57c0,-0.37 0.32,-0.67 0.71,-0.67l0.47,0c0.39,0 0.71,0.3 0.71,0.67c0,0.37 -0.32,0.67 -0.71,0.67l-0.47,0c-0.39,0 -0.71,-0.3 -0.71,-0.67zm4.03,1.57c0.39,0 0.71,0.3 0.71,0.67c0,0.37 -0.32,0.67 -0.71,0.67l-0.47,0c-0.39,0 -0.71,-0.3 -0.71,-0.67c0,-0.37 0.32,-0.67 0.71,-0.67l0.47,0zm-1.18,-1.57c0,-0.37 0.32,-0.67 0.71,-0.67l0.47,0c0.39,0 0.71,0.3 0.71,0.67c0,0.37 -0.32,0.67 -0.71,0.67l-0.47,0c-0.39,0 -0.71,-0.3 -0.71,-0.67zm4.03,1.57c0.39,0 0.71,0.3 0.71,0.67c0,0.37 -0.32,0.67 -0.71,0.67l-0.47,0c-0.39,0 -0.71,-0.3 -0.71,-0.67c0,-0.37 0.32,-0.67 0.71,-0.67l0.47,0zm-5.69,-4.5c0.39,0 0.71,0.3 0.71,0.67c0,0.37 -0.32,0.67 -0.71,0.67l-0.47,0c-0.39,0 -0.71,-0.3 -0.71,-0.67c0,-0.37 0.32,-0.67 0.71,-0.67l0.47,0z',
		"href": '/errand-addresses',
		"fill": getRandomColor(),
	},
	{
		"id": '4',
		"title": 'Payments',
		"description": 'Manage payment methods',
		"label": 'm14.95,20.93a2,2 0 0 1 2,-2l12,0a2,2 0 0 1 2,2l0,8a2,2 0 0 1 -2,2l-12,0a2,2 0 0 1 -2,-2l0,-8zm2,-1a1,1 0 0 0 -1,1l0,1l14,0l0,-1a1,1 0 0 0 -1,-1l-12,0zm13,4l-14,0l0,5a1,1 0 0 0 1,1l12,0a1,1 0 0 0 1,-1l0,-5z',
		"href": '/payments',
		"fill": getRandomColor(),
	},
	/*{
		"id": 'signout',
		"title": 'Sign Out',
		"description": '',
		"label": '',
		"href": '/sign-out',
		"fill": '',
		"dangertext": true,
	},
	{
		"id": '7',
		"title": 'Close My Account',
		"description": '',
		"label": '',
		"href": '/close-account',
		"fill": '',
		"dangertext": true,
	}*/
];
	
const profileOptions = [
	{
		"id": '0',
		"title": 'Name',
		"description": getStorage('__el_data_first_name')+' '+getStorage('__el_data_last_name'),
		"label": 'm26.07,24.81a2.5,2.5 0 1 1 -5,0a2.5,2.5 0 0 1 5,0z',
		"href": '/update-name',
		"fill": getRandomColor(),
	},
	{
		"id": '1',
		"title": 'Email',
		"description": getStorage('__el_data_email'),
		"label": 'm26.07,24.81a2.5,2.5 0 1 1 -5,0a2.5,2.5 0 0 1 5,0z',
		"href": '/update-email',
		"fill": getRandomColor(),
	},
	{
		"id": '2',
		"title": 'Driver',
		"description": 'Manage delivery driver',
		"label": 'm21.66,18.56l4.89,0c1.1,0 2.08,0.79 2.45,1.96l0.95,3.06c0.63,0.29 1.07,0.99 1.07,1.8l0,2.44c0,0.72 -0.35,1.35 -0.86,1.69l0,1.72c0,0.54 -0.39,0.97 -0.86,0.97l-0.86,0c-0.48,0 -0.86,-0.44 -0.86,-0.97l0,-1.46l-6.92,0l0,1.46c0,0.54 -0.39,0.97 -0.86,0.97l-0.86,0c-0.48,0 -0.86,-0.44 -0.86,-0.97l0,-1.72c-0.52,-0.34 -0.86,-0.97 -0.86,-1.69l0,-2.44c0,-0.81 0.44,-1.51 1.07,-1.8l0.95,-3.06c0.36,-1.17 1.35,-1.96 2.45,-1.96l0,0zm0,1.95c-0.37,0 -0.69,0.26 -0.82,0.65l-0.71,2.27l7.94,0l-0.71,-2.27c-0.12,-0.39 -0.45,-0.65 -0.82,-0.65l-4.89,0zm1.15,5.36c-0.24,0 -0.43,0.22 -0.43,0.49l0,0.97c0,0.27 0.19,0.49 0.43,0.49l2.59,0c0.24,0 0.43,-0.22 0.43,-0.49l0,-0.97c0,-0.27 -0.19,-0.49 -0.43,-0.49l-2.59,0zm-3.67,0.97l0.86,0c0.36,0 0.65,-0.33 0.65,-0.73c0,-0.41 -0.29,-0.73 -0.65,-0.73l-0.86,0c-0.36,0 -0.65,0.33 -0.65,0.73c0,0.41 0.29,0.73 0.65,0.73zm9.08,-1.46c-0.36,0 -0.65,0.33 -0.65,0.73c0,0.41 0.29,0.73 0.65,0.73l0.86,0c0.36,0 0.65,-0.33 0.65,-0.73c0,-0.41 -0.29,-0.73 -0.65,-0.73l-0.86,0z',
		"href": '/find-driver',
		"fill": getRandomColor(),
	},
	{
		"id": '3',
		"title": 'Password',
		"description": 'Change Password',
		"label": 'm23.57,18.43a2,2 0 0 1 2,2l0,4l-4,0l0,-4a2,2 0 0 1 2,-2zm3,6l0,-4a3,3 0 0 0 -6,0l0,4a2,2 0 0 0 -2,2l0,5a2,2 0 0 0 2,2l6,0a2,2 0 0 0 2,-2l0,-5a2,2 0 0 0 -2,-2z',
		"href": '/change-password',
		"fill": getRandomColor(),
	},
	{
		"id": '4',
		"title": 'Delivery Code',
		"description": 'Change Delivery Code',
		"label": 'm23.57,18.43a2,2 0 0 1 2,2l0,4l-4,0l0,-4a2,2 0 0 1 2,-2zm3,6l0,-4a3,3 0 0 0 -6,0l0,4a2,2 0 0 0 -2,2l0,5a2,2 0 0 0 2,2l6,0a2,2 0 0 0 2,-2l0,-5a2,2 0 0 0 -2,-2z',
		"href": '/delivery-code',
		"fill": getRandomColor(),
	},
	/*{
		"id": 'signout',
		"title": 'Sign Out',
		"description": '',
		"label": '',
		"href": '/sign-out',
		"fill": '',
		"dangertext": true,
	},
	{
		"id": '7',
		"title": 'Close My Account',
		"description": '',
		"label": '',
		"href": '/close-account',
		"fill": '',
		"dangertext": true,
	}*/
];



const countryOptions = [
		{ label: 'Select Country', value: '', disabled: false },
    { label: 'United States of America', value: 'US', disabled: false },
    { label: 'Canada', value: 'CA', disabled: false },
];

const stateOptionsUS = [
    { label: 'Select State', value: '', disabled: false },
    { label: 'Alabama', value: 'AL', disabled: false },
    { label: 'Alaska', value: 'AK', disabled: false },
    { label: 'Arizona', value: 'AZ', disabled: false },
    { label: 'Arkansas', value: 'AR', disabled: false },
    { label: 'California', value: 'CA', disabled: false },
    { label: 'Colorado', value: 'CO', disabled: false },
    { label: 'Connecticut', value: 'CT', disabled: false },
    { label: 'Delaware', value: 'DE', disabled: false },
    { label: 'District Of Columbia', value: 'DC', disabled: false },
    { label: 'Florida', value: 'FL', disabled: false },
    { label: 'Georgia', value: 'GA', disabled: false },
    { label: 'Hawaii', value: 'HI', disabled: false },
    { label: 'Idaho', value: 'ID', disabled: false },
    { label: 'Illinois', value: 'IL', disabled: false },
    { label: 'Indiana', value: 'IN', disabled: false },
    { label: 'Iowa', value: 'IA', disabled: false },
    { label: 'Kansas', value: 'KS', disabled: false },
    { label: 'Kentucky', value: 'KY', disabled: false },
    { label: 'Louisiana', value: 'LA', disabled: false },
    { label: 'Maine', value: 'ME', disabled: false },
    { label: 'Maryland', value: 'MD', disabled: false },
    { label: 'Massachusetts', value: 'MA', disabled: false },
    { label: 'Michigan', value: 'MI', disabled: false },
    { label: 'Minnesota', value: 'MN', disabled: false },
    { label: 'Mississippi', value: 'MS', disabled: false },
    { label: 'Missouri', value: 'MO', disabled: false },
    { label: 'Montana', value: 'MT', disabled: false },
    { label: 'Nebraska', value: 'NE', disabled: false },
    { label: 'Nevada', value: 'NV', disabled: false },
    { label: 'New Hampshire', value: 'NH', disabled: false },
    { label: 'New Jersey', value: 'NJ', disabled: false },
    { label: 'New Mexico', value: 'NM', disabled: false },
    { label: 'New York', value: 'NY', disabled: false },
    { label: 'North Carolina', value: 'NC', disabled: false },
    { label: 'North Dakota', value: 'ND', disabled: false },
    { label: 'Ohio', value: 'OH', disabled: false },
    { label: 'Oklahoma', value: 'OK', disabled: false },
    { label: 'Oregon', value: 'OR', disabled: false },
    { label: 'Pennsylvania', value: 'PA', disabled: false },
    { label: 'Rhode Island', value: 'RI', disabled: false },
    { label: 'South Carolina', value: 'SC', disabled: false },
    { label: 'South Dakota', value: 'SD', disabled: false },
    { label: 'Tennessee', value: 'TN', disabled: false },
    { label: 'Texas', value: 'TX', disabled: false },
    { label: 'Utah', value: 'UT', disabled: false },
    { label: 'Vermont', value: 'VT', disabled: false },
    { label: 'Virginia', value: 'VA', disabled: false },
    { label: 'Washington', value: 'WA', disabled: false },
    { label: 'West Virginia', value: 'WV', disabled: false },
    { label: 'Wisconsin', value: 'WI', disabled: false },
    { label: 'Wyoming', value: 'WY', disabled: false }
];

const stateOptionsCA = [
    { label: 'Select Province', value: '', disabled: false },
    { label: 'Alberta', value: 'AB', disabled: false },
    { label: 'British Columbia', value: 'BC', disabled: false },
    { label: 'Manitoba', value: 'MB', disabled: false },
    { label: 'New Brunswick', value: 'NB', disabled: false },
    { label: 'Newfoundland and Labrador', value: 'NL', disabled: false },
    { label: 'Northwest Territories', value: 'NT', disabled: false },
    { label: 'Nova Scotia', value: 'NS', disabled: false },
    { label: 'Nunavut', value: 'NU', disabled: false },
    { label: 'Ontario', value: 'ON', disabled: false },
    { label: 'Prince Edward Island', value: 'PE', disabled: false },
    { label: 'Quebec', value: 'QC', disabled: false },
    { label: 'Saskatchewan', value: 'SK', disabled: false },
    { label: 'Yukon', value: 'YT', disabled: false }
];

const getStateOptions = (countryCode) => {
		if(!countryCode){return [];}
    switch (countryCode) {
        case 'US':
            return stateOptionsUS;
        case 'CA':
            return stateOptionsCA;
        default:
            return [];
    }
};

const getCountryByStateProvince = (stateProvinceValue) => {
    const usState = stateOptionsUS.find(option => option.value === stateProvinceValue.toUpperCase());
    if (usState) {
        return 'US';
    }
    const caProvince = stateOptionsCA.find(option => option.value === stateProvinceValue.toUpperCase());
    if (caProvince) {
        return 'CA';
    }
    return false;
};


function isValidEmail(value) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
}

/*
function validateEmail1(value, setEmailError) {
    if (value === "") {
        setEmailError("")
    }
    else if (isValidEmail(value)) {
        setEmailError("")
    }
    else {
        setEmailError("Invalid Email")
    }
}
*/

function validateEmail(value, setEmailError) {
    let r = "";
    if (value === "") {
        
    }
    else if (isValidEmail(value)) {
        
    }
    else {
        r = "Invalid Email";
    }
		
	return r;
}

function validateEmailInput(value) {
	let r = "";
    if (value === "") {
        
    }
    else if (isValidEmail(value)) {
        
    }
    else {
        r = "Invalid Email";
    }
	
	return r;
}

function validatePassword(value, setPasswordError) {
    if (value.length < 8) {
        setPasswordError("Password must be 8 characters")
    } else {
        setPasswordError("")
    }
}

function validateInput(value, minLength, setError) {
    if (value.length < minLength) {
        setError("Invalid Input")
    } else {
        setError("")
    }
}

function checksumCard(number) 
{
	// Strip any non-digits (useful for credit card numbers with spaces and hyphens)
	number = number.replace('/D/', '');
	// Set the string length and parity
	var number_length = number.length;
	var parity = number_length % 2;	
	// Loop through each digit and do the maths
	var total = 0;
	var i;
	for (i=0; i<number_length; i++) 
	{
		var digit = parseFloat(number[i]);		
		// Multiply alternate digits by two
		if (i % 2 === parity) {
			digit = digit * 2;
		  // If the sum is two digits, add them together (in effect)
			if (digit > 9) {
				digit = digit - 9;
			}
		}
		// Total up the digits
		total = total + digit;
	}
	// If the total mod 10 equals 0, the number is valid
	return (total % 10 === 0) ? true : false;
	
}

function validateCard(value, minLength, setError, setCard, extra_check = false)
{	
	if (value.length < minLength) {
        setError("Invalid Input");
		setCard("");
    } 
	else 
	{        
		try
		{
			value = value.replace(/\s/g, '').replace(/(\d{4})/g, '$1').trim();
			var cards = ["(4\\d{12}(?:\\d{3})?)", "(3[47]\\d{13})", "(35[2-8][89]\\d\\d\\d{10})", "((?:5020|5038|6304|6579|6761)\\d{12}(?:\\d\\d)?)", "((?:6334|6767)\\d{12}(?:\\d\\d)?\\d?)", "(5[1-5]\\d{14})","(?:(?:(?:4903|4905|4911|4936|6333|6759)\\d{12})|(?:(?:564182|633110)\\d{10})(\\d\\d)?\\d?)"];
			var names = ["Visa", "American Express", "JCB", "Maestro", "Solo", "Mastercard", "Switch"];	
			var i = 0;
			var pattern, matches, merge, len, name = '', plen = names.length;			
			for(i=0; i<plen; i++)
			{
				merge = cards[i];
				pattern = "^(?:"+merge+")$";
				matches = value.match(pattern);
				if(matches)
				{
					len = matches.length;
					if(len){
						name = names[i];
						i = plen;					
					}
				}
			}
			
			var result = 0;
			if(name !== ''){
				result = 1;
			}	
			if(extra_check && result > 0){
				result = (checksumCard(value)) ? 1 : 0;
			}
			var output = (result>0) ? name : false;
			if(output){
				setCard(output);
				setError("");
			}else{
				setError("Invalid Card Number");
			}
		}
		catch(e)
		{
			var error = e.message;
			if(error === 'matches is null'){
				setError("Invalid Input");
			}else{
				setError(error);
			}
		}
    }	
}

function validateCardExpiry(value, minLength, setError)
{	
	if (value.length < minLength) {
        setError("Invalid Input");
    } 
	else 
	{        
		try
		{
			value = value.trim();
			var regEx = new RegExp("^(0[1-9]|1[0-2])/\\d{2}$", "g");
			if(regEx.test(value)) {
               setError("");
            }
            else 
			{
               setError("Invalid Input");
            }
		}
		catch(e)
		{
			var error = e.message;
			if(error === 'matches is null'){
				setError("Invalid Input");
			}else{
				setError(error);
			}
		}
    }	
}


function calculateAngle(coordinates) {
    let startLat = coordinates[0]["latitude"]
    let startLng = coordinates[0]["longitude"]
    let endLat = coordinates[1]["latitude"]
    let endLng = coordinates[1]["longitude"]
    let dx = endLat - startLat
    let dy = endLng - startLng

    return Math.atan2(dy, dx) * 180 / Math.PI
}


function getTime(){
	let created = Math.floor(Date.now() / 1000);
	return created;
}



function getOs() 
{
	// Get the user agent string
	let userAgent = navigator.userAgent;

	// Initialize an empty string to store the operating system
	let os = "";

	// Check if the user agent string contains "Windows"
	if (userAgent.indexOf("Windows") !== -1) {
		os = "Windows";
	}
	// Check if the user agent string contains "Mac OS X"
	else if (userAgent.indexOf("Mac OS X") !== -1) {
		os = "Mac OS X";
	}
	// Check if the user agent string contains "Linux"
	else if (userAgent.indexOf("Linux") !== -1) {
		os = "Linux";
	}
	// If none of the above conditions are true, set the operating system to "Unknown"
	else {
		os = "Unknown";
	}

	return os;
};


function getBrowser() 
{
	let platform = navigator.platform;

	if(platform === "Win32" || platform === "Win64"){
		platform = "Windows";
	}
	else if(platform === "MacIntel" || platform === "MacPPC"){
		platform = "MacOS";
	}
	else if(platform === "Linux x86_64" || platform === "Linux i686"){
		platform = "Linux";
	}
	else {
		platform = "Unknown";
	}
	return platform;
};


function base64Encode(str) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
      String.fromCharCode("0x" + p1)
    )
  );
}

function base64Decode(str) {
  return decodeURIComponent(
    Array.prototype.map
      .call(
        atob(str),
        (c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      )
      .join("")
  );
}

function cipherEncode(str) {
  str = encodeURIComponent(str);
  const key = CryptoJS.SHA256(ENCRYPTION_KEY).toString(CryptoJS.enc.Latin1).substring(0, 16);
  let encrypted = "";
  for (let i = 0; i < str.length; i++) {
    encrypted += String.fromCharCode(str.charCodeAt(i) ^ key.charCodeAt(i % key.length));
  }
  encrypted = btoa(encrypted);
  return encrypted;
}

function cipherDecode(encryptedStr) {
  encryptedStr = atob(encryptedStr);
  const key = CryptoJS.SHA256(ENCRYPTION_KEY).toString(CryptoJS.enc.Latin1).substring(0, 16);
  const decodedEncryptedMessage = encryptedStr;
  let decryptedStr = "";
  for (let i = 0; i < decodedEncryptedMessage.length; i++) {
    decryptedStr += String.fromCharCode(decodedEncryptedMessage.charCodeAt(i) ^ key.charCodeAt(i % key.length));
  }  
	try {
		return decodeURIComponent(decryptedStr);
	}catch (e) {
		//console.log(e.message);
		return decryptedStr;
	}
}

function encryptObject(name, obj) {
  let str = JSON.stringify(obj);
  str = encodeURIComponent(str);
  const key = CryptoJS.SHA256(ENCRYPTION_KEY).toString(CryptoJS.enc.Latin1).substring(0, 16);
  let encrypted = "";
  for (let i = 0; i < str.length; i++) {
    encrypted += String.fromCharCode(str.charCodeAt(i) ^ key.charCodeAt(i % key.length));
  }
  encrypted = btoa(encrypted);
  localStorage.setItem(name, encrypted);
  return encrypted;
}

function decryptObject(name) {
  let encryptedStr = localStorage.getItem(name);
  encryptedStr = atob(encryptedStr);
  const key = CryptoJS.SHA256(ENCRYPTION_KEY).toString(CryptoJS.enc.Latin1).substring(0, 16);
  const decodedEncryptedMessage = encryptedStr;
  let decryptedStr = "";
  for (let i = 0; i < decodedEncryptedMessage.length; i++) {
    decryptedStr += String.fromCharCode(decodedEncryptedMessage.charCodeAt(i) ^ key.charCodeAt(i % key.length));
  }  
  return JSON.parse(decodeURIComponent(decryptedStr));
}


function setStorage(session, value) 
{
	localStorage.setItem(session, value);
	return true;
};

function removeStorage(session) 
{
    try {
        localStorage.removeItem(session);
        // If no error is thrown, removal was successful
        return true;
    } catch (error) {
        // If an error occurs (e.g., if localStorage is disabled), return false
        return false;
    }
}


function getStorage(session) 
{
	let sessionID = false;
	if(localStorage.getItem(session)){
		sessionID = localStorage.getItem(session);
	}
	return sessionID;
};

function tConv24(time24) 
{
	var ts = time24;
	var H = +ts.substr(0, 2);
	var h = (H % 12) || 12;
	h = (h < 10) ? ("0"+h) : h;  // leading 0 at the left for 1 digit hours
	var ampm = H < 12 ? " AM" : " PM";
	ts = h + ts.substr(2, 3) + ampm;
	return ts;
};

function dateToString(timestamp, type = 'datetime', timezone = 'America/Chicago') 
{
	if(timestamp)
	{
		if(!timezone){
			const former = dateToStringFormer(timestamp, type);
			return former;
		}
		
		let formattedDate;
		//const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
		
		const timenow = (parseFloat(timestamp)) * 1000;	
		const date = moment.tz(timenow, timezone);
		if(type === 'date'){
			formattedDate = date.format('MMM D, YYYY');
			return formattedDate;
		}else if(type === 'time'){
			formattedDate = date.format('h:mm A');
			return formattedDate;
		}
		formattedDate = date.format('MMM D, YYYY h:mm A');
		return formattedDate;
	}else{
		return '0:00 AM';
	}
}

function dateToStringFormer(timestamp, type = 'datetime') 
{
	if(timestamp){
		const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
		//const date2 = new Date();
		//const date2time = date2.getTime();
		const timenow = (parseFloat(timestamp)) * 1000;
		const date = new Date(timenow);			
		const day = date.getDate();
		const month = months[date.getMonth()];
		const year = date.getFullYear();
		const datetime = month+' '+day+', '+year;
		const lang = 'en-US';		
		const dateOfString = date.toLocaleTimeString(lang, {hour: '2-digit', minute:'2-digit', hour12: true});
		if(type === 'date'){
			return datetime;
		}else if(type === 'time'){
			if(dateOfString.indexOf("M") === -1){
				//console.log(timestamp+' '+datetime+' '+dateOfString);
				return tConv24(dateOfString);
			}
			return dateOfString;
		}		
		if(dateOfString.indexOf("M") === -1){
			return datetime+' '+tConv24(dateOfString);
		}
		return datetime+' '+dateOfString;
	}else{
		return '0:00 AM';
	}
}

function amountToString(amount) {
	if(amount){
		return '$'+amount.toFixed(2);
	}else{
		return '$0.00';
	}
}

const convertToFullDay = (day) => {
  let thisday = '';
  if(day === 'mon'){
	  thisday = 'Monday';
  }else if(day === 'tue'){
	  thisday = 'Tuesday';
  }else if(day === 'wed'){
	  thisday = 'Wednesday';
  }else if(day === 'thur'){
	  thisday = 'Thursday';
  }else if(day === 'fri'){
	  thisday = 'Friday';
  }else if(day === 'sat'){
	  thisday = 'Saturday';
  }else if(day === 'sun'){
	  thisday = 'Sunday';
  }
  
  return thisday;
}

const convertTimeTo12HourFormat = (time) => {
  const date = new Date();
  time = ''+time;
  time = time.length === 3 ? '0'+time : time;
  date.setHours(time.substring(0, 2));
  date.setMinutes(time.substring(2, 4));
  
  let hour = date.getHours();
  const minute = date.getMinutes();
  let period = hour >= 12 ? "PM" : "AM";
  hour = hour % 12 || 12; // convert hour to 12-hour format
  
  hour = hour.toString().padStart(2, "0");
  const minuteStr = minute.toString().padStart(2, "0");
  
  return `${hour}:${minuteStr} ${period}`;
}


function convertToUSPhoneFormat(phoneNumber) {
	
	if(!phoneNumber){return ''}
	if(phoneNumber === undefined){return ''}
  const cleanPhoneNumber = phoneNumber.toString().replace(/\D/g, ""); // remove non-digit characters
  
  if (cleanPhoneNumber.length !== 10) {
    return phoneNumber; // return original phone number if it's not 10 digits
  }
  
  const areaCode = cleanPhoneNumber.substring(0, 3);
  const prefix = cleanPhoneNumber.substring(3, 6);
  const lineNum = cleanPhoneNumber.substring(6, 10);
  
  return `(${areaCode}) ${prefix}-${lineNum}`;
}

function convertToNearest15Minutes(timeString) {
  // Parse the input string into separate hours, minutes, and meridiem components
  const [hour, minute, meridiem] = timeString.split(/:| /);
  
  
  // Convert the hours and minutes to numbers
  const hourNum = parseInt(hour);
  const minuteNum = parseInt(minute);
  
  // Determine the number of minutes elapsed since the beginning of the day
  const totalMinutes = hourNum * 60 + minuteNum + (meridiem === 'PM' && hourNum !== 12 ? 12 * 60 : 0);
  
  // Round the number of minutes to the nearest multiple of 15
  const roundedMinutes = Math.round(totalMinutes / 15) * 15;
  
  // Calculate the new hours and minutes based on the rounded number of minutes
  const newHour = Math.floor(roundedMinutes / 60) % 12 || 12;
  const newMinute = roundedMinutes % 60;
  //console.log(newHour, newMinute, meridiem);
  //const newMeridiem = newHour < 12 ? 'AM' : 'PM';
  
  // Format the new time as a string
  const newTimeString = `${newHour.toString().padStart(2, '0')}:${newMinute.toString().padStart(2, '0')} ${meridiem}`;
  
  return newTimeString;
}

function convertTimeStringToInteger(timeString) {
  const [hour, minute] = timeString.split(':');
  const amPm = timeString.slice(-2);

  let hourInt = parseInt(hour, 10);
  if (amPm === 'PM' && hourInt !== 12) {
    hourInt += 12;
  } else if (amPm === 'AM' && hourInt === 12) {
    hourInt = 0;
  }

  const minuteInt = parseInt(minute, 10);

  return hourInt * 100 + minuteInt;
}

const errandDeliveryFee = (distance, speed, rate) => {
  const timeInHours = parseFloat(distance) / parseFloat(speed);
  //const timeInMinutes = Math.round(timeInHours * 60);
  let earnings = parseFloat(rate) * timeInHours;
  earnings = Math.floor(earnings) + 0.99;
  return earnings.toFixed(2);	
}

const errandDeliveryDefaultTip = (total, minTip, tip=0) => { 
	let driverTip = 0;
	if(parseFloat(total) > 50)
	{
		driverTip = 0.1 * parseFloat(total);
	}
	else if(parseFloat(total) > 0)
	{
		driverTip = 0.25 * parseFloat(total);
	}
	
	if(tip && parseFloat(tip) >= 0) //2
	{
		driverTip = parseFloat(tip);
	}

	if(minTip && driverTip < minTip)
	{
		driverTip = minTip;
	}
  return driverTip.toFixed(2);
}

const errandDeliveryTip = (total, tipPercentage, minTip=0) => {
  let tipAmount = (parseFloat(total) * parseFloat(tipPercentage)) / 100;
	
	if(minTip && tipAmount < minTip)
	{
		tipAmount = minTip;
	}
  return tipAmount.toFixed(2);
}

const deliveryFee = (distance, speed, rate) => {
  const timeInHours = parseFloat(distance) / parseFloat(speed);
  //const timeInMinutes = Math.round(timeInHours * 60);
  let earnings = parseFloat(rate) * timeInHours;
  
	if (earnings > 20) {}
  else if(earnings >= 12){
	  earnings = 9.99;
  }else if(earnings >= 9){
	  earnings = 7.99;
  }else if(earnings >= 6){
	  earnings = 4.99;
  }else if(earnings >= 4){
	  earnings = 2.99;
  }else{
	  earnings = 1.99;
  }
  
  return earnings.toFixed(2);
}

const deliveryDefaultTip = (total, minTip, tip=0) => { 
	let driverTip = 0;
	if(parseFloat(total) > 50)
	{
		driverTip = 0.1 * parseFloat(total);
	}
	else if(parseFloat(total) > 0)
	{
		driverTip = 0.25 * parseFloat(total);
	}
	
	if(parseFloat(tip) >= 0) //2
	{
		driverTip = parseFloat(tip);
	}

	if(minTip && driverTip < minTip)
	{
		driverTip = minTip;
	}
  return driverTip.toFixed(2);
}

const deliveryTip = (total, tipPercentage, minTip=0) => {
  let tipAmount = (parseFloat(total) * parseFloat(tipPercentage)) / 100;
	
	if(minTip && tipAmount < minTip)
	{
		tipAmount = minTip;
	}
  return tipAmount.toFixed(2);
}

const serviceCharge = (total, serviceratePercentage) => {
  let serviceCharge = (parseFloat(total) * parseFloat(serviceratePercentage)) / 100;
  if(serviceCharge > 10){
    serviceCharge = 10.00;
  }
  return serviceCharge.toFixed(2);
}

const countryJson = {
	"United States": "US"
	,"Canada": "CA"
};

const stateJson = {
	"Alabama": "AL"
	,"Alaska": "AK"
	,"Arizona": "AZ"
	,"Arkansas": "AR"
	,"California": "CA"
	,"Colorado": "CO"
	,"Connecticut": "CT"
	,"Delaware": "DE"
	,"Florida": "FL"
	,"Georgia": "GA"
	,"Hawaii": "HI"
	,"Idaho": "ID"
	,"Illinois": "IL"
	,"Indiana": "IN"
	,"Iowa": "IA"
	,"Kansas": "KS"
	,"Kentucky": "KY"
	,"Louisiana": "LA"
	,"Maine": "ME"
	,"Maryland": "MD"
	,"Massachusetts": "MA"
	,"Michigan": "MI"
	,"Minnesota": "MN"
	,"Mississippi": "MS"
	,"Missouri": "MO"
	,"Montana": "MT"
	,"Nebraska": "NE"
	,"Nevada": "NV"
	,"New Hampshire": "NH"
	,"New Jersey": "NJ"
	,"New Mexico": "NM"
	,"New York": "NY"
	,"North Carolina": "NC"
	,"North Dakota": "ND"
	,"Ohio": "OH"
	,"Oklahoma": "OK"
	,"Oregon": "OR"
	,"Pennsylvania": "PA"
	,"Rhode Island": "RI"
	,"South Carolina": "SC"
	,"South Dakota": "SD"
	,"Tennessee": "TN"
	,"Texas": "TX"
	,"Utah": "UT"
	,"Vermont": "VT"
	,"Virginia": "VA"
	,"Washington": "WA"
	,"West Virginia": "WV"
	,"Wisconsin": "WI"
	,"Wyoming": "WY"
	,"District of Columbia": "DC"	
	,"American Samoa": "AS"
	,"Guam": "GU"
	,"Northern Mariana Islands": "MP"
	,"Puerto Rico": "PR"
	,"Virgin Islands": "VI"	
	,"Alberta": "AB"
	,"British Columbia": "BC"
	,"Manitoba": "MB"
	,"New Brunswick": "NB"
	,"Newfoundland": "NL"
	,"Nova Scotia": "NS"
	,"Northwest Territories": "NT"
	,"Nunavut": "NU"
	,"Ontario": "ON"
	,"Prince Edward Island": "PE"
	,"Quebec": "QC"
	,"Saskatchewan": "SK"
	,"Yukon": "YT"
};

let states;
function looksLikeState(str){
	if (!states) {
		let map = stateJson;
		states = {};
		for (var k in map) {
			if (map.hasOwnProperty(k)){
				states[k.toLowerCase()] = true;
				states[map[k].toLowerCase()] = true;
			}
		}
	}
	str = str.trim().toLowerCase();
	return !!states[str];
}

let countries;
function looksLikeCountry(str){
	if (!countries) {
		let map = countryJson;
		countries = {};
		for (var k in map) {
			if (map.hasOwnProperty(k)){
				countries[k.toLowerCase()] = true;
				countries[map[k].toLowerCase()] = true;
			}
		}
	}
	str = str.trim().toLowerCase();
	if (str == 'usa') {
		return true;
	}
	return !!countries[str];
}

const parseAddress = (singleLineAddress, cb) => {
	setTimeout(function() {
		var addressObj = {
			house_number: null,			
			street_name: null,
			street_address: null,
			city: null,
			state: null,
			zip: null,
			postal_code: null,
			country: null
		};
				
		if (typeof singleLineAddress != 'string') {
			//return cb(new Error('Input must be a String'))
			return cb(false,addressObj);
		}
		singleLineAddress = singleLineAddress.trim();

		var postalCode = singleLineAddress.match(/([0-9]{5})|([a-z][0-9][a-z] ?[0-9][a-z][0-9])/gi)
			,indexOfPostalCode = -1;
		if (postalCode) {
			postalCode = postalCode.pop(); // pick match closest to end
			indexOfPostalCode = singleLineAddress.lastIndexOf(postalCode);
			if (indexOfPostalCode == 0 && singleLineAddress.length > 10) {
				// postal code is probably part of street address
				postalCode = null;
				indexOfPostalCode = -1;
			}
			if (postalCode) {
				addressObj.zip = postalCode;
				addressObj.postal_code = postalCode;
				var everythingAfterPostalCode = singleLineAddress.substr(indexOfPostalCode+postalCode.length);
				singleLineAddress = singleLineAddress.substr(0,indexOfPostalCode)+everythingAfterPostalCode;
				var possibleCountry = everythingAfterPostalCode.replace(/\s*,/,'').split(',').shift().trim();
				if (possibleCountry && looksLikeCountry(possibleCountry)) {
					addressObj.country = possibleCountry;
					singleLineAddress = singleLineAddress.substr(0,indexOfPostalCode); // just ditch everything after postal + country
				}
			}
		}

		var addySplit = singleLineAddress.split(',');
		// Neighborhood, City, State
		if (addySplit.length == 3 && looksLikeState(addySplit[2])) {
			addressObj.street_address = addySplit[0].trim();			
			addressObj.city = addySplit[1].trim();
			addressObj.state = addySplit[2].trim();
			const strArr = addressObj.street_address.split(" ");
			strArr.forEach(function(addyPart2) {
				if (!(addyPart2 = addyPart2.trim())) return;
				// if has numbers, assume street address
				if (/[0-9]/.test(addyPart2)) {
					if (!addressObj.house_number) {
						var houseNumberMatch = addyPart2.match(/^\d+([A-Za-z]?(-\d+[A-Za-z]?)?)?$/) ?? '';
						if (houseNumberMatch) {
							addressObj.house_number = houseNumberMatch[0];
						}
					}
					if (!addressObj.street_name && addressObj.house_number) {
						addressObj.street_name = addressObj.street_address.replace(houseNumberMatch[0], '').trim(); // Remove house number from street name
					}
					if (!addressObj.street_name && addressObj.street_address) {
						addressObj.street_name = addressObj.street_address;
					}
					return;
				}
			});
			if (!addressObj.street_name && addressObj.street_address) {
				addressObj.street_name = addressObj.street_address;
			}
			return cb(false,addressObj);
		}

		// Handle generic case...
		addySplit.forEach(function(addyPart){
			if (!(addyPart = addyPart.trim())) return;
			// if has numbers, assume street address
			if (/[0-9]/.test(addyPart)) {				
				if(!addressObj?.street_address){
					const strArr = addyPart.split(" ");
					strArr.forEach(function(addyPart2) {
						if (!(addyPart2 = addyPart2.trim())) return;
						// if has numbers, assume street address
						if (/[0-9]/.test(addyPart2)) {
							if (!addressObj.house_number) {
								var houseNumberMatch = addyPart2.match(/^\d+([A-Za-z]?(-\d+[A-Za-z]?)?)?$/) ?? '';
								if (houseNumberMatch) {
									addressObj.house_number = houseNumberMatch[0];
								}
							}
							if (!addressObj.street_name && addressObj.house_number) {
								addressObj.street_name = addyPart.replace(houseNumberMatch[0], '').trim(); // Remove house number from street name
							}
							if (!addressObj.street_name && addressObj.street_address) {
								addressObj.street_name = addressObj.street_address;
							}
							return;
						}
					});
				}			
				
				return !addressObj.street_address && (addressObj.street_address = addyPart);
			}
			// if looks like state
			if (looksLikeState(addyPart) && !addressObj.state) {
				return addressObj.state = addyPart;
			}
			// if looks like country
			if (looksLikeCountry(addyPart)) {
				return !addressObj.country && (addressObj.country = addyPart);
			}
			// else assume city
			!addressObj.city && (addressObj.city = addyPart);
		});	
		
		cb(false, addressObj);
	}, 0);
};

const timeZoneData = (country, state, data) => {
	var timeZone = {
		"US" : {
			'AL' : {'fullName' : 'Alabama', 'timeZone' : 'America/Chicago'},
			'AK' : {'fullName' : 'Alaska', 'timeZone' : 'America/Anchorage'},
			'AZ' : {'fullName' : 'Arizona', 'timeZone' : 'America/Phoenix'},
			'AR' : {'fullName' : 'Arkansas', 'timeZone' : 'America/Chicago'},
			'CA' : {'fullName' : 'California', 'timeZone' : 'America/Los_Angeles'},
			'CO' : {'fullName' : 'Colorado', 'timeZone' : 'America/Denver'},
			'CT' : {'fullName' : 'Connecticut', 'timeZone' : 'America/New_York'},
			'DE' : {'fullName' : 'Delaware', 'timeZone' : 'America/New_York'},
			'FL' : {'fullName' : 'Florida', 'timeZone' : 'America/New_York'},
			'GA' : {'fullName' : 'Georgia', 'timeZone' : 'America/New_York'},
			'HI' : {'fullName' : 'Hawaii', 'timeZone' : 'Pacific/Honolulu'},
			'ID' : {'fullName' : 'Idaho', 'timeZone' : 'America/Denver'},
			'IL' : {'fullName' : 'Illinois', 'timeZone' : 'America/Chicago'},
			'IN' : {'fullName' : 'Indiana', 'timeZone' : 'America/Indianapolis'},
			'IA' : {'fullName' : 'Iowa', 'timeZone' : 'America/Chicago'},
			'KS' : {'fullName' : 'Kansas', 'timeZone' : 'America/Chicago'},
			'KY' : {'fullName' : 'Kentucky', 'timeZone' : 'America/New_York'},
			'LA' : {'fullName' : 'Louisiana', 'timeZone' : 'America/Chicago'},
			'ME' : {'fullName' : 'Maine', 'timeZone' : 'America/New_York'},
			'MD' : {'fullName' : 'Maryland', 'timeZone' : 'America/New_York'},
			'MA' : {'fullName' : 'Massachusetts', 'timeZone' : 'America/New_York'},
			'MI' : {'fullName' : 'Michigan', 'timeZone' : 'America/Detroit'},
			'MN' : {'fullName' : 'Minnesota', 'timeZone' : 'America/Chicago'},
			'MS' : {'fullName' : 'Mississippi', 'timeZone' : 'America/Chicago'},
			'MO' : {'fullName' : 'Missouri', 'timeZone' : 'America/Chicago'},
			'MT' : {'fullName' : 'Montana', 'timeZone' : 'America/Denver'},
			'NE' : {'fullName' : 'Nebraska', 'timeZone' : 'America/Chicago'},
			'NV' : {'fullName' : 'Nevada', 'timeZone' : 'America/Los_Angeles'},
			'NH' : {'fullName' : 'New Hampshire', 'timeZone' : 'America/New_York'},
			'NJ' : {'fullName' : 'New Jersey', 'timeZone' : 'America/New_York'},
			'NM' : {'fullName' : 'New Mexico', 'timeZone' : 'America/Denver'},
			'NY' : {'fullName' : 'New York', 'timeZone' : 'America/New_York'},
			'NC' : {'fullName' : 'North Carolina', 'timeZone' : 'America/New_York'},
			'ND' : {'fullName' : 'North Dakota', 'timeZone' : 'America/Chicago'},
			'OH' : {'fullName' : 'Ohio', 'timeZone' : 'America/New_York'},
			'OK' : {'fullName' : 'Oklahoma', 'timeZone' : 'America/Chicago'},
			'OR' : {'fullName' : 'Oregon', 'timeZone' : 'America/Los_Angeles'},
			'PA' : {'fullName' : 'Pennsylvania', 'timeZone' : 'America/New_York'},
			'RI' : {'fullName' : 'Rhode Island', 'timeZone' : 'America/New_York'},
			'SC' : {'fullName' : 'South Carolina', 'timeZone' : 'America/New_York'},
			'SD' : {'fullName' : 'South Dakota', 'timeZone' : 'America/Chicago'},
			'TN' : {'fullName' : 'Tennessee', 'timeZone' : 'America/Chicago'},
			'TX' : {'fullName' : 'Texas', 'timeZone' : 'America/Chicago'},
			'UT' : {'fullName' : 'Utah', 'timeZone' : 'America/Denver'},
			'VT' : {'fullName' : 'Vermont', 'timeZone' : 'America/New_York'},
			'VA' : {'fullName' : 'Virginia', 'timeZone' : 'America/New_York'},
			'WA' : {'fullName' : 'Washington', 'timeZone' : 'America/Los_Angeles'},
			'WV' : {'fullName' : 'West Virginia', 'timeZone' : 'America/New_York'},
			'WI' : {'fullName' : 'Wisconsin', 'timeZone' : 'America/Chicago'},
			'WY' : {'fullName' : 'Wyoming', 'timeZone' : 'America/Denver'}
		},
		"CA" : {
			'AB' : {'fullName' : 'Alberta', 'timeZone' : 'America/Edmonton'},
			'BC' : {'fullName' : 'British Columbia', 'timeZone' : 'America/Vancouver'},
			'MB' : {'fullName' : 'Manitoba', 'timeZone' : 'America/Winnipeg'},
			'NB' : {'fullName' : 'New Brunswick', 'timeZone' : 'America/Halifax'},
			'NL' : {'fullName' : 'Newfoundland and Labrador', 'timeZone' : 'America/St_Johns'},
			'NT' : {'fullName' : 'Northwest Territories', 'timeZone' : 'America/Yellowknife'},
			'NS' : {'fullName' : 'Nova Scotia', 'timeZone' : 'America/Halifax'},
			'NU' : {'fullName' : 'Nunavut', 'timeZone' : 'America/Iqaluit'},
			'ON' : {'fullName' : 'Ontario', 'timeZone' : 'America/Toronto'},
			'PE' : {'fullName' : 'Prince Edward Island', 'timeZone' : 'America/Halifax'},
			'QC' : {'fullName' : 'Quebec', 'timeZone' : 'America/Montreal'},
			'SK' : {'fullName' : 'Saskatchewan', 'timeZone' : 'America/Regina'},
			'YT' : {'fullName' : 'Yukon', 'timeZone' : 'America/Whitehorse'}
		},
		"NG" : {
			'AB' : {'fullName' : 'Abia', 'timeZone' : 'Africa/Lagos'},
			'AD' : {'fullName' : 'Adamawa', 'timeZone' : 'Africa/Lagos'},
			'AK' : {'fullName' : 'Akwa Ibom', 'timeZone' : 'Africa/Lagos'},
			'AN' : {'fullName' : 'Anambra', 'timeZone' : 'Africa/Lagos'},
			'BA' : {'fullName' : 'Bauchi', 'timeZone' : 'Africa/Lagos'},
			'BY' : {'fullName' : 'Bayelsa', 'timeZone' : 'Africa/Lagos'},
			'BE' : {'fullName' : 'Benue', 'timeZone' : 'Africa/Lagos'},
			'BO' : {'fullName' : 'Borno', 'timeZone' : 'Africa/Lagos'},
			'CR' : {'fullName' : 'Cross River', 'timeZone' : 'Africa/Lagos'},
			'DE' : {'fullName' : 'Delta', 'timeZone' : 'Africa/Lagos'},
			'EB' : {'fullName' : 'Ebonyi', 'timeZone' : 'Africa/Lagos'},
			'ED' : {'fullName' : 'Edo', 'timeZone' : 'Africa/Lagos'},
			'EK' : {'fullName' : 'Ekiti', 'timeZone' : 'Africa/Lagos'},
			'EN' : {'fullName' : 'Enugu', 'timeZone' : 'Africa/Lagos'},
			'FC' : {'fullName' : 'Federal Capital Territory', 'timeZone' : 'Africa/Lagos'},
			'GO' : {'fullName' : 'Gombe', 'timeZone' : 'Africa/Lagos'},
			'IM' : {'fullName' : 'Imo', 'timeZone' : 'Africa/Lagos'},
			'JI' : {'fullName' : 'Jigawa', 'timeZone' : 'Africa/Lagos'},
			'KD' : {'fullName' : 'Kaduna', 'timeZone' : 'Africa/Lagos'},
			'KN' : {'fullName' : 'Kano', 'timeZone' : 'Africa/Lagos'},
			'KT' : {'fullName' : 'Katsina', 'timeZone' : 'Africa/Lagos'},
			'KE' : {'fullName' : 'Kebbi', 'timeZone' : 'Africa/Lagos'},
			'KO' : {'fullName' : 'Kogi', 'timeZone' : 'Africa/Lagos'},
			'KW' : {'fullName' : 'Kwara', 'timeZone' : 'Africa/Lagos'},
			'LA' : {'fullName' : 'Lagos', 'timeZone' : 'Africa/Lagos'},
			'NA' : {'fullName' : 'Nasarawa', 'timeZone' : 'Africa/Lagos'},
			'NI' : {'fullName' : 'Niger', 'timeZone' : 'Africa/Lagos'},
			'OG' : {'fullName' : 'Ogun', 'timeZone' : 'Africa/Lagos'},
			'ON' : {'fullName' : 'Ondo', 'timeZone' : 'Africa/Lagos'},
			'OS' : {'fullName' : 'Osun', 'timeZone' : 'Africa/Lagos'},
			'OY' : {'fullName' : 'Oyo', 'timeZone' : 'Africa/Lagos'},
			'PL' : {'fullName' : 'Plateau', 'timeZone' : 'Africa/Lagos'},
			'RI' : {'fullName' : 'Rivers', 'timeZone' : 'Africa/Lagos'},
			'SO' : {'fullName' : 'Sokoto', 'timeZone' : 'Africa/Lagos'},
			'TA' : {'fullName' : 'Taraba', 'timeZone' : 'Africa/Lagos'},
			'YO' : {'fullName' : 'Yobe', 'timeZone' : 'Africa/Lagos'},
			'ZA' : {'fullName' : 'Zamfara', 'timeZone' : 'Africa/Lagos'}
		}
	};
		
	return timeZone[country][state][data];
}

const convertAddress = (address) => {
	return address.toLowerCase().replace(/[^0-9a-zA-Z -]/g, '').replace(/ /g, '-').replace(/--/g, '-');
}

const capitalizeFirstLetter = (str) => {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

const checkLogin = async(email) => {
	try
	{			
		const ellogin = await getStorage('__el_data_login_status');
		const email = await getStorage('__el_data_email');
		if(!isValidEmail(email) && ellogin === '0'){
			return "/signin";
		}else if(!isValidEmail(email)){
			return "/signin";
		}else if(ellogin === '0'){
			return "/signin";
		}
		return "";
		
	}catch(e){}
}

const fetchURL = async(url, method, dataToPost, header) => {
	try
	{
		if(method === 'GET'){
			const res = await Promise.race([
			  fetch(url, {
				method: method,
				headers: header,
				rejectUnauthorized: false,
			  }),
			  new Promise((_, reject) =>
					setTimeout(() => reject(new Error('Request timed out')), 60000)
			  ),
			]);
			return res;
		}else{
			const res = await Promise.race([
			  fetch(url, {
				method: method,
				body: dataToPost,
				headers: header,
				rejectUnauthorized: false,
			  }),
			  new Promise((_, reject) =>
					setTimeout(() => reject(new Error('Request timed out')), 30000)
			  ),
			]);
			return res;	
		}
	}catch(e){
		// Handle the timeout error here
    if (e.message === 'Request timed out') {
      // Perform any desired actions for timeout, e.g., return a custom timeout response
      return { error: 'Request timed out' };
    } else {
      // Handle other errors if needed
			return { error: e.message };
    }
	}
}

const fetchURL2 = async (url, method, dataToPost, header) => {  
  if (method === 'GET') {
    const response = await fetch(url, {
      method: method,
      headers: header,
    });
    return response;
  } else if (method === 'POST') {
    const response = await fetch(url, {
      method: method,
      body: dataToPost,
      headers: header,
    });
    return response;
  }
};


var gis = {
	calculateDistance: function(start, end) {
		var lat1 = parseFloat(start[0]),
			lon1 = parseFloat(start[1]),
			lat2 = parseFloat(end[0]),
			lon2 = parseFloat(end[1]);
			
		return gis.sphericalCosinus(lat1, lon1, lat2, lon2);
	},
	sphericalCosinus: function(lat1, lon1, lat2, lon2) {
		lat1 = gis.toRad(lat1);
		lat2 = gis.toRad(lat2);
		const radius = 6371000; // meters
		const dLon = gis.toRad(lon2 - lon1);			
		const distance = Math.acos(Math.sin(lat1) * Math.sin(lat2) +
				Math.cos(lat1) * Math.cos(lat2) * Math.cos(dLon)) * radius;

		return distance;
	},
	createCoord: function(coord, bearing, distance) {
		var 
		  radius = 6371000, // meters
		  δ = Number(distance) / radius, // angular distance in radians
		  θ = gis.toRad(Number(bearing)),
		  φ1 = gis.toRad(coord[1]),
		  λ1 = gis.toRad(coord[0]);

		var φ2 = Math.asin(Math.sin(φ1)*Math.cos(δ) + 
		  Math.cos(φ1)*Math.sin(δ)*Math.cos(θ));

		var λ2 = λ1 + Math.atan2(Math.sin(θ) * Math.sin(δ)*Math.cos(φ1),
		  Math.cos(δ)-Math.sin(φ1)*Math.sin(φ2));
		// normalise to -180..+180°
		λ2 = (λ2 + 3 * Math.PI) % (2 * Math.PI) - Math.PI; 

		return [gis.toDeg(λ2), gis.toDeg(φ2)];
	},
	getBearing: function(start, end){
		var
		  startLat = gis.toRad(start[0]),
		  startLong = gis.toRad(start[1]),
		  endLat = gis.toRad(end[0]),
		  endLong = gis.toRad(end[1]),
		  dLong = endLong - startLong;

		var dPhi = Math.log(Math.tan(endLat/2.0 + Math.PI/4.0) / 
		  Math.tan(startLat/2.0 + Math.PI/4.0));

		if (Math.abs(dLong) > Math.PI) {
		  dLong = (dLong > 0.0) ? -(2.0 * Math.PI - dLong) : (2.0 * Math.PI + dLong);
		}

		return (gis.toDeg(Math.atan2(dLong, dPhi)) + 360.0) % 360.0;
	},
	toDeg: function(n) { return n * 180 / Math.PI; },
	toRad: function(n) { return n * Math.PI / 180; }
};

const getDriveData = async (req) => 
{
	const stored = await localStorage.getItem('__el_drive');
	const data = await JSON.parse(cipherDecode(stored));
	
	if(req === 'mph'){
		return data.mph;
	}
	if(req === 'perHrEarnings'){
		return data.perHrEarnings;
	}
	if(req === 'meterPerMile'){
		return data.meterPerMile;
	}
	if(req === 'minTip'){
		return data.minTip;
	}
	if(req === 'waitDelay'){
		return data.waitDelay;
	}
	if(req === 'driverDelay'){
		return data.driverDelay;
	}
	if(req === 'errandExtra'){
		return data.errandExtra;
	}
	if(req === 'mileRadius'){
		return data.mileRadius;
	}
	if(req === 'mobileVersion'){
		return data.mobileVersion;
	}
}

const driveObj = localStorage.getItem('__el_drive') ? JSON.parse(cipherDecode(localStorage.getItem('__el_drive'))) : "";
const driveData = typeof driveObj === "object" ? driveObj : {};

const minTip = driveData?.minTip ?? 2;
const mph = driveData?.mph ?? 30;
const perHrEarnings = driveData?.perHrEarnings ?? 25;
const meterPerMile = driveData?.meterPerMile ?? 1609.34;

const setLocalCookie = async (name, val) => {
	let currentDomain = window.location.hostname;
  let domain = '';
  if (currentDomain !== 'localhost') {
    const parts = currentDomain.split('.');    
    // Check if the current domain has more than two parts
    if (parts.length > 2) {
      currentDomain = parts.slice(-2).join('.');
    }
    domain = `.${currentDomain}`;
  } else {
    domain = currentDomain;
  }
	Cookies.set(name, JSON.stringify(val), { domain, SameSite: 'None', Secure: true });
  return true;    
};

const getLocalCookie = async (name) => {
	if(!Cookies.get(name)){
		return false;
	}
	let gtcookie = Cookies.get(name);
	gtcookie = typeof gtcookie === "object" ? gtcookie : JSON.parse(gtcookie);
	return gtcookie;
};

const removeLocalCookie = async (name) => {
  let currentDomain = window.location.hostname;
  let domain = '';
  if (currentDomain !== 'localhost') {
    const parts = currentDomain.split('.');    
    // Check if the current domain has more than two parts
    if (parts.length > 2) {
      currentDomain = parts.slice(-2).join('.');
    }
    domain = `.${currentDomain}`;
  } else {
    domain = currentDomain;
  }

  Cookies.remove(name, { domain });
  return true;
};


const utils = {
	parseAddress,
	setLocalCookie,
	getLocalCookie,
	removeLocalCookie,
	isValidEmail,
	validateEmail,
	validateEmailInput,
	validatePassword,
	validateInput,
	calculateAngle,
	validateCard,
	validateCardExpiry,
	getTime,
	dateToString,
	getBrowser,
	getOs,
	removeStorage,
	getStorage,
	setStorage,
	amountToString,
	convertToFullDay,
	convertTimeTo12HourFormat,
	convertToUSPhoneFormat,
	convertToNearest15Minutes,
	convertTimeStringToInteger,
	timeZoneData,
	convertAddress,
	deliveryFee,
	errandDeliveryFee,
	deliveryTip,
	deliveryDefaultTip,
	errandDeliveryTip,
	errandDeliveryDefaultTip,
	serviceCharge,
	capitalizeFirstLetter,
	encryptObject,
	decryptObject,
	base64Encode,
	base64Decode,
	cipherEncode,
	cipherDecode,
	GOOGLE_API_KEY,
	GOOGLE_CLIENT_SECRET,
	GOOGLE_CLIENT_ID,
	squareAppId,
	squareLocationId,
	gis,
	mph,
	perHrEarnings,
	meterPerMile,
	minTip,
	dashboardOptions,
	countryOptions,
	profileOptions,
	getStateOptions,
	getCountryByStateProvince,
	checkLogin,
	fetchURL,
	STORE_MENU_URL,
	USER_CALL,
	USER_DRIVE,
	USER_REPORT,
	DRIVER_REPORT,
	MERCHANT_REPORT,
	BASE_URL,
	AUTH_URL,
	USER_URL,
	DRIVER_URL,
	DRIVER_SIGNUP_URL,
	MERCHANT_SIGNUP_URL,
	MERCHANT_URL,
	PARTNER_URL,
	SPLITTER,
	ENCRYPTION_KEY,
	SITE_PHONE_NUMBER,
	MAP_MARKER_1,
	MAP_MARKER_2,
	getRandomColor,
	getLightRandomColor,
	supportemail,
	getDriveData,
	APP_DOWNLOAD,
	supportedCountries,
	cookieExceptions,
	storeExceptions,
};

export default utils;